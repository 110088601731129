import { isEmpty } from 'lodash'
import { isNil, head } from 'lodash'
import { CategoryItemFragment } from '../types/generated'

export const getCategoryBySlug = (categories: CategoryItemFragment[], slug: string) =>
  categories.find(category => category.slug === slug) ?? null

export const getRootCategories = (categories: CategoryItemFragment[]) =>
  categories.filter(category => isEmpty(category.parents))

export const getParentCategory = (categories: CategoryItemFragment[], parents?: string[]) => {
  if (isNil(parents) || isEmpty(parents)) {
    return null
  }

  return categories.find(category => head(parents) === category.slug) ?? null
}

export const getChildCategories = (categories: CategoryItemFragment[], slug?: string) => {
  if (isNil(slug) || isEmpty(slug)) {
    return []
  }

  return categories.filter(category => head(category.parents) === slug)
}
