import { Component } from 'react'
import * as Sentry from '@sentry/browser'

interface Props {}

class ErrorBoundary extends Component<Props> {
  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope(scope => {
      scope.setExtra('errorMessage', errorInfo)
      Sentry.captureException(error)
    })
  }

  render() {
    return this.props.children
  }
}

export default ErrorBoundary
