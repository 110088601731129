import React, { MouseEvent } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { startsWith } from 'lodash'

import { toLocaleUrl } from '../utils/locale'

interface Props extends LinkProps {
  nativeLink?: boolean
  onClick?: (e: MouseEvent) => void
}

export default function LinkWithLocale({ to, nativeLink, onClick, children, ...rest }: Props) {
  const href = to.toString()
  const decoratedTo = startsWith(href, '/') ? toLocaleUrl(href) : toLocaleUrl(`/${href}`)

  if (nativeLink) {
    return (
      <a {...rest} href={decoratedTo}>
        {children}
      </a>
    )
  }

  return (
    <Link {...rest} to={decoratedTo} onClick={onClick}>
      {children}
    </Link>
  )
}
