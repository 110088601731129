import './SearchResultItem.scss'

import React from 'react'

import ItemImage from '../../components/ItemImage'
import EpisodeSubInfo from '../../components/EpisodeSubInfo'
import CCLanguages from '../../components/CCLanguages'
import ProgramMeta from './ProgramMeta'

import useRouter from '../../hooks/useRouter'
import { toLocaleUrl, getTranslateValue } from '../../utils/locale'
import { getSizedImageUrl } from '../../utils/misc'

import { ReleaseStatus } from '../../constants/enums'
import { SearchItemFragment } from '../../types/generated'

interface Props {
  item: SearchItemFragment
}

export default function SearchResultItem({ item }: Props) {
  const { history } = useRouter()

  const { slug, images, latestEpisode, releaseStatus, meta } = item
  const title = getTranslateValue(item, 'title') ?? ''
  const secondaryTitle = getTranslateValue(item, 'secondaryTitle') ?? ''
  const latestEpisodeTitle = getTranslateValue(latestEpisode, 'title') ?? ''
  const synopsis = getTranslateValue(item, 'synopsis') ?? ''
  const poster = getTranslateValue(images, 'poster') || images?.poster

  return (
    <li className="SearchResultItem">
      <div className="SearchResultItem__wrapper" onClick={() => history.push(toLocaleUrl(`/program/${slug}`))}>
        <div className="SearchResultItem__imageWrapper">
          <ItemImage className="SearchResultItem__image" imageUrl={getSizedImageUrl(poster)} />
        </div>
        <div className="SearchResultItem__mobileTitleWrapper">
          <h3 className="SearchResultItem__mobileTitle">{title}</h3>
          <p className="SearchResultItem__mobileSubTitle">{secondaryTitle}</p>
        </div>
        <div className="SearchResultItem__content">
          <h3 className="SearchResultItem__title">{title}</h3>
          <p className="SearchResultItem__subTitle">{secondaryTitle}</p>
          <div className="SearchResultItem__episode">
            {meta?.isSeries && (
              <EpisodeSubInfo
                latestEpisodeTitle={latestEpisodeTitle}
                episodeTotal={meta.episodeTotal ?? 0}
                releaseStatus={releaseStatus as ReleaseStatus}
              />
            )}
          </div>
          <CCLanguages ccLanguages={meta?.ccLanguages} />
          <ProgramMeta item={item} />
          <p className="SearchResultItem__synopsys">{synopsis}</p>
        </div>
      </div>
    </li>
  )
}
