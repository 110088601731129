import { setItem, removeItem, getItem } from './localStorage'

// FIXME: 기존 코드가 user token이 userId라는 이름으로 저장되어있었다. 나중에 일괄변경하자.
export const USER_TOKEN_KEY = 'userId'
export const USER_ID_KEY = 'authenticatedUserId'

export const saveUserCredentials = (token: string, userId: number | null) => {
  setItem(USER_TOKEN_KEY, token)
  if (userId) {
    setItem(USER_ID_KEY, userId)
  } else {
    removeItem(USER_ID_KEY)
  }
}

export const clearStoredUserCredentials = () => {
  removeItem(USER_TOKEN_KEY)
  removeItem(USER_ID_KEY)
}

export const getStoredUserId = () => {
  const userIdString = getItem(USER_ID_KEY, null)

  try {
    return userIdString ? parseInt(userIdString, 10) : userIdString
  } catch (_) {
    return null
  }
}

export const getStoredUserToken = (): string | null => getItem(USER_TOKEN_KEY, null)
