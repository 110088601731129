import { useState, useEffect, RefObject } from 'react'

type IntersectionTarget = HTMLDivElement | HTMLImageElement

const defaultOption: IntersectionObserverInit = {
  root: null,
  threshold: 0.5,
}

const useLazyLoad = (
  baseRef: RefObject<IntersectionTarget>,
  ioCb: IntersectionObserverCallback,
  option?: IntersectionObserverInit
) => {
  /** NOTE: 'nextLazyValue' is using for renew IntersectionObserver only.
   *  Therefore nextLazyValue value type is 'any' because there just executing useEffect, regardless of value types.
   */
  const [nextLazyValue, setNextLazyValue] = useState<any>(null)

  useEffect(() => {
    if (!('IntersectionObserver' in window)) {
      return
    }
    const $intersectionTarget = baseRef.current
    if (!$intersectionTarget) {
      return
    }

    const io = new IntersectionObserver(ioCb, option || defaultOption)
    io.observe($intersectionTarget)

    return () => {
      io.disconnect()
    }
    // eslint-disable-next-line
  }, [nextLazyValue])

  return setNextLazyValue
}

export default useLazyLoad
