import './CategoryList.scss'

import React, { useCallback, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { isEmpty, isNil } from 'lodash'

import MobileSubCategorySelector from './MobileSubCategorySelector'
import ErrorBoundary from './ErrorBoundary'
import Translate from './Translate'

import useRouter from '../hooks/useRouter'
import useDeviceTypeContext from '../hooks/useDeviceTypeContext'

import { DeviceType } from '../constants/enums'
import { toLocaleUrl } from '../utils/locale'
import { isHome as checkIsHome, isProgramListPage as checkIsProgramList } from '../utils/queryString'
import { isMobileView } from '../utils/responsive'
import { CategoryItemFragment, Maybe } from '../types/generated'

interface Props {
  isRootCategoryList: boolean
  categories: CategoryItemFragment[]
  currentCategory: Maybe<CategoryItemFragment>
  parentCategory?: Maybe<CategoryItemFragment>
  onCategoryClick: (category: CategoryItemFragment) => void
  onHideMobileMenu: () => void
}

const checkMobileCategorySelectorIsVisible = (isRootCategoryList: boolean, deviceType: DeviceType) =>
  !isRootCategoryList && isMobileView(deviceType)

export default function CategoryList({
  isRootCategoryList,
  categories,
  currentCategory,
  parentCategory,
  onCategoryClick,
  onHideMobileMenu,
}: Props) {
  const [isVisibleMobileSubCategorySelector, setIsVisibleMobileSubCategorySelector] = useState(false)
  const {
    history,
    location: { pathname },
  } = useRouter()
  const isHome = checkIsHome(pathname)
  const isProgramListPage = checkIsProgramList(pathname)
  const { t } = useTranslation()
  const currentDeviceType = useDeviceTypeContext()

  const getIsCategorySelected = useCallback((slug: string) => currentCategory?.slug === slug, [currentCategory])

  const handleHomeClick = useCallback(
    () => onHideMobileMenu(),
    // eslint-disable-next-line
    []
  )

  const handleParentCategoryClick = useCallback(() => {
    if (!parentCategory) {
      return null
    }

    history.push(toLocaleUrl(`/category/${parentCategory.slug}`))
    onCategoryClick(parentCategory)
    // eslint-disable-next-line
  }, [parentCategory])

  const handleCategoryClick = useCallback(
    (category: Maybe<CategoryItemFragment>) => {
      if (isNil(category)) {
        return
      }

      onHideMobileMenu()
      onCategoryClick(category)
      history.push(toLocaleUrl(`/category/${category.slug}`))
    },
    // eslint-disable-next-line
    [onHideMobileMenu, onCategoryClick]
  )

  const handleSelectClick = useCallback(
    () => {
      if (checkMobileCategorySelectorIsVisible(isRootCategoryList, currentDeviceType)) {
        setIsVisibleMobileSubCategorySelector(true)
      }
    },
    // eslint-disable-next-line
    [currentDeviceType]
  )

  const handleCloseClick = useCallback(
    () => setIsVisibleMobileSubCategorySelector(false),
    // eslint-disable-next-line
    []
  )

  const handleModalClick = useCallback(
    (category: CategoryItemFragment) => {
      setIsVisibleMobileSubCategorySelector(false)
      if (getIsCategorySelected(category.slug)) {
        return
      }

      onCategoryClick(category)
    },
    // eslint-disable-next-line
    [onCategoryClick]
  )

  return isEmpty(categories) ? null : (
    <ErrorBoundary>
      <ul className={`CategoryList__${isRootCategoryList ? 'root' : 'leaf'}`}>
        <li className="Category__home">
          <NavLink
            className={classNames('Category', {
              'Category--selected': isHome,
              'Category--notSelected': !isHome,
            })}
            to={toLocaleUrl('/')}
            onClick={handleHomeClick}
          >
            {t('error.home')}
          </NavLink>
        </li>
        {categories.map((category: CategoryItemFragment) => (
          <li key={category.slug} onClick={() => handleCategoryClick(category)}>
            <span
              className={classNames('Category', {
                'Category--selected': !isHome && isProgramListPage && getIsCategorySelected(category.slug),
                'Category--notSelected': !isHome && isProgramListPage && !getIsCategorySelected(category.slug),
              })}
            >
              <Translate target={category} fieldKey="title" />
            </span>
          </li>
        ))}
        {checkMobileCategorySelectorIsVisible(isRootCategoryList, currentDeviceType) && (
          <>
            <li className="Category__parent" onClick={handleParentCategoryClick}>
              <Translate target={parentCategory ?? currentCategory} fieldKey="title" />
            </li>
            <li onClick={handleSelectClick}>
              <span className="Category__select">
                {isNil(parentCategory) ? (
                  t('programFilter.all')
                ) : (
                  <Translate target={currentCategory} fieldKey="title" />
                )}
              </span>
            </li>
          </>
        )}
        {currentCategory && isVisibleMobileSubCategorySelector && (
          <MobileSubCategorySelector
            categories={categories}
            parentCategory={parentCategory}
            currentCategory={currentCategory}
            isHome={isHome}
            onCloseClick={handleCloseClick}
            onModalClick={handleModalClick}
            onIsCategorySelected={getIsCategorySelected}
          />
        )}
      </ul>
    </ErrorBoundary>
  )
}
