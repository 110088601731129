import './Logo.scss'

import React, { MouseEvent, FC } from 'react'
import classNames from 'classnames'

import LinkWithLocale from '../components/LinkWithLocale'

interface Props {
  center?: boolean
  imgClassName?: string
  onClick?: (e: MouseEvent) => void
}

const Logo: FC<Props> = ({ center, imgClassName, onClick }) => (
  <div
    className={classNames('Logo', {
      'Logo--center': center,
    })}
  >
    <LinkWithLocale className="Logo__link" to="/" title="Ondemand china home" onClick={onClick}>
      <img className={classNames(imgClassName)} src="/images/logo.png" alt="Ondemand china home" />
    </LinkWithLocale>
  </div>
)

export default Logo
