import './App.scss'

import React, { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'

import AppRoute from './AppRoute'
import ErrorPage from './pages/error/ErrorPage'
import Notification from './components/Notification'

import useEventListener from './hooks/useEventListener'
import { sendGTMEvent } from './utils/analytics'
import { ODXThemeProvider } from '@odkmedia/odx-design-system'

export default function App() {
  useEventListener([
    {
      eventName: 'click',
      callback: e => {
        if (e.target.id) {
          sendGTMEvent({
            event: 'ButtonClick',
            label: e.target.id,
          })
        }
      },
    },
  ])

  return (
    <Suspense fallback="loading">
      <ODXThemeProvider theme="odc">
        <div className="App">
          <Notification />
          <Switch>
            <Route path="/:locale/errors/:error" component={ErrorPage} exact />
            <Route path="/" component={AppRoute} />
            <Route component={ErrorPage} />
          </Switch>
        </div>
      </ODXThemeProvider>
    </Suspense>
  )
}
