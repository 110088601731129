import './ItemImage.scss'

import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'

import useLazyLoadImage from '../hooks/useLazyLoadImage'

export enum ImageRatio {
  RATIO_16_9,
  RATIO_3_4,
}

interface Props {
  width?: number | string
  className?: string
  imageUrl?: string
  ratio?: ImageRatio
}

export default function ItemImage({
  width = 'auto',
  className = '',
  imageUrl = '',
  ratio = ImageRatio.RATIO_16_9,
}: Props) {
  const imageDivRef = useRef<HTMLDivElement>(null)
  const { isLoaded, setNextLazyValue } = useLazyLoadImage(imageDivRef)

  useEffect(() => {
    if (imageUrl && isLoaded) {
      setNextLazyValue(imageUrl)
    }
    // eslint-disable-next-line
  }, [imageUrl])

  return (
    <div className={classNames('ItemImage', className)} style={{ width }}>
      <div
        ref={imageDivRef}
        className={classNames('ItemImage__ratio', {
          'ItemImage__ratio--16to9': ratio === ImageRatio.RATIO_16_9,
          'ItemImage__ratio--3to4': ratio === ImageRatio.RATIO_3_4,
        })}
        data-src={imageUrl}
        style={{ width }}
      />
    </div>
  )
}
