import { isEmpty } from 'lodash'

import { getTranslateValue, toLocaleUrl } from './locale'

import { SearchQueryType } from '../constants/enums'
import { GenreItemFragment, PeopleItemFragment, Category } from '../types/generated'

export const SEO_IMAGE_META = {
  width: 640,
  height: 480,
}

export interface CategoryData {
  id: number
  title: string | undefined
  to: string
}

export const createSearchUrl = (queryKind: SearchQueryType, queryParameter: string | number) =>
  toLocaleUrl(`/search?${queryKind}=${queryParameter}`)

export const createPeopleData = (people: PeopleItemFragment[], role: string) =>
  people
    .filter(person => person.role === role)
    .map(filteredPeople => ({
      id: filteredPeople.slug,
      title: getTranslateValue(filteredPeople, 'name'),
      to: `/search?person=${filteredPeople.slug}`,
    }))

export const createCategoryData = (categories: (GenreItemFragment | Category)[], isGenre?: boolean): CategoryData[] =>
  categories?.map(category => ({
    id: category.id,
    title: getTranslateValue(category, 'title'),
    to: `/search?${isGenre ? 'genre' : 'category'}=${category.slug}`,
  }))

const ifEmptyReturnNull = (value?: string): string | null => {
  if (isEmpty(value)) {
    return null
  }
  return value as string
}

export const getSEOValue = ({
  target,
  key,
  language,
  fallbackValue = null,
}: {
  target: any
  key: string
  language: string
  fallbackValue?: string | null
}): string | null => {
  const seoValue = ifEmptyReturnNull(getTranslateValue(target, `seo_${key}`, language))

  return seoValue ? seoValue : fallbackValue
}
