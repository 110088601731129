import React, { useEffect } from 'react'

import useRouter from '../hooks/useRouter'
import usePrevious from '../hooks/usePrevious'

interface Props {
  children: React.ReactNode
}

export default function ScrollToTop(props: Props) {
  const {
    location: { pathname },
  } = useRouter()
  const prevPathname = usePrevious(pathname)

  useEffect(() => {
    if (pathname !== prevPathname) {
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line
  }, [pathname])
  return <>{props.children}</>
}
