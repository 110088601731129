import React, { lazy, useState, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { find, includes, isNil, isEmpty } from 'lodash'
import classNames from 'classnames'

import AuthPage from './components/AuthPage'
import AdArea from './components/AdArea'
import NavBar from './components/NavBar'
import Footer from './components/Footer'
import AuthenticateRoute from './components/AuthenticateRoute'
import SearchResultPage from './pages/searchResult/SearchResultPage'

import useInterval from './hooks/useInterval'
import useRouter from './hooks/useRouter'
import useUserContext from './hooks/useUserContext'
import { DEFAULT_LANGUAGE, toLocaleUrl } from './utils/locale'
import { isIncludePathname } from './utils/queryString'
import { allowBodyScroll, preventBodyScroll } from './utils/ui'
import { getCategoryBySlug, getParentCategory, getRootCategories } from './utils/category'
import { adSpots, AD_REFRESH_TIME, refreshAd, displayAd } from './advertisement'
import { AdAreaHeight } from './constants/enums'
import { useNavigationQuery, CategoryItemFragment, Maybe } from './types/generated'

const HomePage = lazy(() => import('./pages/home/HomePage'))
const ProgramListPage = lazy(() => import('./pages/programList/ProgramListPage'))
const ProgramPage = lazy(() => import('./pages/program/ProgramPage'))
const ProgramDirectPlayPage = lazy(() => import('./pages/program/ProgramDirectPlayPage'))
const EpisodePage = lazy(() => import('./pages/episode/EpisodePage'))
const SigninPage = lazy(() => import('./pages/signin/SigninPage'))
const SignupPage = lazy(() => import('./pages/signup/SignupPage'))
const TermsOfUsePage = lazy(() => import('./pages/termsOfUse/TermsOfUsePage'))
const PrivacyPolicyPage = lazy(() => import('./pages/privacyPolicy/PrivacyPolicyPage'))
const ContactUsPage = lazy(() => import('./pages/contactUs/ContactUsPage'))
const RegisterUserInfoPage = lazy(() => import('./pages/registerUserInfo/RegisterUserInfoPage'))
const FAQPage = lazy(() => import('./pages/faq/FAQPage'))
const ForgotPasswordPage = lazy(() => import('./pages/forgotPassword/ForgotPasswordPage'))
const UserInfoPage = lazy(() => import('./pages/userInfo/UserInfoPage'))

const urlsWithApplyBorder = ['category', 'search', 'program', 'watch']

export default function AppRoute() {
  const [visibleMenu, setVisibleMenu] = useState(false)
  const [currentCategory, setCurrentCategory] = useState<Maybe<CategoryItemFragment>>(null)
  const {
    location: { pathname },
    history,
  } = useRouter()
  const { currentLocale } = useUserContext()
  const { data } = useNavigationQuery({
    variables: {
      slug: 'odc',
    },
  })
  const categories = data?.navigation.nodes ?? []

  useEffect(() => {
    displayAd()
    refreshAd()
  }, [])
  useInterval(refreshAd, AD_REFRESH_TIME)

  useEffect(() => {
    if (!isEmpty(categories) && includes(pathname, 'category')) {
      const slugFromUrl = pathname.split('category/')[1]
      const category = getCategoryBySlug(categories, slugFromUrl)

      if (isNil(category)) {
        history.replace(toLocaleUrl('/errors/notfound'))
        return
      }

      setCurrentCategory(category)
    }
    // eslint-disable-next-line
  }, [categories, pathname])

  const leftAdSpot = find(adSpots, { name: 'ODC_Left' })!
  const rightAdSpot = find(adSpots, { name: 'ODC_Right' })!

  if (process.env.REACT_APP_MAINTENANCE_MODE === '1') {
    return <Route render={() => <Redirect to={toLocaleUrl('/errors/maintenance')} />} />
  }

  return (
    <>
      <div className="App__contentWrapper">
        <AdArea className="App__leftAd" adSpotId={leftAdSpot.spotId} height={AdAreaHeight.LEFT_RIGHT} />
        <header className="App__header">
          <NavBar
            categories={getRootCategories(categories)}
            currentCategory={currentCategory}
            parentCategory={getParentCategory(categories, currentCategory?.parents)}
            mobileMenuVisible={visibleMenu}
            onVisibleMobileMenu={() => {
              preventBodyScroll()
              setVisibleMenu(true)
            }}
            onHideMobileMenu={() => {
              allowBodyScroll()
              setVisibleMenu(false)
            }}
            onCurrentCategory={setCurrentCategory}
          />
        </header>
        <div
          role="main"
          className={classNames('App__content', {
            'App__content--toRight': visibleMenu,
            'App__content--separated': isIncludePathname(pathname, urlsWithApplyBorder),
          })}
        >
          <AuthPage>
            <Switch>
              <Route path="/" render={() => <Redirect to={currentLocale || DEFAULT_LANGUAGE} />} exact />
              <Route path="/:locale" component={HomePage} exact />
              <AuthenticateRoute path="/:locale/sign/sign-in" exact component={SigninPage} />
              <AuthenticateRoute path="/:locale/sign/sign-up" exact component={SignupPage} />
              <Route path="/:locale/sign/register-userinfo" exact component={RegisterUserInfoPage} />
              <AuthenticateRoute path="/:locale/sign/forgot-password" exact component={ForgotPasswordPage} />
              <Route path="/:locale/terms-of-use" exact component={TermsOfUsePage} />
              <Route path="/:locale/privacy-policy" exact component={PrivacyPolicyPage} />
              <Route path="/:locale/contact-us" exact component={ContactUsPage} />
              <Route path="/:locale/faq" exact component={FAQPage} />
              <Route path="/:locale/search" exact component={SearchResultPage} />
              <Route
                path="/:locale/category/:categorySlug"
                exact
                render={props => (
                  <ProgramListPage
                    {...props}
                    categories={categories}
                    currentCategory={currentCategory}
                    parentCategory={getParentCategory(categories, currentCategory?.parents)}
                    onCurrentCategory={setCurrentCategory}
                    onHideMobileMenu={() => {
                      allowBodyScroll()
                      setVisibleMenu(false)
                    }}
                  />
                )}
              />
              <Route path="/:locale/program/:programSlug" component={ProgramPage} exact />
              <Route path="/:locale/program/:programSlug/direct-play" component={ProgramDirectPlayPage} exact />
              <Route path="/:locale/watch/:programSlug/:episodeIdentitiesString" exact component={EpisodePage} />
              <Route path="/:locale/user" component={UserInfoPage} />
              <Route render={() => <Redirect to={toLocaleUrl('/errors/notfound')} />} />
            </Switch>
          </AuthPage>
        </div>
        <AdArea className="App__rightAd" adSpotId={rightAdSpot.spotId} height={AdAreaHeight.LEFT_RIGHT} />
      </div>
      <Footer />
    </>
  )
}
