import './MobileSidebar.scss'

import React, { useRef, useEffect } from 'react'
import classNames from 'classnames'
import { throttle } from 'lodash'

import CategoryList from './CategoryList'
import UserMenu from './UserMenu'
import Logo from './Logo'
import MobileLocaleSelector from './MobileLocaleSelector'

import useEventListener from '../hooks/useEventListener'

import { allowBodyScroll, preventBodyScroll } from '../utils/ui'
import { KeyCode } from '../constants/enums'
import { CategoryItemFragment, Maybe } from '../types/generated'
import { isIOS } from '../utils/userAgent'

interface Props {
  categories: CategoryItemFragment[]
  currentCategory: Maybe<CategoryItemFragment>
  mobileMenuVisible: boolean
  onCategoryClick: (category: CategoryItemFragment) => void
  onHideMobileMenu: () => void
}

export default function MobileSidebar({
  categories,
  currentCategory,
  mobileMenuVisible,
  onCategoryClick,
  onHideMobileMenu,
}: Props) {
  const dimRef = useRef<HTMLDivElement>(null)

  useEventListener([
    {
      eventName: 'keyup',
      callback: e => {
        if (e.keyCode === KeyCode.ESC) {
          onHideMobileMenu()
        }
      },
    },
    {
      eventName: 'click',
      callback: e => {
        const $dim = dimRef.current
        if (e.target === $dim) {
          onHideMobileMenu()
        }
      },
    },
  ])

  useEffect(() => {
    if (mobileMenuVisible) {
      preventBodyScroll()
      return
    }

    allowBodyScroll()

    return () => {
      allowBodyScroll()
    }
  }, [mobileMenuVisible])

  useEffect(() => {
    window.addEventListener(
      'resize',
      throttle(() => {
        if (window.innerWidth > 1024) {
          onHideMobileMenu()
          return
        }
      }, 100)
    )
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <aside
        className={classNames('MobileSidebar', {
          'MobileSidebar--visible': mobileMenuVisible,
          'MobileSidebar--close': !mobileMenuVisible,
        })}
      >
        <div className="MobileSidebar__linksMobile">
          <div className="MobileSidebar__linksMobile__head">
            <Logo onClick={onHideMobileMenu} />
            <div className="MobileSidebar__closeButtonWrapper" onClick={onHideMobileMenu}>
              <button id="MobileSidebarClose" className="MobileSidebar__closeButton">
                sidebar close
              </button>
            </div>
          </div>
          <CategoryList
            isRootCategoryList
            categories={categories}
            currentCategory={currentCategory}
            onCategoryClick={onCategoryClick}
            onHideMobileMenu={onHideMobileMenu}
          />
          <UserMenu isMobile onHideMobileMenu={onHideMobileMenu} />
          <MobileLocaleSelector />
        </div>
      </aside>
      <div
        ref={dimRef}
        className={classNames('MobileSidebar__dim', {
          'MobileSidebar__dim--visible': mobileMenuVisible,
          'MobileSidebar__dim--ios': isIOS,
        })}
      />
    </>
  )
}
