import './SearchBar.scss'

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { isEmpty, debounce, take } from 'lodash'

import DynamicSearchResult from './DynamicSearchResult'
import Portal from './Portal'
import SearchBarInput from './SearchBarInput'
import LinkWithLocale from './LinkWithLocale'

import useEventListener from '../hooks/useEventListener'

import { delay, allowBodyScroll, preventBodyScroll } from '../utils/ui'
import { sendGTMEvent } from '../utils/analytics'
import { KeyCode } from '../constants/enums'

import { useSearchLazyQuery } from '../types/generated'

interface Props {
  onClose: () => void
}

const ANIMATION_TIME = 300
const DYNAMIC_SEARCH_PAGE_SIZE = 12

export default function SearchBar({ onClose }: Props) {
  const { t } = useTranslation()
  const searchBar = useRef<HTMLDivElement>(null)
  const [willClose, setWillClose] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState('')
  const prevKeyword = useRef(searchKeyword)

  const [fetch, { data, loading, called }] = useSearchLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const searchedItems = data?.search.nodes ?? []

  const fetchDynamicSearchResult = useCallback(
    debounce((keyword: string) => {
      if (keyword.length < 1 || prevKeyword.current === keyword) {
        return
      }
      prevKeyword.current = keyword

      fetch({
        variables: {
          keyword,
          category: null,
          genre: null,
          person: null,
          produceCountry: null,
          produceYear: null,
          page: 1,
        },
      })
      sendGTMEvent({
        event: 'searchOccured',
        searchKeyword: keyword,
      })
    }, 500),
    []
  )

  const handleClose = useCallback(() => {
    setWillClose(true)
    delay(ANIMATION_TIME, onClose)
    // eslint-disable-next-line
  }, [])

  useEventListener([
    {
      eventName: 'click',
      callback: (e: MouseEvent) => {
        if (e.target === searchBar.current) {
          handleClose()
        }
      },
    },
    {
      eventName: 'keydown',
      callback: (e: KeyboardEvent) => {
        if (e.keyCode === KeyCode.ESC) {
          handleClose()
        }
      },
    },
  ])

  useEffect(() => {
    preventBodyScroll()

    return () => {
      allowBodyScroll()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    fetchDynamicSearchResult(searchKeyword)
    // eslint-disable-next-line
  }, [searchKeyword])

  return (
    <Portal position="bottom">
      <aside
        ref={searchBar}
        className={classNames('SearchBar', {
          'SearchBar--willClose': willClose,
        })}
      >
        <div
          className={classNames('SearchBar__content', {
            'SearchBar__content--visible': !willClose,
            'SearchBar__content--hide': willClose,
          })}
        >
          <div className="SearchBarInput__inputWrapper">
            <SearchBarInput
              searchKeyword={searchKeyword}
              onChange={setSearchKeyword}
              isLoading={loading}
              isPossibleSubmit={!isEmpty(searchedItems)}
              onClose={handleClose}
            />
          </div>
          <div className="SearchBar__searchItems">
            {isEmpty(searchKeyword) && !called ? (
              <div className="SearchBar__searchKeywordEmpty">{t('searchBar.searchForTitle')}</div>
            ) : (
              <DynamicSearchResult
                searchItems={take(searchedItems, DYNAMIC_SEARCH_PAGE_SIZE)}
                loading={loading}
                called={called}
                onCloseSidebar={handleClose}
              />
            )}
          </div>
          {!isEmpty(searchedItems) && (
            <div className="SearchBar__linkWrapper">
              <LinkWithLocale
                className="SearchBar__loadMoreLink"
                to={`/search?q=${searchKeyword}`}
                onClick={handleClose}
              >
                {t('searchResult.loadMore')}
              </LinkWithLocale>
            </div>
          )}
        </div>
      </aside>
    </Portal>
  )
}
