import axios from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { isString, includes, merge, isEmpty } from 'lodash'

import { HTTPStatus } from '../constants/enums'
import { getStoredUserToken } from './user'

export const instance = axios.create()

export interface RequestApiParams {
  url: string
  header?: { [key: string]: any }
  body?: { [key: string]: any }
  method?: 'get' | 'post' | 'patch' | 'delete' | 'put'
  isAuthRequest?: boolean
}

export const getRequestHeader = (params?: Pick<RequestApiParams, 'header' | 'isAuthRequest'>) => {
  const { header, isAuthRequest } = params ?? { header: {}, isAuthRequest: false }
  const token = getStoredUserToken()
  const headers: { [key: string]: any } = merge(
    {
      'content-type': 'application/json',
    },
    header
  )

  if (!token) {
    return headers
  }

  if (isAuthRequest) {
    headers.Authorization = `ODX ${token}`
  }

  return headers
}

export const parseData = (data: string | { [key: string]: any }) => {
  const parsedData = isString(data) ? JSON.parse(data) : data
  const camelizedData = camelizeKeys(parsedData)

  return camelizedData
}

const serverError = [HTTPStatus.SERVER_ERROR, HTTPStatus.SERVICE_UNAVALIABLE, HTTPStatus.BAD_GATEWAY]

export const isServerError = (errorCode: number) => includes(serverError, errorCode)

export const requestApi = async ({
  url,
  header,
  body,
  method = 'get',
  isAuthRequest = true,
}: RequestApiParams): Promise<any> => {
  const params: { [key: string]: any } = {
    method,
    url,
    headers: getRequestHeader({ header, isAuthRequest }),
  }

  if (body && !isEmpty(body)) {
    params.data = decamelizeKeys(body)
  }

  const { data } = await instance.request(params)

  return parseData(data || {})
}
