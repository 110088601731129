import './EpisodeSubInfo.scss'

import React, { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { includes } from 'lodash'

import { ReleaseStatus } from '../constants/enums'

interface Props {
  latestEpisodeTitle: string | undefined
  episodeTotal: number
  releaseStatus: ReleaseStatus | undefined
}

export default memo(function EpisodeSubInfo({ latestEpisodeTitle, episodeTotal, releaseStatus }: Props) {
  const { t } = useTranslation()

  const getIsShowLatestEpisodeTitle = useCallback(
    () =>
      includes([ReleaseStatus.ONAIR, ReleaseStatus.COMINGSOON, ReleaseStatus.UNKNOWN], releaseStatus) &&
      latestEpisodeTitle,
    [releaseStatus, latestEpisodeTitle]
  )
  const getIsShowEpisodeTotal = useCallback(() => releaseStatus === ReleaseStatus.COMPLETED && !!episodeTotal, [
    releaseStatus,
    episodeTotal,
  ])

  return (
    <span className="EpisodeSubInfo">
      {getIsShowLatestEpisodeTitle() && `${t('itemLink.updatedEpisode')} ${latestEpisodeTitle}`}
      {getIsShowEpisodeTotal() && `${episodeTotal} ${t('itemLink.totalEpisodes')}`}
    </span>
  )
})
