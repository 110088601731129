import React, { ReactElement } from 'react'
import { Redirect } from 'react-router'
import { endsWith, includes } from 'lodash'

import useRouter from '../hooks/useRouter'
import { removeLastSlash } from '../utils/misc'

interface Props {
  children: ReactElement
}

function UrlChecker({ children }: Props) {
  const {
    location: { pathname, search },
  } = useRouter()

  if (includes(['', '/'], pathname)) {
    return <Redirect to="/zh-Hans" />
  }

  if (endsWith(pathname, '/')) {
    return <Redirect to={`${removeLastSlash(pathname)}${search}`} />
  }

  return children
}

export default UrlChecker
