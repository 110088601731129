import './ProgramMeta.scss'

import React, { MouseEvent } from 'react'
import { upperCase } from 'lodash'
import { useTranslation } from 'react-i18next'

import LinkWithLocale from '../../components/LinkWithLocale'
import SearchLink from './SearchLink'

import { createPeopleData, createCategoryData } from '../../utils/metaUtils'
import { SearchItemFragment } from '../../types/generated'

interface Props {
  item: SearchItemFragment
}

export default function ProgramMeta({ item }: Props) {
  const { t } = useTranslation()
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation()
  }

  const { produceYear, meta, categories, genres, people } = item

  return (
    <div className="ProgramMeta">
      <div className="ProgramMeta__row">
        <LinkWithLocale
          className="ProgramMeta__basicInfoLink"
          to={`/search?produce_year=${produceYear}`}
          onClick={handleClick}
        >
          {produceYear}
        </LinkWithLocale>
        <SearchLink
          className="ProgramMeta__nation"
          links={meta?.produceCountries.map(nation => ({
            id: nation,
            title: t(nation),
            to: `/search?produce_country=${nation}`,
          }))}
        />
        <SearchLink className="ProgramMeta__category" links={createCategoryData(categories)} />
        <SearchLink className="ProgramMeta__genre" links={createCategoryData(genres, true)} />
      </div>
      <div className="ProgramMeta__row">
        <SearchLink
          className="ProgramMeta__castLink"
          links={createPeopleData(people, 'cast')}
          title={upperCase(t('searchResult.cast'))}
        />
      </div>
      <div className="ProgramMeta__row">
        <SearchLink
          className="ProgramMeta__directorLink"
          links={createPeopleData(people, 'director')}
          title={upperCase(t('searchResult.director'))}
        />
        <SearchLink
          wrapperClassName="ProgramMeta__writerLinks"
          className="ProgramMeta__writer"
          links={createPeopleData(people, 'writer')}
          title={upperCase(t('searchResult.writer'))}
        />
      </div>
    </div>
  )
}
