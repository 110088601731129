import { useEffect } from 'react'
import { throttle } from 'lodash'

export default function useScrollEvent(onScroll: (e?: Event) => void, throttleTime = 100) {
  useEffect(() => {
    const throttledOnScroll = throttle(onScroll, throttleTime)
    window.addEventListener('scroll', throttledOnScroll)
    return () => {
      window.removeEventListener('scroll', throttledOnScroll)
    }
    // eslint-disable-next-line
  }, [])
}
