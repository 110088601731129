import React, { createContext, useReducer, FC } from 'react'

import { Action } from '../types/common'

import { User } from '../types/models'

const ActionTypes = {
  AUTHENTICATE_SUCCESS: 'AUTHENTICATE_SUCCESS',
  AUTHENTICATE_CANCEL: 'AUTHENTICATE_CANCEL',
  AUTHENTICATE: 'AUTHENTICATE',
  SET_CURRENT_LOCALE: 'SET_CURRENT_LOCALE',
  USER_INFO_UPDATE_SUCCESS: 'USER_INFO_UPDATE_SUCCESS',
  USER_INFO_UPDATE_FAILED: 'USER_INFO_UPDATE_FAILED',
}

const initializeState: User & {
  isAuthenticated: boolean | null
  isLoading: boolean
  currentLocale: string | null
} = {
  id: null,
  username: '',
  email: '',
  info: null,
  isAuthenticated: null,
  isLoading: false,
  currentLocale: null,
}

const reducer = (state: User = initializeState, action: Action) => {
  switch (action.type) {
    case ActionTypes.AUTHENTICATE:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.AUTHENTICATE_SUCCESS:
      return {
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
      }
    case ActionTypes.AUTHENTICATE_CANCEL:
      return { ...initializeState, isAuthenticated: false }
    case ActionTypes.USER_INFO_UPDATE_SUCCESS:
      return {
        ...state,
        info: action.payload,
      }
    case ActionTypes.USER_INFO_UPDATE_FAILED:
      return state
    case ActionTypes.SET_CURRENT_LOCALE:
      return {
        ...state,
        currentLocale: action.payload,
      }
    default:
      return state
  }
}

export const UserContext = createContext({
  ...initializeState,
  ActionTypes,
  // eslint-disable-next-line
  dispatch: (action: Action) => {},
})

export const UserContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initializeState)
  const value = {
    ...state,
    ActionTypes,
    dispatch,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
