import React from 'react'

import { getTranslateValue } from '../utils/locale'
import useLanguage from '../hooks/useLanguage'

interface Props {
  target: any
  fieldKey: string
}

export default function Translate({ target, fieldKey }: Props) {
  const language = useLanguage()

  return <>{getTranslateValue(target, fieldKey, language)}</>
}
