import { ReactNode, ReactElement } from 'react'
import ReactDOM from 'react-dom'

interface Props {
  children: ReactNode
  position: 'top' | 'bottom'
}

const Portal = ({ children, position }: Props): ReactElement => {
  const $el = document.getElementById(`portal-${position}`)

  if ($el === null) {
    throw new Error('portal target not found. plz check index.html.')
  }
  return ReactDOM.createPortal(children, $el)
}

export default Portal
