import {
  CurationCarouselContentsFragment,
  CurationCarouselContentsProgramFragment,
  CurationCarouselContentsCarouselWatchHistoryFragment,
} from '../types/generated'

export const isCarouselProgram = (
  carouselContent: CurationCarouselContentsFragment
): carouselContent is CurationCarouselContentsProgramFragment => carouselContent.__typename === 'Program'

export const isCarouselCWEpisode = (
  carouselContent: CurationCarouselContentsFragment
): carouselContent is CurationCarouselContentsCarouselWatchHistoryFragment =>
  carouselContent.__typename === 'CarouselWatchHistory'
