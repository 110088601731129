import './Loading.scss'

import React from 'react'
import classNames from 'classnames'

interface Props {
  visible: boolean
  size?: number
  white?: boolean
  className?: string
}

const Loading = ({ className, visible, size, white }: Props) => {
  if (!visible) {
    return null
  }

  return (
    <div className={classNames('Loading', className)}>
      <img
        src={`/icons/loader-${white ? 'white' : 'black'}.svg`}
        alt="loading indicator"
        className="Loading__indicator"
        width={size || 20}
        height={size || 20}
      />
    </div>
  )
}

export default Loading
