const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

export const MINIMUM_PASSWORD_LENGTH = 6

export const validateEmail = (email: string): boolean => emailRegex.test(email)

export const getUserNameErrorMessage = (
  email: string,
  requireMessage = 'emailAddressIsRequired',
  invalidMessage = 'invalidEmailFormat'
): string => {
  if (!email) {
    return requireMessage
  }
  if (!emailRegex.test(email)) {
    return invalidMessage
  }

  return ''
}

export const getPasswordErrorMessage = (password: string): string => {
  if (!password) {
    return 'passwordIsRequired'
  }
  if (password.length < 6) {
    return 'passwordAtLeast6Charcters'
  }

  return ''
}
