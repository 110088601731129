import { ReactNode } from 'react'
import i18next from 'i18next'
import {
  head,
  dropRight,
  last,
  concat,
  isEmpty,
  divide,
  ceil,
  isNaN,
  startsWith,
  endsWith,
  includes,
  isUndefined,
  isNull,
} from 'lodash'

import { HTTPStatus, PosterType } from '../constants/enums'
import { toLocaleUrl } from './locale'
import { Maybe } from '../types/generated'
import { ApolloError } from '@apollo/client'
import { isAndroid, isIOS } from './userAgent'

const DEFAULT_IMAGE_SIZE = 300

export const safeMapLoop = (expectedArray: any, looper: (data: any, index: number) => ReactNode | string) => {
  if (Array.isArray(expectedArray)) {
    return expectedArray.map(looper)
  }
  return []
}

export const safeFilterLoop = <T>(expectedArray: T[], looper: (data: T, index: number) => ReactNode) => {
  if (Array.isArray(expectedArray)) {
    return expectedArray.filter(looper)
  }
  return []
}

export const getErrorMessage = (i18nKey: string | string[]) => {
  const key = typeof i18nKey === 'string' ? i18nKey : head<string>(i18nKey)

  if (!key) {
    return ''
  }

  return i18next.t(key)
}

const createImageUrl = (domain: string[], width: number, height: number, imageUrl: string) =>
  concat(domain, `/fit-in/${width}x${height}`, imageUrl).join('')

export const getSizedImageUrl = (imagePath: Maybe<string> | undefined, width?: number, height?: number) => {
  /* NOTE: This function is presupposing that image has serving from cloudfront.
   *       If image serving domain changes, this function must change regex.
   */
  if (!imagePath) {
    return ''
  }
  const splitedPath = imagePath.split(/(.*cloudfront.net)/g)
  const domain = dropRight(splitedPath)
  const imageUrl = last(splitedPath)

  if (isEmpty(domain) || !imageUrl) {
    return imagePath
  }

  const isIncludeSize = /\/fit-in\/[\dx]*/g.test(imageUrl)
  if (isIncludeSize && !width && !height) {
    return imagePath
  }

  const imageWidth = width ?? DEFAULT_IMAGE_SIZE
  const imageHeight = height ?? DEFAULT_IMAGE_SIZE

  return createImageUrl(
    domain,
    imageWidth,
    imageHeight,
    isIncludeSize ? imageUrl.replace(/\/fit-in\/[\dx]*/g, '') : imageUrl
  )
}

export const getCommaSeparatedStr = (titles: any[]) => {
  const length = titles ? titles.length : 0

  return (title: string, index: number) => {
    if (length === 0) {
      return null
    }
    return `${title}${index === length - 1 ? '' : ', '}`
  }
}

export const notEmpty = <T>(value: T | null | undefined): value is T => value !== null && value !== undefined

export const getVideoDuration = (videoDuration: number | string) => {
  const onlyNumberRegex = /^[0-9]+$/g
  if (typeof videoDuration === 'string' && !onlyNumberRegex.test(videoDuration)) {
    return null
  }
  const duration = typeof videoDuration === 'number' ? videoDuration : parseInt(videoDuration, 10)
  if (isNaN(duration)) {
    return null
  }

  return ceil(divide(duration, 60 * 1000))
}

export const safeGet = (f: () => any, fallback?: any) => {
  try {
    return f()
  } catch (e) {
    return fallback || null
  }
}

export const getRedirectUrlByErrorcode = (errorCode: number | null) => {
  if (!errorCode) {
    return null
  }
  const currentPath = window.location.href

  switch (errorCode) {
    case HTTPStatus.NOT_FOUND:
      return toLocaleUrl(`/errors/notfound?prev-url=${currentPath}`)
    case HTTPStatus.GONE:
      return toLocaleUrl(`/errors/expired?prev-url=${currentPath}`)
    case HTTPStatus.UNAUTHORIZED:
      return toLocaleUrl('/sign/sign-in')
    default:
      return null
  }
}

export const isOutLink = (url: string) =>
  startsWith(url, 'http://') || startsWith(url, 'https://') || startsWith(url, '//')

export const removeLastSlash = (url: string) => (endsWith(url, '/') ? url.substring(0, url.length - 1) : url)

export const isDevelopment = () =>
  includes(['local', 'development'], process.env.REACT_APP_DEPLOY_ENV) ||
  new URLSearchParams(window.location.search).has('debug')

export const getDevelopmentEnvironment = () => process.env.REACT_APP_DEPLOY_ENV!

export const getLogger = () => {
  if (isDevelopment() && window.console) {
    return window.console
  }
  return {
    log: () => {
      // eslint-disable-nextline
    },
    error: () => {
      // eslint-disable-nextline
    },
  }
}

export const logger = getLogger()

export const createLogger = (prefix: string, bgColor: string, color: string) => {
  if (isDevelopment() && window.console) {
    return (message: string) => console.log(`%c[${prefix}] ${message}`, `background: ${bgColor}; color: ${color}`)
  } else {
    // eslint-disable-next-line
    return (message: string) => {}
  }
}

export const getPlatform = () => {
  if (isIOS) {
    return 'web-iOS'
  }

  if (isAndroid) {
    return 'web-android'
  }

  return 'web'
}

export const isPortrait = (imageRatio: PosterType | null, posterTypeFromProps?: PosterType) => {
  if (isNull(imageRatio) && isUndefined(posterTypeFromProps)) {
    return false
  }
  // NOTE: posterTypeFromProps has high priority than imageRatio
  if (isUndefined(posterTypeFromProps)) {
    return imageRatio === PosterType.PORTRAIT
  }
  return posterTypeFromProps === PosterType.PORTRAIT
}

export const reloadPage = () => window.location.reload()

export const isTouchableDevice = () => 'ontouchstart' in document.documentElement

export const getGraphQLErrorMessage = (error: ApolloError, key: string) =>
  error.graphQLErrors.find(graphQLError => graphQLError.body?.[key])?.body?.[key][0]

export const isDebug = () => /debug=y/.test(window.location.href)
