import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Any type value. Be careful to handling this type data. */
  Any: any;
  /** An ISO-8601 encoded date string. */
  Date: any;
};

/** Input type of AddFavorite */
export type AddFavoriteInput = {
  /** A slug of program */
  programSlug: Scalars['String'];
};

/** Return type of AddFavorite */
export type AddFavoritePayload = {
  __typename?: 'AddFavoritePayload';
  /** Program that have successfully added favorite of user */
  item: Program;
  result: Scalars['Boolean'];
};

export type AgeRating = {
  __typename?: 'AgeRating';
  /** A label of ageRating ('All', 'PG', '12+', '13+', '14+', '15+', '16+', '17+', '18+', '19+', 'NR') */
  label: Scalars['String'];
  /** A name of ageRating */
  name: AgeRatingName;
  /** A isBlock status of ageRating */
  isBlock: Scalars['Boolean'];
};

export enum AgeRatingName {
  ALL = 'All',
  PG = 'PG',
  R12 = 'R12',
  R13 = 'R13',
  R14 = 'R14',
  R15 = 'R15',
  R16 = 'R16',
  R17 = 'R17',
  R18 = 'R18',
  R19 = 'R19',
  NR = 'NR'
}

export enum AllowedCountries {
  KR = 'KR',
  CN = 'CN',
  US = 'US',
  CA = 'CA',
  TW = 'TW',
  HK = 'HK',
  JP = 'JP'
}


/** Directive */
export enum CacheControlScope {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

/** Return type of CancelChangeSubscriptionPlan */
export type CancelChangeSubscriptionPlanPayload = {
  __typename?: 'CancelChangeSubscriptionPlanPayload';
  result: Scalars['Boolean'];
};

export type CarouselItem = Program | Episode | CarouselWatchHistory;

export type CarouselWatchHistory = {
  __typename?: 'CarouselWatchHistory';
  progressRatio: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  uuid: Scalars['ID'];
  /** A list of content providers */
  cp: Array<Scalars['String']>;
  /** A set of episode image URLs according to languages */
  images: Maybe<Images>;
  /**
   * A kind of episode
   * 
   * SERIES
   * MAIN
   * TRAILER
   * ADDITIONAL
   * CLIP
   */
  kind: Scalars['String'];
  /** A next episode information if it exists */
  nextEpisode: Maybe<Episode>;
  /** An episode number of program */
  number: Scalars['Int'];
  /** A part number of episode if it exists */
  part: Maybe<Scalars['Int']>;
  /** A list of producers, writers and actors related to the current episode */
  people: Array<People>;
  /** Program information containing a episode */
  program: Program;
  /** A release date of episode */
  releaseDate: Maybe<Scalars['String']>;
  /** A SEO keyword of episode */
  seoKeyword: Maybe<Scalars['String']>;
  seoKeywordEn: Maybe<Scalars['String']>;
  seoKeywordKo: Maybe<Scalars['String']>;
  seoKeywordZhHans: Maybe<Scalars['String']>;
  seoKeywordZhHant: Maybe<Scalars['String']>;
  /** A SEO synopsis of episode */
  seoSynopsis: Maybe<Scalars['String']>;
  seoSynopsisEn: Maybe<Scalars['String']>;
  seoSynopsisKo: Maybe<Scalars['String']>;
  seoSynopsisZhHans: Maybe<Scalars['String']>;
  seoSynopsisZhHant: Maybe<Scalars['String']>;
  /** A SEO title of episode */
  seoTitle: Maybe<Scalars['String']>;
  seoTitleEn: Maybe<Scalars['String']>;
  seoTitleKo: Maybe<Scalars['String']>;
  seoTitleZhHans: Maybe<Scalars['String']>;
  seoTitleZhHant: Maybe<Scalars['String']>;
  /** A synopsis of episode */
  synopsis: Maybe<Scalars['String']>;
  synopsisEn: Maybe<Scalars['String']>;
  synopsisKo: Maybe<Scalars['String']>;
  synopsisZhHans: Maybe<Scalars['String']>;
  synopsisZhHant: Maybe<Scalars['String']>;
  /** A title of episode */
  title: Maybe<Scalars['String']>;
  titleEn: Maybe<Scalars['String']>;
  titleKo: Maybe<Scalars['String']>;
  titleZhHans: Maybe<Scalars['String']>;
  titleZhHant: Maybe<Scalars['String']>;
  /** A list of cc languages */
  videoCcLanguages: Array<Scalars['String']>;
  /** A video duration */
  videoDuration: Scalars['Int'];
};

/** A category-based navigation or category itself (https://admin.ondemandchina.com/service/category-navigation/1) */
export type Category = {
  __typename?: 'Category';
  id: Scalars['Int'];
  /** The category slug */
  slug: Scalars['String'];
  /** A type of category (e.g., 'category' | 'genre') */
  type: Maybe<Scalars['String']>;
  /** A list of sub-categories */
  items: Array<Category>;
  /** An aspect ratio of posters based on category ('portrait' | 'landscape') */
  ratio: Maybe<Scalars['String']>;
  /** A title of category */
  titleEn: Maybe<Scalars['String']>;
  titleZhHans: Scalars['String'];
  titleZhHant: Maybe<Scalars['String']>;
  /** A SEO title of category */
  seoTitleEn: Maybe<Scalars['String']>;
  seoTitleZhHans: Maybe<Scalars['String']>;
  seoTitleZhHant: Maybe<Scalars['String']>;
  /** A SEO description of category */
  seoDescriptionEn: Maybe<Scalars['String']>;
  seoDescriptionZhHans: Maybe<Scalars['String']>;
  seoDescriptionZhHant: Maybe<Scalars['String']>;
  /** A SEO keywords of category */
  seoKeywordsEn: Maybe<Scalars['String']>;
  seoKeywordsZhHans: Maybe<Scalars['String']>;
  seoKeywordsZhHant: Maybe<Scalars['String']>;
};

/** Input type of ChangeSubscriptionPlan */
export type ChangeSubscriptionPlanInput = {
  planCode: Scalars['String'];
  /** Is coupon code not nullable? */
  couponCode: Scalars['String'];
  /** Flag value for changing subscription plan wheter immediate or wait for end of current period */
  changeNow: Scalars['Boolean'];
};

/** Return type of ChangeSubscriptionPlan */
export type ChangeSubscriptionPlanPayload = {
  __typename?: 'ChangeSubscriptionPlanPayload';
  result: Scalars['Boolean'];
  item: ChangeSubscriptionPlanResult;
};

/** ODKMedia.io response for subscription change */
export type ChangeSubscriptionPlanResult = {
  __typename?: 'ChangeSubscriptionPlanResult';
  planCode: Scalars['String'];
  changeData: Scalars['Date'];
};

/** Input type of CodeVerification */
export type CodeVerificationInput = {
  /** An User's email */
  email: Scalars['String'];
  /** A verficiation code in verification email */
  code: Scalars['String'];
};

/** Return type of CodeVerification */
export type CodeVerificationPayload = {
  __typename?: 'CodeVerificationPayload';
  result: Scalars['Boolean'];
};

/** Input type of ContactUs */
export type ContactUsInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  subject: Scalars['String'];
  description: Scalars['String'];
};

/** Return type of ContactUs */
export type ContactUsPayload = {
  __typename?: 'ContactUsPayload';
  result: Scalars['Boolean'];
};

export type Content = Program | Episode;

/** A content provider information about program */
export type ContentProvider = {
  __typename?: 'ContentProvider';
  /** A code of contentProvider */
  code: Scalars['String'];
  /** A name of contentProvider */
  name: Scalars['String'];
};

export enum ContentType {
  PROGRAM = 'PROGRAM',
  EPISODE = 'EPISODE'
}

export type Cover = {
  __typename?: 'Cover';
  /** The URL pointing to the program or episode page */
  url: Scalars['String'];
  /**
   * The URL of cover image
   * @deprecated Use thumbnailImage, coverImage field.
   */
  image: Scalars['String'];
  thumbnailImage: Scalars['String'];
  coverImage: Scalars['String'];
  /** A reserved field for Google Analytics */
  tracking: Maybe<Scalars['String']>;
  /** The title of Cover */
  titleEn: Maybe<Scalars['String']>;
  titleZhHans: Scalars['String'];
  titleZhHant: Maybe<Scalars['String']>;
  /** The description of Cover */
  descriptionEn: Maybe<Scalars['String']>;
  descriptionZhHans: Scalars['String'];
  descriptionZhHant: Maybe<Scalars['String']>;
  /** The action name that is rendered on watchButton element */
  callToActionEn: Maybe<Scalars['String']>;
  callToActionZhHans: Scalars['String'];
  callToActionZhHant: Maybe<Scalars['String']>;
  /** The list of user types that determine whether a page exposes cover or not */
  exposeUserTypes: Maybe<Array<Maybe<ExposeUserType>>>;
};


export type CoverThumbnailImageArgs = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  clippingType?: Maybe<ImageClippingType>;
};


export type CoverCoverImageArgs = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  clippingType?: Maybe<ImageClippingType>;
};

/** A list of covers that mimic cursor connections (https://admin.ondemandchina.com/service/cover) */
export type CoverCollection = {
  __typename?: 'CoverCollection';
  id: Scalars['Int'];
  /** A cover collection slug (e.g., 'odc') */
  slug: Scalars['String'];
  /** A filter for sorting the list of covers ('random' | 'manual') */
  sortBy: Maybe<Scalars['String']>;
  /** A list of covers */
  nodes: Array<Cover>;
};

/** Input type of CreateSubscriptionPlan */
export type CreateSubscriptionPlanInput = {
  paymentMethod: Scalars['ID'];
  planCode: Scalars['String'];
  couponCode?: Maybe<Scalars['String']>;
};

/** Return type of CreateSubscriptionPlan */
export type CreateSubscriptionPlanPayload = {
  __typename?: 'CreateSubscriptionPlanPayload';
  result: Scalars['Boolean'];
  item: SubscriptionSpecification;
};

export type Curation = {
  __typename?: 'Curation';
  /** A slug of Curation (e.g., 'continue-watching' | 'new-release-movies') */
  slug: Scalars['String'];
  /** The content type of curation */
  type: Scalars['String'];
  /** An aspect ratio of curation ('portrait' | 'landscape') */
  ratio: Scalars['String'];
  /** The title of curation */
  titleEn: Maybe<Scalars['String']>;
  titleZhHans: Scalars['String'];
  titleZhHant: Maybe<Scalars['String']>;
  /** The URL pointing to predefined link (e.g., /category/drama, outlink) */
  titleUrl: Maybe<Scalars['String']>;
  /** The maximum amount of carousel items in curation */
  max: Maybe<Scalars['Int']>;
  /** The ids of programs or episodes */
  manualIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Identifies whether the program carousel item will be automatically played or not */
  programDirectPlay: Maybe<Scalars['Boolean']>;
  /** A category id of curation */
  logicCategory: Maybe<Scalars['Int']>;
  /**
   * A filter for sorting the curation to enumerate carousel items
   * 
   * 'episode-continue-watching',
   * 'episode-recent-updated',
   * 'program-recent-updated',
   * 'program-popular',
   * 'program-custom-drama',
   * 'program-custom-variety',
   * 'program-custom-documentary',
   * 'program-custom-movie',
   * 'program-custom-news',
   * 'program-custom-kids'
   */
  logicOrdering: Maybe<Scalars['String']>;
  /** The list of carousel items in Curation */
  carousels: Array<CarouselItem>;
};

export type CurationCollection = {
  __typename?: 'CurationCollection';
  id: Scalars['Int'];
  /** A slug of CurationCollection ('odc' | 'right-sidebar') */
  slug: Scalars['String'];
  /** A list of curations */
  nodes: Array<Curation>;
};

export enum CurationType {
  HOME = 'HOME',
  SIDE_CONTENT = 'SIDE_CONTENT'
}


/** Input type of DeleteUser */
export type DeleteUserInput = {
  /** The current password of user */
  password: Scalars['String'];
};

/** Return type of DeleteUser */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  result: Scalars['Boolean'];
};

/** A information of direct play for program */
export type DirectPlay = {
  __typename?: 'DirectPlay';
  type: ContentType;
  /** Link for redirecting to appropriate page */
  link: Scalars['String'];
};

/** Input type of email verficiation */
export type EmailVerificationInput = {
  /** An User's email */
  email: Scalars['String'];
};

/** Return type of email verification */
export type EmailVerificationPayload = {
  __typename?: 'EmailVerificationPayload';
  result: Scalars['Boolean'];
};

export type Episode = {
  __typename?: 'Episode';
  id: Scalars['Int'];
  uuid: Scalars['ID'];
  /** A list of content providers */
  cp: Array<Scalars['String']>;
  /** A set of episode image URLs according to languages */
  images: Maybe<Images>;
  /**
   * A kind of episode
   * 
   * SERIES
   * MAIN
   * TRAILER
   * ADDITIONAL
   * CLIP
   */
  kind: Scalars['String'];
  /** A next episode information if it exists */
  nextEpisode: Maybe<Episode>;
  /**
   * An episode number of program (deprecated)
   * @deprecated Use episodeNumber field.
   */
  number: Scalars['Int'];
  /** An episode number of program */
  episodeNumber: Scalars['Int'];
  /** A part number of episode if it exists */
  part: Maybe<Scalars['Int']>;
  /** A list of producers, writers and actors related to the current episode */
  people: Array<People>;
  /** Program information containing a episode */
  program: Program;
  /** A release date of episode */
  releaseDate: Maybe<Scalars['String']>;
  /** The URL path of episode page */
  link: Scalars['String'];
  /** A SEO keyword of episode */
  seoKeyword: Maybe<Scalars['String']>;
  seoKeywordEn: Maybe<Scalars['String']>;
  seoKeywordKo: Maybe<Scalars['String']>;
  seoKeywordZhHans: Maybe<Scalars['String']>;
  seoKeywordZhHant: Maybe<Scalars['String']>;
  /** A SEO synopsis of episode */
  seoSynopsis: Maybe<Scalars['String']>;
  seoSynopsisEn: Maybe<Scalars['String']>;
  seoSynopsisKo: Maybe<Scalars['String']>;
  seoSynopsisZhHans: Maybe<Scalars['String']>;
  seoSynopsisZhHant: Maybe<Scalars['String']>;
  /** A SEO title of episode */
  seoTitle: Maybe<Scalars['String']>;
  seoTitleEn: Maybe<Scalars['String']>;
  seoTitleKo: Maybe<Scalars['String']>;
  seoTitleZhHans: Maybe<Scalars['String']>;
  seoTitleZhHant: Maybe<Scalars['String']>;
  /** A synopsis of episode */
  synopsis: Maybe<Scalars['String']>;
  synopsisEn: Maybe<Scalars['String']>;
  synopsisKo: Maybe<Scalars['String']>;
  synopsisZhHans: Maybe<Scalars['String']>;
  synopsisZhHant: Maybe<Scalars['String']>;
  /** A title of episode */
  title: Maybe<Scalars['String']>;
  titleEn: Maybe<Scalars['String']>;
  titleKo: Maybe<Scalars['String']>;
  titleZhHans: Maybe<Scalars['String']>;
  titleZhHant: Maybe<Scalars['String']>;
  /** A list of cc languages */
  videoCcLanguages: Array<Scalars['String']>;
  /** A video duration */
  videoDuration: Scalars['Int'];
  /** An ageRating information of episode */
  ageRating: Maybe<AgeRating>;
};

/** A list of episodes that mimic cursor connections */
export type EpisodeConnection = {
  __typename?: 'EpisodeConnection';
  /** Total amount of episodes */
  totalCount: Scalars['Int'];
  /** A list of episodes */
  nodes: Array<Episode>;
  /** A pagination information */
  pageInfo: PageInfo;
};

export enum EpisodesOrderTypes {
  NEWEST = 'NEWEST',
  OLDEST = 'OLDEST'
}

export enum ExposeUserType {
  ALL = 'ALL',
  GUEST = 'GUEST',
  FREE = 'FREE',
  PLUS = 'PLUS',
  PPV = 'PPV',
  ADULT = 'ADULT'
}

/** A list of FAQ Tabs */
export type Faq = {
  __typename?: 'FAQ';
  /** An id of FAQ Tab */
  id: Scalars['Float'];
  /** A name of FAQ Tab */
  name: Scalars['String'];
  /** FAQ Articles in FAQ Tab */
  articles: Array<FaqArticle>;
};


/** A list of FAQ Tabs */
export type FaqArticlesArgs = {
  locale: Maybe<Language>;
};

/** A FAQ article to be rendered at Accordion */
export type FaqArticle = {
  __typename?: 'FAQArticle';
  /** An id of FAQ Article */
  id: Scalars['Float'];
  /** A title of FAQ Article */
  title: Scalars['String'];
  /** A markup content of FAQ Article */
  description: Scalars['String'];
};

/** A connection of favorite programs those are added by user */
export type FavoriteConnection = {
  __typename?: 'FavoriteConnection';
  /** Total amount of favorite programs */
  totalCount: Scalars['Int'];
  /** A list of favorite programs */
  nodes: Array<Program>;
  /** Inforation about pagination in a favorite connection */
  pageInfo: PageInfo;
};

/** Return type of FavoriteProgram */
export type FavoriteProgramResult = {
  __typename?: 'FavoriteProgramResult';
  item: Maybe<Program>;
  result: Scalars['Boolean'];
};

/** Input type of ForgotPassword */
export type ForgotPasswordInput = {
  /** The user's email address */
  email: Scalars['String'];
  /** The new password that will be updated into DB */
  password: Scalars['String'];
  /** The confirm password */
  confirmPassword: Scalars['String'];
  /** Verification code to authenticate user */
  code: Scalars['String'];
};

/** Return type of ForgotPassword */
export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload';
  result: Scalars['Boolean'];
};

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NO_ANSWER = 'NO_ANSWER',
  NOT_EXIST = 'NOT_EXIST'
}

export enum ImageClippingType {
  SMART = 'SMART',
  CENTER = 'CENTER'
}

/** Information about image URLs of the content */
export type Images = {
  __typename?: 'Images';
  /** The URL of image that is equivalent with postZhHans */
  poster: Maybe<Scalars['String']>;
  posterZhHans: Maybe<Scalars['String']>;
  posterZhHant: Maybe<Scalars['String']>;
  posterEn: Maybe<Scalars['String']>;
  posterKo: Maybe<Scalars['String']>;
  /** The thumbnail URL */
  thumbnail: Maybe<Scalars['String']>;
};

export enum Language {
  EN = 'EN',
  ZH_HANS = 'ZH_HANS',
  ZH_HANT = 'ZH_HANT'
}

/** Provide additional information about program */
export type Meta = {
  __typename?: 'Meta';
  /** A list of content providers */
  cp: Array<Scalars['String']>;
  /** A rating of the program */
  score: Maybe<Scalars['Float']>;
  /** Identifies whether the content has a series or not */
  isSeries: Maybe<Scalars['Boolean']>;
  /** A filter for sorting the list of episodes in order */
  episodeOrdering: Maybe<EpisodesOrderTypes>;
  /** Total amount of episodes */
  episodeTotal: Maybe<Scalars['Int']>;
  /** A list of countries involved in production */
  produceCountries: Array<Scalars['String']>;
  /** A list of cc languages provided by the program */
  ccLanguages: Array<Scalars['String']>;
  runningTime: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Add program to user favorites */
  addFavorite: AddFavoritePayload;
  /** Remove program from user favorites */
  removeFavorite: RemoveFavoritePayload;
  /** Remove all programs from user favorites */
  removeAllFavorites: RemoveAllFavorites;
  /** Sign up */
  signup: SignUpPayload;
  /** Log in */
  signin: SignInPayload;
  /** Log out for logged-in user */
  signout: SignOutPayload;
  /** Update user profile for logged-in user */
  updateProfile: UpdateProfilePayload;
  /** Verify email address */
  verifyEmail: EmailVerificationPayload;
  /** Verify authentication code */
  verifyCode: CodeVerificationPayload;
  /** Change password for logged-in user */
  resetPassword: ResetPasswordPayload;
  /** Reset password for user who forgot password their account */
  forgotPassword: ForgotPasswordPayload;
  /** Delete account for logged-in user */
  deleteUser: DeleteUserPayload;
  /** Create a subscription for current user with given plan */
  createSubscriptionPlan: CreateSubscriptionPlanPayload;
  /** Attach given payment method to the current user's default */
  registerPaymentMethod: RegisterPaymentMethodPayload;
  /** Change subscription plan of current user */
  changeSubscriptionPlan: ChangeSubscriptionPlanPayload;
  /** Reactivate subscription plan for stopped plan */
  reactivateSubscriptionPlan: ReactivateSubscriptionPlanPayload;
  /** Cancel to changing subscription plan */
  cancelChangeSubscriptionPlan: CancelChangeSubscriptionPlanPayload;
  /** Stop subscription plan of current user when ended current plan */
  stopSubscriptionPlan: StopSubscriptionPlanPayload;
  /** Stop subscription plan of current user immediately */
  stopSubscriptionPlanImmediate: StopSubscriptionPlanImmediatePayload;
  /** Using coupon */
  registerCoupon: RegisterCouponPayload;
  /** Request CS ticket to Freshdesk */
  contactUs: ContactUsPayload;
};


export type MutationAddFavoriteArgs = {
  input: AddFavoriteInput;
};


export type MutationRemoveFavoriteArgs = {
  input: RemoveFavoriteInput;
};


export type MutationSignupArgs = {
  input: SignUpInput;
};


export type MutationSigninArgs = {
  input: SignInInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationVerifyEmailArgs = {
  input: EmailVerificationInput;
};


export type MutationVerifyCodeArgs = {
  input: CodeVerificationInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationCreateSubscriptionPlanArgs = {
  input: CreateSubscriptionPlanInput;
};


export type MutationRegisterPaymentMethodArgs = {
  input: RegisterPaymentMethodInput;
};


export type MutationChangeSubscriptionPlanArgs = {
  input: ChangeSubscriptionPlanInput;
};


export type MutationRegisterCouponArgs = {
  input: RegisterCouponInput;
};


export type MutationContactUsArgs = {
  input: ContactUsInput;
};

/** A category-based navigation (https://admin.ondemandchina.com/service/category-navigation/1) */
export type Navigation = {
  __typename?: 'Navigation';
  /** The navigation slug */
  slug: Scalars['String'];
  /** A type of navigation ('category') */
  type: Maybe<Scalars['String']>;
  /**
   * A list of sub-navigation
   * @deprecated Use genres and flatten navigation item
   */
  items: Array<Navigation>;
  /** An aspect ratio of posters based on navigation ('portrait' | 'landscape') */
  ratio: Maybe<Scalars['String']>;
  /** A title of navigation */
  titleEn: Maybe<Scalars['String']>;
  titleZhHans: Scalars['String'];
  titleZhHant: Maybe<Scalars['String']>;
  /** A SEO title of navigation */
  seoTitleEn: Maybe<Scalars['String']>;
  seoTitleZhHans: Maybe<Scalars['String']>;
  seoTitleZhHant: Maybe<Scalars['String']>;
  /** A SEO keywords of navigation */
  seoDescriptionEn: Maybe<Scalars['String']>;
  seoDescriptionZhHans: Maybe<Scalars['String']>;
  seoDescriptionZhHant: Maybe<Scalars['String']>;
  /** A SEO keyword of navigation */
  seoKeywordsEn: Maybe<Scalars['String']>;
  seoKeywordsZhHans: Maybe<Scalars['String']>;
  seoKeywordsZhHant: Maybe<Scalars['String']>;
  /** A list of generated genres in navigation */
  genres: Array<Navigation>;
  /** A slug chain from root navigation item */
  parents: Array<Scalars['String']>;
};

export type NavigationCollection = {
  __typename?: 'NavigationCollection';
  id: Scalars['Int'];
  /** A type of navigation ('odc') */
  slug: Scalars['String'];
  /** A list of navigations */
  nodes: Array<Navigation>;
};

/** Information about pagination in a connection */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** The page number of next page */
  nextPage: Maybe<Scalars['Int']>;
  /** The page number of previoue page */
  previousPage: Maybe<Scalars['Int']>;
};

/** Payment history of current user */
export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['ID'];
  brand: Scalars['String'];
  expYear: Scalars['Int'];
  expMonth: Scalars['Int'];
  last4digits: Scalars['Int'];
  created: Scalars['Date'];
};

/** Information about the person related with the content */
export type People = {
  __typename?: 'People';
  /** A person's role in the content('director' | 'cast' | 'writer') */
  role: Maybe<Scalars['String']>;
  /** A person's slug */
  slug: Maybe<Scalars['String']>;
  /** A person's name */
  nameEn: Maybe<Scalars['String']>;
  nameZhHans: Maybe<Scalars['String']>;
  nameZhHant: Maybe<Scalars['String']>;
};

export type Program = {
  __typename?: 'Program';
  id: Scalars['Int'];
  uuid: Scalars['ID'];
  slug: Scalars['String'];
  /** A list of countries allowed to play program (ISO-3166 Alpha-2 code) */
  allowedCountries: Array<AllowedCountries>;
  /** A list of categories about program */
  categories: Array<Category>;
  /** A content type that could be provided depending on the user's subscription model */
  contentType: Maybe<Scalars['String']>;
  /** Identifies whether a program would be served in public */
  enable: Maybe<Scalars['Boolean']>;
  /** A list of genre */
  genres: Array<Category>;
  /** A set of program image URLs according to languages */
  images: Maybe<Images>;
  /** A latest episode information about program */
  latestEpisode: Maybe<Episode>;
  /** Metadata of program */
  meta: Maybe<Meta>;
  /** A list of producers, writers and actors */
  people: Array<People>;
  /** The production year of program */
  produceYear: Maybe<Scalars['Int']>;
  /** A datetime when program was over */
  publishEnd: Maybe<Scalars['String']>;
  /** A datetime when program began to be published */
  publishStart: Maybe<Scalars['String']>;
  /**
   * Represents a publish status of program
   * 
   * DISABLED
   * EXPIRED
   * UNSCHEDULED
   * SCHEDULED
   * PUBLISHED
   */
  publishStatus: Scalars['String'];
  /** A datetime when program expired for release */
  releaseEnd: Maybe<Scalars['String']>;
  /** A datetime when program was released */
  releaseStart: Maybe<Scalars['String']>;
  /**
   * Represents a release status of program
   * 
   * COMING_SOON
   * COMPLETED
   * ON_AIR
   * UNKNOWN
   */
  releaseStatus: Scalars['String'];
  /** A list of days of the week to release a program */
  releaseWeekday: Array<Scalars['String']>;
  /** A secondary title of program */
  secondaryTitleEn: Maybe<Scalars['String']>;
  secondaryTitleKo: Maybe<Scalars['String']>;
  secondaryTitleZhHans: Maybe<Scalars['String']>;
  secondaryTitleZhHant: Maybe<Scalars['String']>;
  /** A SEO keywords of program */
  seoKeywordEn: Maybe<Scalars['String']>;
  seoKeywordKo: Maybe<Scalars['String']>;
  seoKeywordZhHans: Maybe<Scalars['String']>;
  seoKeywordZhHant: Maybe<Scalars['String']>;
  /** A SEO synopsis of program */
  seoSynopsisEn: Maybe<Scalars['String']>;
  seoSynopsisKo: Maybe<Scalars['String']>;
  seoSynopsisZhHans: Maybe<Scalars['String']>;
  seoSynopsisZhHant: Maybe<Scalars['String']>;
  /** A SEO title of program */
  seoTitleEn: Maybe<Scalars['String']>;
  seoTitleKo: Maybe<Scalars['String']>;
  seoTitleZhHans: Maybe<Scalars['String']>;
  seoTitleZhHant: Maybe<Scalars['String']>;
  /** A synopsis of program */
  synopsisEn: Maybe<Scalars['String']>;
  synopsisKo: Maybe<Scalars['String']>;
  synopsisZhHans: Maybe<Scalars['String']>;
  synopsisZhHant: Maybe<Scalars['String']>;
  /** A title of program */
  titleEn: Maybe<Scalars['String']>;
  titleKo: Maybe<Scalars['String']>;
  titleZhHans: Maybe<Scalars['String']>;
  titleZhHant: Maybe<Scalars['String']>;
  /** A contentProvider of program */
  contentProvider: Maybe<ContentProvider>;
  /** An ageRating information of program */
  ageRating: Maybe<AgeRating>;
};

/** A list of programs that mimic Cursor Connections in GraphQL */
export type ProgramConnection = {
  __typename?: 'ProgramConnection';
  /** Total amount of program */
  totalCount: Scalars['Int'];
  /** A list of programs */
  nodes: Array<Program>;
  /** A pagination information in a program connection */
  pageInfo: PageInfo;
};

export type Query = {
  __typename?: 'Query';
  /** A cover data at Home page */
  cover: CoverCollection;
  /** A curation data at Home page */
  curation: CurationCollection;
  /** A logged-in user data */
  user: User;
  /** A list of watch histories of logged-in user */
  watchHistories: Array<WatchHistory>;
  /** A favorite connection of logged-in user */
  favorites: FavoriteConnection;
  /** Check if program has added to favorite list for logged-in user */
  favoriteProgram: FavoriteProgramResult;
  /** A list of programs */
  programs: ProgramConnection;
  /** A one of program data by program slug */
  program: Program;
  /** A list of episodes by program slug */
  episodes: EpisodeConnection;
  /** A one of episode by program slug and episode number */
  episode: Episode;
  /** A items of navigation menu */
  navigation: NavigationCollection;
  /** A searched program list by search keyword */
  search: ProgramConnection;
  termsOfUse: TermsOfUse;
  faq: Array<Faq>;
  /** A appropriate link for direct-play by program */
  directPlay: DirectPlay;
  /** A list of payment method of current user */
  paymentMethods: Array<PaymentMethod>;
  /** A list of ALL of available plans for creating a subscription */
  subscriptionPlans: Array<SubscriptionPlan>;
  /** Parameters for initializing stripe instance */
  stripeParameter: StripeParamter;
  /** A list of subscription history of current user */
  subscriptionHistories: Array<SubscriptionSpecification>;
  /** A list of pending schedules of current user */
  pendingSchedules: Array<Schedule>;
};


export type QueryCurationArgs = {
  type: CurationType;
};


export type QueryFavoritesArgs = {
  page: Maybe<Scalars['Int']>;
};


export type QueryFavoriteProgramArgs = {
  programSlug: Scalars['String'];
};


export type QueryProgramsArgs = {
  categorySlug: Scalars['String'];
  releaseStatus: Maybe<Scalars['String']>;
  genreSlug: Maybe<Scalars['String']>;
  sortFilter: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
};


export type QueryProgramArgs = {
  programSlug: Scalars['String'];
};


export type QueryEpisodesArgs = {
  programSlug: Scalars['String'];
  ordering: EpisodesOrderTypes;
  page: Maybe<Scalars['Int']>;
};


export type QueryEpisodeArgs = {
  programSlug: Scalars['String'];
  kind: Scalars['String'];
  episodeNumber: Scalars['Int'];
  part: Maybe<Scalars['Int']>;
};


export type QueryNavigationArgs = {
  slug: Scalars['String'];
};


export type QuerySearchArgs = {
  keyword: Maybe<Scalars['String']>;
  category: Maybe<Scalars['String']>;
  genre: Maybe<Scalars['String']>;
  person: Maybe<Scalars['String']>;
  produceCountry: Maybe<Scalars['String']>;
  produceYear: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
};


export type QueryFaqArgs = {
  locale: Maybe<Language>;
};


export type QueryDirectPlayArgs = {
  programSlug: Scalars['String'];
};

/** Return type of ReactivateSubscriptionPlan */
export type ReactivateSubscriptionPlanPayload = {
  __typename?: 'ReactivateSubscriptionPlanPayload';
  result: Scalars['Boolean'];
};

/** Input type of RegisterCoupon */
export type RegisterCouponInput = {
  couponCode: Scalars['String'];
};

/** Return type of RegisterCoupon */
export type RegisterCouponPayload = {
  __typename?: 'RegisterCouponPayload';
  result: Scalars['Boolean'];
};

/** Input type of RegisterPaymentMethod */
export type RegisterPaymentMethodInput = {
  paymentMethodId: Scalars['ID'];
};

/** Return type of RegisterPaymentMethod */
export type RegisterPaymentMethodPayload = {
  __typename?: 'RegisterPaymentMethodPayload';
  result: Scalars['Boolean'];
};

/** Return type of RemoveFavoritesAll */
export type RemoveAllFavorites = {
  __typename?: 'RemoveAllFavorites';
  result: Scalars['Boolean'];
};

/** Input type of RemoveFavorite */
export type RemoveFavoriteInput = {
  programSlug: Scalars['String'];
};

/** Return type of RemoveFavorite */
export type RemoveFavoritePayload = {
  __typename?: 'RemoveFavoritePayload';
  result: Scalars['Boolean'];
};

/** Input type of ResetPassword */
export type ResetPasswordInput = {
  /** The current password */
  password: Scalars['String'];
  /** The new password */
  newPassword: Scalars['String'];
  /** The new confirm password */
  confirmPassword: Scalars['String'];
};

/** Return type of ResetPassword */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  result: Scalars['Boolean'];
};

export type Schedule = {
  __typename?: 'Schedule';
  plan: Scalars['Int'];
  status: ScheduleStatus;
  startDate: Scalars['Date'];
};

export enum ScheduleStatus {
  NOT_STARTED = 'NOT_STARTED',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  RELEASED = 'RELEASED',
  CANCELED = 'CANCELED'
}

/** Input type of Signin */
export type SignInInput = {
  /** An User's email */
  username: Scalars['String'];
  /** A password of user */
  password: Scalars['String'];
  /** Identifies preserved signin */
  keep?: Maybe<Scalars['Boolean']>;
};

/** Return type of Signin */
export type SignInPayload = {
  __typename?: 'SignInPayload';
  result: Scalars['Boolean'];
  item: SignInResult;
};

/** Actutal SignIn response of media.io */
export type SignInResult = {
  __typename?: 'SignInResult';
  /** An access token of user */
  token: Scalars['String'];
  /** An User information */
  user: User;
};

/** Return type of Signout */
export type SignOutPayload = {
  __typename?: 'SignOutPayload';
  result: Scalars['Boolean'];
};

/** Input type of SignUp */
export type SignUpInput = {
  username: Scalars['String'];
  password: Scalars['String'];
  confirmPassword: Scalars['String'];
  code: Scalars['String'];
};

/** Return type of SignUp */
export type SignUpPayload = {
  __typename?: 'SignUpPayload';
  result: Scalars['Boolean'];
  item: SignUpResult;
};

/** Actual response value of signup from media.io */
export type SignUpResult = {
  __typename?: 'SignUpResult';
  token: Scalars['String'];
  user: User;
};

/** Return type of StopSubscriptionPlanImmediate */
export type StopSubscriptionPlanImmediatePayload = {
  __typename?: 'StopSubscriptionPlanImmediatePayload';
  result: Scalars['Boolean'];
};

/** Return type of StopSubscriptionPlan */
export type StopSubscriptionPlanPayload = {
  __typename?: 'StopSubscriptionPlanPayload';
  result: Scalars['Boolean'];
};

/** Parameters for initializing stripe.js */
export type StripeParamter = {
  __typename?: 'StripeParamter';
  stripeJsKey: Scalars['String'];
  clientSecret: Scalars['String'];
};

export enum SubscriptionCurrency {
  USD = 'USD'
}

export enum SubscriptionIntervalUnit {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

/** Available subscription plan */
export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  /** Subscription plan code created from admin page */
  code: Scalars['String'];
  name: Scalars['String'];
  /** Amount in Cents */
  amount: Scalars['Int'];
  currency: SubscriptionCurrency;
  intervalUnit: SubscriptionIntervalUnit;
  intervalLength: Scalars['Int'];
};

/** Details of subscription plan */
export type SubscriptionSpecification = {
  __typename?: 'SubscriptionSpecification';
  /** The date of the first full invoice */
  billingCycleAnchor: Maybe<Scalars['Date']>;
  /** Date which will be canceled */
  cancelAt: Maybe<Scalars['Date']>;
  /** Date user requested the cancellation */
  canceledAt: Maybe<Scalars['Date']>;
  /** Make this to be a last period */
  cancelAtPeriodEnd: Scalars['Boolean'];
  /** Created datetime on stripe */
  created: Scalars['Date'];
  currentPeriodEnd: Scalars['Date'];
  currentPeriodStart: Scalars['Date'];
  /** Subscription started date */
  startDate: Scalars['Date'];
  /** Subscription ended date */
  endedAt: Maybe<Scalars['Date']>;
  planCode: Scalars['String'];
  status: SubscriptionStatus;
  trialEnd: Maybe<Scalars['Date']>;
  trialStart: Maybe<Scalars['Date']>;
  uuid: Scalars['String'];
};

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  TRIALING = 'TRIALING',
  CANCELED = 'CANCELED',
  PAST_DUE = 'PAST_DUE',
  UNPAID = 'UNPAID',
  INCOMPLETE = 'INCOMPLETE',
  INCOMPLETE_EXPIRED = 'INCOMPLETE_EXPIRED'
}

export enum SubscriptionType {
  FREE = 'FREE',
  PLUS = 'PLUS',
  PREMIUM = 'PREMIUM',
  PPV = 'PPV'
}

/** Terms-of-use article from freshdesk */
export type TermsOfUse = {
  __typename?: 'TermsOfUse';
  /** A content of terms-of-use */
  description: Scalars['String'];
};

/** Input type of UpdateProfile */
export type UpdateProfileInput = {
  /** An username */
  name: Scalars['String'];
  /** An user's gender */
  gender: Gender;
  /** An user's birthday */
  birthday: Scalars['Date'];
  /** A preferred language of user */
  language: Language;
  /** Identifies whether an user agree to receive updates/news from ODC or not */
  isReceiveMail: Scalars['Boolean'];
};

/** Return type of UpdateProfile */
export type UpdateProfilePayload = {
  __typename?: 'UpdateProfilePayload';
  /** Updated user profile */
  item: User;
  result: Scalars['Boolean'];
};

/** Composite User's Profile, WatchHistory and Favorites information */
export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  /** An username */
  username: Scalars['String'];
  /** An user's email */
  email: Scalars['String'];
  /** User Profile Information */
  info: UserInfo;
  /** A list of watch histories */
  watchHistories: Array<WatchHistory>;
  /** A favorite connection */
  favorites: FavoriteConnection;
  /** A list of payment method of current user */
  paymentMethods: Array<PaymentMethod>;
  /** A list of subscription history of current user */
  subscriptionHistories: Array<SubscriptionSpecification>;
  /** A list of pending schedules of current user */
  pendingSchedules: Array<Schedule>;
};


/** Composite User's Profile, WatchHistory and Favorites information */
export type UserFavoritesArgs = {
  page: Maybe<Scalars['Int']>;
};

/** User Profile Information /user/profile */
export type UserInfo = {
  __typename?: 'UserInfo';
  /** An username */
  name: Maybe<Scalars['String']>;
  /** An user's gender */
  gender: Maybe<Scalars['String']>;
  /** An user's birthday */
  birthday: Maybe<Scalars['Date']>;
  /** A preferred language of user */
  language: Maybe<Scalars['String']>;
  /** Identifies whether an user agree to receive updates/news from ODC or not */
  isReceiveMail: Maybe<Scalars['Boolean']>;
};

/** A detailed episode information watched by user previously */
export type WatchHistory = {
  __typename?: 'WatchHistory';
  /** The user id */
  userId: Scalars['Int'];
  /** The program id of watched episode */
  programId: Scalars['Int'];
  /** The episode id of watched episode */
  episodeId: Scalars['Int'];
  /** A users' session key */
  sessionKey: Maybe<Scalars['String']>;
  /** A total play time of episode */
  totalPlayTime: Scalars['Float'];
  /** A latest played position */
  lastPosition: Scalars['Float'];
  /** An episode duration */
  duration: Scalars['Float'];
  /** A ratio of total video playback time (latestPosition / duration) */
  progressRatio: Maybe<Scalars['Float']>;
  /** Identifies whether an episode is played or not */
  watched: Scalars['Boolean'];
  /** A latest datetime that a history was recorded */
  updatedAt: Scalars['Date'];
  /** An detailed episode information */
  episode: Episode;
};

export type CoverQueryVariables = Exact<{ [key: string]: never; }>;


export type CoverQuery = (
  { __typename?: 'Query' }
  & { cover: (
    { __typename?: 'CoverCollection' }
    & Pick<CoverCollection, 'sortBy'>
    & { nodes: Array<(
      { __typename?: 'Cover' }
      & CoverItemFragment
    )> }
  ) }
);

export type CoverItemFragment = (
  { __typename?: 'Cover' }
  & Pick<Cover, 'url' | 'titleEn' | 'titleZhHans' | 'titleZhHant' | 'callToActionEn' | 'callToActionZhHans' | 'callToActionZhHant' | 'descriptionEn' | 'descriptionZhHans' | 'descriptionZhHant' | 'thumbnailImage' | 'coverImage'>
);

export type CurationQueryVariables = Exact<{
  type: CurationType;
}>;


export type CurationQuery = (
  { __typename?: 'Query' }
  & { curation: (
    { __typename?: 'CurationCollection' }
    & Pick<CurationCollection, 'id'>
    & { nodes: Array<(
      { __typename?: 'Curation' }
      & CurationItemFragment
    )> }
  ) }
);

export type CurationItemFragment = (
  { __typename?: 'Curation' }
  & Pick<Curation, 'slug' | 'programDirectPlay' | 'ratio' | 'titleUrl' | 'titleEn' | 'titleZhHans' | 'titleZhHant' | 'logicOrdering' | 'type'>
  & { carousels: Array<(
    { __typename?: 'Program' }
    & CurationCarouselContentsProgramFragment
  ) | (
    { __typename?: 'Episode' }
    & CurationCarouselContentsEpisodeFragment
  ) | (
    { __typename?: 'CarouselWatchHistory' }
    & CurationCarouselContentsCarouselWatchHistoryFragment
  )> }
);

export type CurationCarouselContentsProgramFragment = (
  { __typename?: 'Program' }
  & Pick<Program, 'slug' | 'uuid' | 'titleEn' | 'titleZhHans' | 'titleZhHant' | 'produceYear' | 'releaseStatus'>
  & { images: Maybe<(
    { __typename?: 'Images' }
    & Pick<Images, 'poster' | 'posterEn' | 'posterZhHans' | 'posterZhHant'>
  )>, latestEpisode: Maybe<(
    { __typename?: 'Episode' }
    & Pick<Episode, 'titleEn' | 'titleZhHans' | 'titleZhHant'>
  )>, meta: Maybe<(
    { __typename?: 'Meta' }
    & Pick<Meta, 'produceCountries' | 'episodeTotal' | 'isSeries' | 'ccLanguages'>
  )> }
);

export type CurationCarouselContentsEpisodeFragment = (
  { __typename?: 'Episode' }
  & Pick<Episode, 'uuid' | 'titleEn' | 'titleZhHans' | 'titleZhHant' | 'kind' | 'number' | 'part' | 'videoCcLanguages' | 'videoDuration'>
  & { program: (
    { __typename?: 'Program' }
    & Pick<Program, 'slug' | 'titleEn' | 'titleZhHans' | 'titleZhHant'>
  ), images: Maybe<(
    { __typename?: 'Images' }
    & Pick<Images, 'thumbnail'>
  )> }
);

export type CurationCarouselContentsCarouselWatchHistoryFragment = (
  { __typename?: 'CarouselWatchHistory' }
  & Pick<CarouselWatchHistory, 'progressRatio' | 'uuid' | 'titleEn' | 'titleZhHans' | 'titleZhHant' | 'kind' | 'number' | 'part' | 'videoCcLanguages' | 'videoDuration'>
  & { program: (
    { __typename?: 'Program' }
    & Pick<Program, 'slug' | 'titleEn' | 'titleZhHans' | 'titleZhHant'>
  ), images: Maybe<(
    { __typename?: 'Images' }
    & Pick<Images, 'thumbnail'>
  )> }
);

export type CurationCarouselContentsFragment = CurationCarouselContentsProgramFragment | CurationCarouselContentsEpisodeFragment | CurationCarouselContentsCarouselWatchHistoryFragment;

export type DirectPlayQueryVariables = Exact<{
  programSlug: Scalars['String'];
}>;


export type DirectPlayQuery = (
  { __typename?: 'Query' }
  & { directPlay: (
    { __typename?: 'DirectPlay' }
    & Pick<DirectPlay, 'link'>
  ) }
);

export type EpisodeQueryVariables = Exact<{
  programSlug: Scalars['String'];
  episodeNumber: Scalars['Int'];
  kind: Scalars['String'];
  part: Maybe<Scalars['Int']>;
}>;


export type EpisodeQuery = (
  { __typename?: 'Query' }
  & { episode: (
    { __typename?: 'Episode' }
    & EpisodeDetailFragment
  ) }
);

export type NextEpisodeDetailFragment = (
  { __typename?: 'Episode' }
  & Pick<Episode, 'link' | 'kind' | 'part' | 'episodeNumber' | 'title' | 'titleEn' | 'titleKo' | 'titleZhHans' | 'titleZhHant'>
  & { program: (
    { __typename?: 'Program' }
    & Pick<Program, 'slug'>
  ), images: Maybe<(
    { __typename?: 'Images' }
    & Pick<Images, 'thumbnail'>
  )> }
);

export type EpisodeDetailFragment = (
  { __typename?: 'Episode' }
  & Pick<Episode, 'id' | 'episodeNumber' | 'part' | 'kind' | 'link' | 'title' | 'titleEn' | 'titleKo' | 'titleZhHans' | 'titleZhHant' | 'synopsis' | 'synopsisEn' | 'synopsisKo' | 'synopsisZhHans' | 'synopsisZhHant' | 'seoSynopsis' | 'seoSynopsisEn' | 'seoSynopsisKo' | 'seoSynopsisZhHans' | 'seoSynopsisZhHant' | 'seoKeyword' | 'seoKeywordEn' | 'seoKeywordKo' | 'seoKeywordZhHans' | 'seoKeywordZhHant' | 'seoTitle' | 'seoTitleEn' | 'seoTitleKo' | 'seoTitleZhHans' | 'seoTitleZhHant' | 'videoCcLanguages' | 'videoDuration' | 'releaseDate'>
  & { nextEpisode: Maybe<(
    { __typename?: 'Episode' }
    & NextEpisodeDetailFragment
  )>, people: Array<(
    { __typename?: 'People' }
    & PeopleItemFragment
  )>, images: Maybe<(
    { __typename?: 'Images' }
    & Pick<Images, 'thumbnail'>
  )>, ageRating: Maybe<(
    { __typename?: 'AgeRating' }
    & Pick<AgeRating, 'isBlock'>
  )> }
);

export type EpisodeListQueryVariables = Exact<{
  programSlug: Scalars['String'];
  ordering: EpisodesOrderTypes;
  page: Maybe<Scalars['Int']>;
}>;


export type EpisodeListQuery = (
  { __typename?: 'Query' }
  & { episodes: (
    { __typename?: 'EpisodeConnection' }
    & Pick<EpisodeConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'nextPage'>
    ), nodes: Array<(
      { __typename?: 'Episode' }
      & EpisodeListItemFragment
    )> }
  ) }
);

export type EpisodeListItemFragment = (
  { __typename?: 'Episode' }
  & Pick<Episode, 'id' | 'link' | 'titleEn' | 'titleZhHans' | 'titleZhHant' | 'synopsisEn' | 'synopsisZhHans' | 'synopsisZhHant' | 'videoDuration' | 'videoCcLanguages'>
  & { images: Maybe<(
    { __typename?: 'Images' }
    & Pick<Images, 'thumbnail'>
  )> }
);

export type FaqQueryVariables = Exact<{
  locale: Maybe<Language>;
}>;


export type FaqQuery = (
  { __typename?: 'Query' }
  & { faq: Array<(
    { __typename?: 'FAQ' }
    & Pick<Faq, 'id' | 'name'>
    & { articles: Array<(
      { __typename?: 'FAQArticle' }
      & Pick<FaqArticle, 'id' | 'title' | 'description'>
    )> }
  )> }
);

export type FavoriteProgramQueryVariables = Exact<{
  programSlug: Scalars['String'];
}>;


export type FavoriteProgramQuery = (
  { __typename?: 'Query' }
  & { favoriteProgram: (
    { __typename?: 'FavoriteProgramResult' }
    & Pick<FavoriteProgramResult, 'result'>
  ) }
);

export type AddFavoriteMutationVariables = Exact<{
  input: AddFavoriteInput;
}>;


export type AddFavoriteMutation = (
  { __typename?: 'Mutation' }
  & { addFavorite: (
    { __typename?: 'AddFavoritePayload' }
    & Pick<AddFavoritePayload, 'result'>
    & { item: (
      { __typename?: 'Program' }
      & FavoriteItemProgramFragment
    ) }
  ) }
);

export type RemoveFavoriteMutationVariables = Exact<{
  input: RemoveFavoriteInput;
}>;


export type RemoveFavoriteMutation = (
  { __typename?: 'Mutation' }
  & { removeFavorite: (
    { __typename?: 'RemoveFavoritePayload' }
    & Pick<RemoveFavoritePayload, 'result'>
  ) }
);

export type FavoritesQueryVariables = Exact<{
  page: Scalars['Int'];
}>;


export type FavoritesQuery = (
  { __typename?: 'Query' }
  & { favorites: (
    { __typename?: 'FavoriteConnection' }
    & Pick<FavoriteConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'nextPage'>
    ), nodes: Array<(
      { __typename?: 'Program' }
      & FavoriteItemProgramFragment
    )> }
  ) }
);

export type FavoriteItemProgramFragment = (
  { __typename?: 'Program' }
  & Pick<Program, 'id' | 'uuid' | 'slug' | 'titleEn' | 'titleZhHans' | 'titleZhHant' | 'releaseStatus' | 'publishStatus' | 'produceYear'>
  & { images: Maybe<(
    { __typename?: 'Images' }
    & Pick<Images, 'posterEn' | 'posterZhHans' | 'posterZhHant'>
  )>, meta: Maybe<(
    { __typename?: 'Meta' }
    & Pick<Meta, 'isSeries' | 'episodeTotal' | 'produceCountries' | 'ccLanguages'>
  )>, latestEpisode: Maybe<(
    { __typename?: 'Episode' }
    & Pick<Episode, 'titleEn' | 'titleZhHans' | 'titleZhHant'>
  )> }
);

export type RemoveAllFavoriteMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveAllFavoriteMutation = (
  { __typename?: 'Mutation' }
  & { removeAllFavorites: (
    { __typename?: 'RemoveAllFavorites' }
    & Pick<RemoveAllFavorites, 'result'>
  ) }
);

export type NavigationQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type NavigationQuery = (
  { __typename?: 'Query' }
  & { navigation: (
    { __typename?: 'NavigationCollection' }
    & Pick<NavigationCollection, 'id' | 'slug'>
    & { nodes: Array<(
      { __typename?: 'Navigation' }
      & CategoryItemFragment
    )> }
  ) }
);

export type CategoryItemFragment = (
  { __typename?: 'Navigation' }
  & Pick<Navigation, 'slug' | 'ratio' | 'titleEn' | 'titleZhHans' | 'titleZhHant' | 'seoTitleEn' | 'seoTitleZhHans' | 'seoTitleZhHant' | 'seoDescriptionEn' | 'seoDescriptionZhHans' | 'seoDescriptionZhHant' | 'seoKeywordsEn' | 'seoKeywordsZhHans' | 'seoKeywordsZhHant' | 'parents'>
  & { genres: Array<(
    { __typename?: 'Navigation' }
    & GenreFilterItemFragment
  )> }
);

export type GenreFilterItemFragment = (
  { __typename?: 'Navigation' }
  & Pick<Navigation, 'slug' | 'titleEn' | 'titleZhHans' | 'titleZhHant'>
);

export type ProgramQueryVariables = Exact<{
  programSlug: Scalars['String'];
}>;


export type ProgramQuery = (
  { __typename?: 'Query' }
  & { program: (
    { __typename?: 'Program' }
    & ProgramDetailFragment
  ) }
);

export type ProgramDetailFragment = (
  { __typename?: 'Program' }
  & Pick<Program, 'id' | 'slug' | 'titleEn' | 'titleKo' | 'titleZhHans' | 'titleZhHant' | 'seoTitleEn' | 'seoTitleKo' | 'seoTitleZhHans' | 'seoTitleZhHant' | 'seoSynopsisEn' | 'seoSynopsisKo' | 'seoSynopsisZhHant' | 'seoSynopsisZhHans' | 'seoKeywordEn' | 'seoKeywordKo' | 'seoKeywordZhHans' | 'seoKeywordZhHant' | 'synopsisEn' | 'synopsisKo' | 'synopsisZhHans' | 'synopsisZhHant' | 'secondaryTitleEn' | 'secondaryTitleKo' | 'secondaryTitleZhHans' | 'secondaryTitleZhHant' | 'releaseStatus' | 'releaseStart' | 'produceYear'>
  & { contentProvider: Maybe<(
    { __typename?: 'ContentProvider' }
    & Pick<ContentProvider, 'code' | 'name'>
  )>, genres: Array<(
    { __typename?: 'Category' }
    & GenreItemFragment
  )>, people: Array<(
    { __typename?: 'People' }
    & PeopleItemFragment
  )>, categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'slug' | 'titleEn' | 'titleZhHans' | 'titleZhHant'>
  )>, meta: Maybe<(
    { __typename?: 'Meta' }
    & Pick<Meta, 'episodeTotal' | 'episodeOrdering' | 'isSeries' | 'score' | 'cp' | 'produceCountries'>
  )>, images: Maybe<(
    { __typename?: 'Images' }
    & Pick<Images, 'poster' | 'posterEn' | 'posterKo' | 'posterZhHans' | 'posterZhHant' | 'thumbnail'>
  )> }
);

export type GenreItemFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'slug' | 'titleEn' | 'titleZhHans' | 'titleZhHant'>
);

export type PeopleItemFragment = (
  { __typename?: 'People' }
  & Pick<People, 'role' | 'slug' | 'nameEn' | 'nameZhHans' | 'nameZhHant'>
);

export type ProgramListQueryVariables = Exact<{
  categorySlug: Scalars['String'];
  releaseStatus: Maybe<Scalars['String']>;
  genreSlug: Maybe<Scalars['String']>;
  sortFilter: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
}>;


export type ProgramListQuery = (
  { __typename?: 'Query' }
  & { programs: (
    { __typename?: 'ProgramConnection' }
    & Pick<ProgramConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Program' }
      & ProgramItemFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'nextPage'>
    ) }
  ) }
);

export type ProgramItemFragment = (
  { __typename?: 'Program' }
  & Pick<Program, 'id' | 'slug' | 'titleEn' | 'titleKo' | 'titleZhHans' | 'titleZhHant' | 'produceYear' | 'releaseStatus'>
  & { categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'slug'>
  )>, images: Maybe<(
    { __typename?: 'Images' }
    & Pick<Images, 'poster' | 'posterEn' | 'posterKo' | 'posterZhHans' | 'posterZhHant'>
  )>, meta: Maybe<(
    { __typename?: 'Meta' }
    & Pick<Meta, 'isSeries' | 'produceCountries' | 'episodeTotal' | 'ccLanguages'>
  )>, latestEpisode: Maybe<(
    { __typename?: 'Episode' }
    & Pick<Episode, 'title' | 'titleEn' | 'titleKo' | 'titleZhHans' | 'titleZhHant'>
  )> }
);

export type SearchQueryVariables = Exact<{
  keyword: Maybe<Scalars['String']>;
  category: Maybe<Scalars['String']>;
  genre: Maybe<Scalars['String']>;
  person: Maybe<Scalars['String']>;
  produceCountry: Maybe<Scalars['String']>;
  produceYear: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
}>;


export type SearchQuery = (
  { __typename?: 'Query' }
  & { search: (
    { __typename?: 'ProgramConnection' }
    & Pick<ProgramConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'nextPage' | 'hasNextPage'>
    ), nodes: Array<(
      { __typename?: 'Program' }
      & SearchItemFragment
    )> }
  ) }
);

export type SearchItemFragment = (
  { __typename?: 'Program' }
  & Pick<Program, 'id' | 'uuid' | 'slug' | 'titleEn' | 'titleZhHans' | 'titleZhHant' | 'secondaryTitleEn' | 'secondaryTitleZhHans' | 'secondaryTitleZhHant' | 'synopsisEn' | 'synopsisZhHans' | 'synopsisZhHant' | 'produceYear' | 'releaseStatus'>
  & { images: Maybe<(
    { __typename?: 'Images' }
    & Pick<Images, 'poster' | 'posterEn' | 'posterZhHans' | 'posterZhHant'>
  )>, meta: Maybe<(
    { __typename?: 'Meta' }
    & Pick<Meta, 'episodeTotal' | 'isSeries' | 'ccLanguages' | 'produceCountries'>
  )>, categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'slug' | 'titleEn' | 'titleZhHans' | 'titleZhHant'>
  )>, genres: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'slug' | 'titleEn' | 'titleZhHans' | 'titleZhHant'>
  )>, people: Array<(
    { __typename?: 'People' }
    & PeopleItemFragment
  )>, latestEpisode: Maybe<(
    { __typename?: 'Episode' }
    & Pick<Episode, 'title' | 'titleEn' | 'titleZhHans' | 'titleZhHant'>
  )> }
);

export type SigninMutationVariables = Exact<{
  input: SignInInput;
}>;


export type SigninMutation = (
  { __typename?: 'Mutation' }
  & { signin: (
    { __typename?: 'SignInPayload' }
    & Pick<SignInPayload, 'result'>
    & { item: (
      { __typename?: 'SignInResult' }
      & Pick<SignInResult, 'token'>
      & { user: (
        { __typename?: 'User' }
        & UserDataFragment
      ) }
    ) }
  ) }
);

export type SignupMutationVariables = Exact<{
  input: SignUpInput;
}>;


export type SignupMutation = (
  { __typename?: 'Mutation' }
  & { signup: (
    { __typename?: 'SignUpPayload' }
    & Pick<SignUpPayload, 'result'>
    & { item: (
      { __typename?: 'SignUpResult' }
      & Pick<SignUpResult, 'token'>
      & { user: (
        { __typename?: 'User' }
        & UserDataFragment
      ) }
    ) }
  ) }
);

export type VerifyEmailMutationVariables = Exact<{
  input: EmailVerificationInput;
}>;


export type VerifyEmailMutation = (
  { __typename?: 'Mutation' }
  & { verifyEmail: (
    { __typename?: 'EmailVerificationPayload' }
    & Pick<EmailVerificationPayload, 'result'>
  ) }
);

export type VerifyCodeMutationVariables = Exact<{
  input: CodeVerificationInput;
}>;


export type VerifyCodeMutation = (
  { __typename?: 'Mutation' }
  & { verifyCode: (
    { __typename?: 'CodeVerificationPayload' }
    & Pick<CodeVerificationPayload, 'result'>
  ) }
);

export type TermsOfUseQueryVariables = Exact<{ [key: string]: never; }>;


export type TermsOfUseQuery = (
  { __typename?: 'Query' }
  & { termsOfUse: (
    { __typename?: 'TermsOfUse' }
    & Pick<TermsOfUse, 'description'>
  ) }
);

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & UserDataFragment
  ) }
);

export type UserDataFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'username' | 'email'>
  & { info: (
    { __typename?: 'UserInfo' }
    & UserInfoFragment
  ) }
);

export type UserInfoFragment = (
  { __typename?: 'UserInfo' }
  & Pick<UserInfo, 'name' | 'gender' | 'birthday' | 'language' | 'isReceiveMail'>
);

export type WatchHistoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type WatchHistoriesQuery = (
  { __typename?: 'Query' }
  & { watchHistories: Array<(
    { __typename?: 'WatchHistory' }
    & WatchHistoryItemWatchHistoryFragment
  )> }
);

export type WatchHistoryItemWatchHistoryFragment = (
  { __typename?: 'WatchHistory' }
  & Pick<WatchHistory, 'userId' | 'episodeId' | 'progressRatio'>
  & { episode: (
    { __typename?: 'Episode' }
    & Pick<Episode, 'titleEn' | 'titleZhHans' | 'titleZhHant' | 'number' | 'videoCcLanguages' | 'kind' | 'videoDuration'>
    & { program: (
      { __typename?: 'Program' }
      & Pick<Program, 'slug' | 'titleEn' | 'titleZhHans' | 'titleZhHant'>
    ), images: Maybe<(
      { __typename?: 'Images' }
      & Pick<Images, 'thumbnail'>
    )> }
  ) }
);

export const CoverItemFragmentDoc = gql`
    fragment CoverItem on Cover {
  url
  titleEn
  titleZhHans
  titleZhHant
  callToActionEn
  callToActionZhHans
  callToActionZhHant
  descriptionEn
  descriptionZhHans
  descriptionZhHant
  thumbnailImage(width: 260, height: 147, clippingType: CENTER)
  coverImage(width: 1520, height: 536, clippingType: SMART)
}
    `;
export const CurationCarouselContentsFragmentDoc = gql`
    fragment CurationCarouselContents on CarouselItem {
  ... on Program {
    slug
    uuid
    titleEn
    titleZhHans
    titleZhHant
    images {
      poster
      posterEn
      posterZhHans
      posterZhHant
    }
    produceYear
    releaseStatus
    latestEpisode {
      titleEn
      titleZhHans
      titleZhHant
    }
    meta {
      produceCountries
      episodeTotal
      isSeries
      ccLanguages
    }
  }
  ... on Episode {
    uuid
    titleEn
    titleZhHans
    titleZhHant
    program {
      slug
      titleEn
      titleZhHans
      titleZhHant
    }
    images {
      thumbnail
    }
    kind
    number
    part
    videoCcLanguages
    videoDuration
  }
  ... on CarouselWatchHistory {
    progressRatio
    uuid
    program {
      slug
      titleEn
      titleZhHans
      titleZhHant
    }
    images {
      thumbnail
    }
    titleEn
    titleZhHans
    titleZhHant
    kind
    number
    part
    videoCcLanguages
    videoDuration
  }
}
    `;
export const CurationItemFragmentDoc = gql`
    fragment CurationItem on Curation {
  slug
  programDirectPlay
  ratio
  titleUrl
  titleEn
  titleZhHans
  titleZhHant
  logicOrdering
  type
  carousels {
    ...CurationCarouselContents
  }
}
    ${CurationCarouselContentsFragmentDoc}`;
export const NextEpisodeDetailFragmentDoc = gql`
    fragment NextEpisodeDetail on Episode {
  program {
    slug
  }
  link
  kind
  part
  episodeNumber
  images {
    thumbnail
  }
  title
  titleEn
  titleKo
  titleZhHans
  titleZhHant
}
    `;
export const PeopleItemFragmentDoc = gql`
    fragment PeopleItem on People {
  role
  slug
  nameEn
  nameZhHans
  nameZhHant
}
    `;
export const EpisodeDetailFragmentDoc = gql`
    fragment EpisodeDetail on Episode {
  id
  episodeNumber
  part
  kind
  link
  nextEpisode {
    ...NextEpisodeDetail
  }
  title
  titleEn
  titleKo
  titleZhHans
  titleZhHant
  synopsis
  synopsisEn
  synopsisKo
  synopsisZhHans
  synopsisZhHant
  seoSynopsis
  seoSynopsisEn
  seoSynopsisKo
  seoSynopsisZhHans
  seoSynopsisZhHant
  seoKeyword
  seoKeywordEn
  seoKeywordKo
  seoKeywordZhHans
  seoKeywordZhHant
  seoTitle
  seoTitleEn
  seoTitleKo
  seoTitleZhHans
  seoTitleZhHant
  videoCcLanguages
  videoDuration
  releaseDate
  people {
    ...PeopleItem
  }
  images {
    thumbnail
  }
  ageRating {
    isBlock
  }
}
    ${NextEpisodeDetailFragmentDoc}
${PeopleItemFragmentDoc}`;
export const EpisodeListItemFragmentDoc = gql`
    fragment EpisodeListItem on Episode {
  id
  link
  titleEn
  titleZhHans
  titleZhHant
  synopsisEn
  synopsisZhHans
  synopsisZhHant
  images {
    thumbnail
  }
  videoDuration
  videoCcLanguages
}
    `;
export const FavoriteItemProgramFragmentDoc = gql`
    fragment FavoriteItemProgram on Program {
  id
  uuid
  slug
  titleEn
  titleZhHans
  titleZhHant
  images {
    posterEn
    posterZhHans
    posterZhHant
  }
  releaseStatus
  publishStatus
  meta {
    isSeries
    episodeTotal
    produceCountries
    ccLanguages
  }
  produceYear
  latestEpisode {
    titleEn
    titleZhHans
    titleZhHant
  }
}
    `;
export const GenreFilterItemFragmentDoc = gql`
    fragment GenreFilterItem on Navigation {
  slug
  titleEn
  titleZhHans
  titleZhHant
}
    `;
export const CategoryItemFragmentDoc = gql`
    fragment CategoryItem on Navigation {
  slug
  ratio
  titleEn
  titleZhHans
  titleZhHant
  seoTitleEn
  seoTitleZhHans
  seoTitleZhHant
  seoDescriptionEn
  seoDescriptionZhHans
  seoDescriptionZhHant
  seoKeywordsEn
  seoKeywordsZhHans
  seoKeywordsZhHant
  genres {
    ...GenreFilterItem
  }
  parents
}
    ${GenreFilterItemFragmentDoc}`;
export const GenreItemFragmentDoc = gql`
    fragment GenreItem on Category {
  id
  slug
  titleEn
  titleZhHans
  titleZhHant
}
    `;
export const ProgramDetailFragmentDoc = gql`
    fragment ProgramDetail on Program {
  id
  slug
  titleEn
  titleKo
  titleZhHans
  titleZhHant
  seoTitleEn
  seoTitleKo
  seoTitleZhHans
  seoTitleZhHant
  seoSynopsisEn
  seoSynopsisKo
  seoSynopsisZhHant
  seoSynopsisZhHans
  seoKeywordEn
  seoKeywordKo
  seoKeywordZhHans
  seoKeywordZhHant
  synopsisEn
  synopsisKo
  synopsisZhHans
  synopsisZhHant
  secondaryTitleEn
  secondaryTitleKo
  secondaryTitleZhHans
  secondaryTitleZhHant
  releaseStatus
  releaseStart
  produceYear
  contentProvider {
    code
    name
  }
  genres {
    ...GenreItem
  }
  people {
    ...PeopleItem
  }
  categories {
    slug
    titleEn
    titleZhHans
    titleZhHant
  }
  meta {
    episodeTotal
    episodeOrdering
    isSeries
    score
    cp
    produceCountries
  }
  images {
    poster
    posterEn
    posterKo
    posterZhHans
    posterZhHant
    thumbnail
  }
}
    ${GenreItemFragmentDoc}
${PeopleItemFragmentDoc}`;
export const ProgramItemFragmentDoc = gql`
    fragment ProgramItem on Program {
  id
  slug
  titleEn
  titleKo
  titleZhHans
  titleZhHant
  categories {
    slug
  }
  images {
    poster
    posterEn
    posterKo
    posterZhHans
    posterZhHant
  }
  meta {
    isSeries
    produceCountries
    episodeTotal
    ccLanguages
  }
  produceYear
  latestEpisode {
    title
    titleEn
    titleKo
    titleZhHans
    titleZhHant
  }
  releaseStatus
}
    `;
export const SearchItemFragmentDoc = gql`
    fragment SearchItem on Program {
  id
  uuid
  slug
  titleEn
  titleZhHans
  titleZhHant
  secondaryTitleEn
  secondaryTitleZhHans
  secondaryTitleZhHant
  images {
    poster
    posterEn
    posterZhHans
    posterZhHant
  }
  synopsisEn
  synopsisZhHans
  synopsisZhHant
  produceYear
  releaseStatus
  meta {
    episodeTotal
    isSeries
    ccLanguages
    produceCountries
  }
  categories {
    id
    slug
    titleEn
    titleZhHans
    titleZhHant
  }
  genres {
    id
    slug
    titleEn
    titleZhHans
    titleZhHant
  }
  people {
    ...PeopleItem
  }
  latestEpisode {
    title
    titleEn
    titleZhHans
    titleZhHant
  }
}
    ${PeopleItemFragmentDoc}`;
export const UserInfoFragmentDoc = gql`
    fragment UserInfo on UserInfo {
  name
  gender
  birthday
  language
  isReceiveMail
}
    `;
export const UserDataFragmentDoc = gql`
    fragment UserData on User {
  id
  username
  email
  info {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;
export const WatchHistoryItemWatchHistoryFragmentDoc = gql`
    fragment WatchHistoryItemWatchHistory on WatchHistory {
  userId
  episodeId
  progressRatio
  episode {
    titleEn
    titleZhHans
    titleZhHant
    program {
      slug
      titleEn
      titleZhHans
      titleZhHant
    }
    images {
      thumbnail
    }
    number
    videoCcLanguages
    kind
    videoDuration
  }
}
    `;
export const CoverDocument = gql`
    query Cover {
  cover {
    sortBy
    nodes {
      ...CoverItem
    }
  }
}
    ${CoverItemFragmentDoc}`;

/**
 * __useCoverQuery__
 *
 * To run a query within a React component, call `useCoverQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoverQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoverQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoverQuery, CoverQueryVariables>) {
        return ApolloReactHooks.useQuery<CoverQuery, CoverQueryVariables>(CoverDocument, baseOptions);
      }
export function useCoverLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoverQuery, CoverQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoverQuery, CoverQueryVariables>(CoverDocument, baseOptions);
        }
export type CoverQueryHookResult = ReturnType<typeof useCoverQuery>;
export type CoverLazyQueryHookResult = ReturnType<typeof useCoverLazyQuery>;
export type CoverQueryResult = ApolloReactCommon.QueryResult<CoverQuery, CoverQueryVariables>;
export const CurationDocument = gql`
    query Curation($type: CurationType!) {
  curation(type: $type) {
    id
    nodes {
      ...CurationItem
    }
  }
}
    ${CurationItemFragmentDoc}`;

/**
 * __useCurationQuery__
 *
 * To run a query within a React component, call `useCurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurationQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCurationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurationQuery, CurationQueryVariables>) {
        return ApolloReactHooks.useQuery<CurationQuery, CurationQueryVariables>(CurationDocument, baseOptions);
      }
export function useCurationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurationQuery, CurationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurationQuery, CurationQueryVariables>(CurationDocument, baseOptions);
        }
export type CurationQueryHookResult = ReturnType<typeof useCurationQuery>;
export type CurationLazyQueryHookResult = ReturnType<typeof useCurationLazyQuery>;
export type CurationQueryResult = ApolloReactCommon.QueryResult<CurationQuery, CurationQueryVariables>;
export const DirectPlayDocument = gql`
    query DirectPlay($programSlug: String!) {
  directPlay(programSlug: $programSlug) {
    link
  }
}
    `;

/**
 * __useDirectPlayQuery__
 *
 * To run a query within a React component, call `useDirectPlayQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectPlayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectPlayQuery({
 *   variables: {
 *      programSlug: // value for 'programSlug'
 *   },
 * });
 */
export function useDirectPlayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DirectPlayQuery, DirectPlayQueryVariables>) {
        return ApolloReactHooks.useQuery<DirectPlayQuery, DirectPlayQueryVariables>(DirectPlayDocument, baseOptions);
      }
export function useDirectPlayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DirectPlayQuery, DirectPlayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DirectPlayQuery, DirectPlayQueryVariables>(DirectPlayDocument, baseOptions);
        }
export type DirectPlayQueryHookResult = ReturnType<typeof useDirectPlayQuery>;
export type DirectPlayLazyQueryHookResult = ReturnType<typeof useDirectPlayLazyQuery>;
export type DirectPlayQueryResult = ApolloReactCommon.QueryResult<DirectPlayQuery, DirectPlayQueryVariables>;
export const EpisodeDocument = gql`
    query Episode($programSlug: String!, $episodeNumber: Int!, $kind: String!, $part: Int) {
  episode(
    programSlug: $programSlug
    episodeNumber: $episodeNumber
    kind: $kind
    part: $part
  ) {
    ...EpisodeDetail
  }
}
    ${EpisodeDetailFragmentDoc}`;

/**
 * __useEpisodeQuery__
 *
 * To run a query within a React component, call `useEpisodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEpisodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEpisodeQuery({
 *   variables: {
 *      programSlug: // value for 'programSlug'
 *      episodeNumber: // value for 'episodeNumber'
 *      kind: // value for 'kind'
 *      part: // value for 'part'
 *   },
 * });
 */
export function useEpisodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EpisodeQuery, EpisodeQueryVariables>) {
        return ApolloReactHooks.useQuery<EpisodeQuery, EpisodeQueryVariables>(EpisodeDocument, baseOptions);
      }
export function useEpisodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EpisodeQuery, EpisodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EpisodeQuery, EpisodeQueryVariables>(EpisodeDocument, baseOptions);
        }
export type EpisodeQueryHookResult = ReturnType<typeof useEpisodeQuery>;
export type EpisodeLazyQueryHookResult = ReturnType<typeof useEpisodeLazyQuery>;
export type EpisodeQueryResult = ApolloReactCommon.QueryResult<EpisodeQuery, EpisodeQueryVariables>;
export const EpisodeListDocument = gql`
    query EpisodeList($programSlug: String!, $ordering: EpisodesOrderTypes!, $page: Int) {
  episodes(programSlug: $programSlug, ordering: $ordering, page: $page) {
    totalCount
    pageInfo {
      hasNextPage
      nextPage
    }
    nodes {
      ...EpisodeListItem
    }
  }
}
    ${EpisodeListItemFragmentDoc}`;

/**
 * __useEpisodeListQuery__
 *
 * To run a query within a React component, call `useEpisodeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEpisodeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEpisodeListQuery({
 *   variables: {
 *      programSlug: // value for 'programSlug'
 *      ordering: // value for 'ordering'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useEpisodeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EpisodeListQuery, EpisodeListQueryVariables>) {
        return ApolloReactHooks.useQuery<EpisodeListQuery, EpisodeListQueryVariables>(EpisodeListDocument, baseOptions);
      }
export function useEpisodeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EpisodeListQuery, EpisodeListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EpisodeListQuery, EpisodeListQueryVariables>(EpisodeListDocument, baseOptions);
        }
export type EpisodeListQueryHookResult = ReturnType<typeof useEpisodeListQuery>;
export type EpisodeListLazyQueryHookResult = ReturnType<typeof useEpisodeListLazyQuery>;
export type EpisodeListQueryResult = ApolloReactCommon.QueryResult<EpisodeListQuery, EpisodeListQueryVariables>;
export const FaqDocument = gql`
    query FAQ($locale: Language) {
  faq(locale: $locale) {
    id
    name
    articles(locale: $locale) {
      id
      title
      description
    }
  }
}
    `;

/**
 * __useFaqQuery__
 *
 * To run a query within a React component, call `useFaqQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFaqQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FaqQuery, FaqQueryVariables>) {
        return ApolloReactHooks.useQuery<FaqQuery, FaqQueryVariables>(FaqDocument, baseOptions);
      }
export function useFaqLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FaqQuery, FaqQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FaqQuery, FaqQueryVariables>(FaqDocument, baseOptions);
        }
export type FaqQueryHookResult = ReturnType<typeof useFaqQuery>;
export type FaqLazyQueryHookResult = ReturnType<typeof useFaqLazyQuery>;
export type FaqQueryResult = ApolloReactCommon.QueryResult<FaqQuery, FaqQueryVariables>;
export const FavoriteProgramDocument = gql`
    query FavoriteProgram($programSlug: String!) {
  favoriteProgram(programSlug: $programSlug) {
    result
  }
}
    `;

/**
 * __useFavoriteProgramQuery__
 *
 * To run a query within a React component, call `useFavoriteProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavoriteProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavoriteProgramQuery({
 *   variables: {
 *      programSlug: // value for 'programSlug'
 *   },
 * });
 */
export function useFavoriteProgramQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FavoriteProgramQuery, FavoriteProgramQueryVariables>) {
        return ApolloReactHooks.useQuery<FavoriteProgramQuery, FavoriteProgramQueryVariables>(FavoriteProgramDocument, baseOptions);
      }
export function useFavoriteProgramLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FavoriteProgramQuery, FavoriteProgramQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FavoriteProgramQuery, FavoriteProgramQueryVariables>(FavoriteProgramDocument, baseOptions);
        }
export type FavoriteProgramQueryHookResult = ReturnType<typeof useFavoriteProgramQuery>;
export type FavoriteProgramLazyQueryHookResult = ReturnType<typeof useFavoriteProgramLazyQuery>;
export type FavoriteProgramQueryResult = ApolloReactCommon.QueryResult<FavoriteProgramQuery, FavoriteProgramQueryVariables>;
export const AddFavoriteDocument = gql`
    mutation AddFavorite($input: AddFavoriteInput!) {
  addFavorite(input: $input) {
    result
    item {
      ...FavoriteItemProgram
    }
  }
}
    ${FavoriteItemProgramFragmentDoc}`;
export type AddFavoriteMutationFn = ApolloReactCommon.MutationFunction<AddFavoriteMutation, AddFavoriteMutationVariables>;

/**
 * __useAddFavoriteMutation__
 *
 * To run a mutation, you first call `useAddFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFavoriteMutation, { data, loading, error }] = useAddFavoriteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFavoriteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddFavoriteMutation, AddFavoriteMutationVariables>) {
        return ApolloReactHooks.useMutation<AddFavoriteMutation, AddFavoriteMutationVariables>(AddFavoriteDocument, baseOptions);
      }
export type AddFavoriteMutationHookResult = ReturnType<typeof useAddFavoriteMutation>;
export type AddFavoriteMutationResult = ApolloReactCommon.MutationResult<AddFavoriteMutation>;
export type AddFavoriteMutationOptions = ApolloReactCommon.BaseMutationOptions<AddFavoriteMutation, AddFavoriteMutationVariables>;
export const RemoveFavoriteDocument = gql`
    mutation RemoveFavorite($input: RemoveFavoriteInput!) {
  removeFavorite(input: $input) {
    result
  }
}
    `;
export type RemoveFavoriteMutationFn = ApolloReactCommon.MutationFunction<RemoveFavoriteMutation, RemoveFavoriteMutationVariables>;

/**
 * __useRemoveFavoriteMutation__
 *
 * To run a mutation, you first call `useRemoveFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFavoriteMutation, { data, loading, error }] = useRemoveFavoriteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFavoriteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveFavoriteMutation, RemoveFavoriteMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveFavoriteMutation, RemoveFavoriteMutationVariables>(RemoveFavoriteDocument, baseOptions);
      }
export type RemoveFavoriteMutationHookResult = ReturnType<typeof useRemoveFavoriteMutation>;
export type RemoveFavoriteMutationResult = ApolloReactCommon.MutationResult<RemoveFavoriteMutation>;
export type RemoveFavoriteMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveFavoriteMutation, RemoveFavoriteMutationVariables>;
export const FavoritesDocument = gql`
    query Favorites($page: Int!) {
  favorites(page: $page) {
    totalCount
    pageInfo {
      hasNextPage
      nextPage
    }
    nodes {
      ...FavoriteItemProgram
    }
  }
}
    ${FavoriteItemProgramFragmentDoc}`;

/**
 * __useFavoritesQuery__
 *
 * To run a query within a React component, call `useFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavoritesQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFavoritesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FavoritesQuery, FavoritesQueryVariables>) {
        return ApolloReactHooks.useQuery<FavoritesQuery, FavoritesQueryVariables>(FavoritesDocument, baseOptions);
      }
export function useFavoritesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FavoritesQuery, FavoritesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FavoritesQuery, FavoritesQueryVariables>(FavoritesDocument, baseOptions);
        }
export type FavoritesQueryHookResult = ReturnType<typeof useFavoritesQuery>;
export type FavoritesLazyQueryHookResult = ReturnType<typeof useFavoritesLazyQuery>;
export type FavoritesQueryResult = ApolloReactCommon.QueryResult<FavoritesQuery, FavoritesQueryVariables>;
export const RemoveAllFavoriteDocument = gql`
    mutation RemoveAllFavorite {
  removeAllFavorites {
    result
  }
}
    `;
export type RemoveAllFavoriteMutationFn = ApolloReactCommon.MutationFunction<RemoveAllFavoriteMutation, RemoveAllFavoriteMutationVariables>;

/**
 * __useRemoveAllFavoriteMutation__
 *
 * To run a mutation, you first call `useRemoveAllFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAllFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAllFavoriteMutation, { data, loading, error }] = useRemoveAllFavoriteMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveAllFavoriteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAllFavoriteMutation, RemoveAllFavoriteMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAllFavoriteMutation, RemoveAllFavoriteMutationVariables>(RemoveAllFavoriteDocument, baseOptions);
      }
export type RemoveAllFavoriteMutationHookResult = ReturnType<typeof useRemoveAllFavoriteMutation>;
export type RemoveAllFavoriteMutationResult = ApolloReactCommon.MutationResult<RemoveAllFavoriteMutation>;
export type RemoveAllFavoriteMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAllFavoriteMutation, RemoveAllFavoriteMutationVariables>;
export const NavigationDocument = gql`
    query Navigation($slug: String!) {
  navigation(slug: $slug) {
    id
    slug
    nodes {
      ...CategoryItem
    }
  }
}
    ${CategoryItemFragmentDoc}`;

/**
 * __useNavigationQuery__
 *
 * To run a query within a React component, call `useNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useNavigationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NavigationQuery, NavigationQueryVariables>) {
        return ApolloReactHooks.useQuery<NavigationQuery, NavigationQueryVariables>(NavigationDocument, baseOptions);
      }
export function useNavigationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NavigationQuery, NavigationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NavigationQuery, NavigationQueryVariables>(NavigationDocument, baseOptions);
        }
export type NavigationQueryHookResult = ReturnType<typeof useNavigationQuery>;
export type NavigationLazyQueryHookResult = ReturnType<typeof useNavigationLazyQuery>;
export type NavigationQueryResult = ApolloReactCommon.QueryResult<NavigationQuery, NavigationQueryVariables>;
export const ProgramDocument = gql`
    query Program($programSlug: String!) {
  program(programSlug: $programSlug) {
    ...ProgramDetail
  }
}
    ${ProgramDetailFragmentDoc}`;

/**
 * __useProgramQuery__
 *
 * To run a query within a React component, call `useProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramQuery({
 *   variables: {
 *      programSlug: // value for 'programSlug'
 *   },
 * });
 */
export function useProgramQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProgramQuery, ProgramQueryVariables>) {
        return ApolloReactHooks.useQuery<ProgramQuery, ProgramQueryVariables>(ProgramDocument, baseOptions);
      }
export function useProgramLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProgramQuery, ProgramQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProgramQuery, ProgramQueryVariables>(ProgramDocument, baseOptions);
        }
export type ProgramQueryHookResult = ReturnType<typeof useProgramQuery>;
export type ProgramLazyQueryHookResult = ReturnType<typeof useProgramLazyQuery>;
export type ProgramQueryResult = ApolloReactCommon.QueryResult<ProgramQuery, ProgramQueryVariables>;
export const ProgramListDocument = gql`
    query ProgramList($categorySlug: String!, $releaseStatus: String, $genreSlug: String, $sortFilter: String, $page: Int) {
  programs(
    categorySlug: $categorySlug
    releaseStatus: $releaseStatus
    genreSlug: $genreSlug
    sortFilter: $sortFilter
    page: $page
  ) {
    totalCount
    nodes {
      ...ProgramItem
    }
    pageInfo {
      hasNextPage
      nextPage
    }
  }
}
    ${ProgramItemFragmentDoc}`;

/**
 * __useProgramListQuery__
 *
 * To run a query within a React component, call `useProgramListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramListQuery({
 *   variables: {
 *      categorySlug: // value for 'categorySlug'
 *      releaseStatus: // value for 'releaseStatus'
 *      genreSlug: // value for 'genreSlug'
 *      sortFilter: // value for 'sortFilter'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useProgramListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProgramListQuery, ProgramListQueryVariables>) {
        return ApolloReactHooks.useQuery<ProgramListQuery, ProgramListQueryVariables>(ProgramListDocument, baseOptions);
      }
export function useProgramListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProgramListQuery, ProgramListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProgramListQuery, ProgramListQueryVariables>(ProgramListDocument, baseOptions);
        }
export type ProgramListQueryHookResult = ReturnType<typeof useProgramListQuery>;
export type ProgramListLazyQueryHookResult = ReturnType<typeof useProgramListLazyQuery>;
export type ProgramListQueryResult = ApolloReactCommon.QueryResult<ProgramListQuery, ProgramListQueryVariables>;
export const SearchDocument = gql`
    query Search($keyword: String, $category: String, $genre: String, $person: String, $produceCountry: String, $produceYear: String, $page: Int) {
  search(
    keyword: $keyword
    category: $category
    genre: $genre
    person: $person
    produceCountry: $produceCountry
    produceYear: $produceYear
    page: $page
  ) {
    totalCount
    pageInfo {
      nextPage
      hasNextPage
    }
    nodes {
      ...SearchItem
    }
  }
}
    ${SearchItemFragmentDoc}`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      category: // value for 'category'
 *      genre: // value for 'genre'
 *      person: // value for 'person'
 *      produceCountry: // value for 'produceCountry'
 *      produceYear: // value for 'produceYear'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, baseOptions);
      }
export function useSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, baseOptions);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = ApolloReactCommon.QueryResult<SearchQuery, SearchQueryVariables>;
export const SigninDocument = gql`
    mutation Signin($input: SignInInput!) {
  signin(input: $input) {
    result
    item {
      token
      user {
        ...UserData
      }
    }
  }
}
    ${UserDataFragmentDoc}`;
export type SigninMutationFn = ApolloReactCommon.MutationFunction<SigninMutation, SigninMutationVariables>;

/**
 * __useSigninMutation__
 *
 * To run a mutation, you first call `useSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinMutation, { data, loading, error }] = useSigninMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSigninMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SigninMutation, SigninMutationVariables>) {
        return ApolloReactHooks.useMutation<SigninMutation, SigninMutationVariables>(SigninDocument, baseOptions);
      }
export type SigninMutationHookResult = ReturnType<typeof useSigninMutation>;
export type SigninMutationResult = ApolloReactCommon.MutationResult<SigninMutation>;
export type SigninMutationOptions = ApolloReactCommon.BaseMutationOptions<SigninMutation, SigninMutationVariables>;
export const SignupDocument = gql`
    mutation Signup($input: SignUpInput!) {
  signup(input: $input) {
    result
    item {
      token
      user {
        ...UserData
      }
    }
  }
}
    ${UserDataFragmentDoc}`;
export type SignupMutationFn = ApolloReactCommon.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        return ApolloReactHooks.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, baseOptions);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = ApolloReactCommon.MutationResult<SignupMutation>;
export type SignupMutationOptions = ApolloReactCommon.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($input: EmailVerificationInput!) {
  verifyEmail(input: $input) {
    result
  }
}
    `;
export type VerifyEmailMutationFn = ApolloReactCommon.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, baseOptions);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = ApolloReactCommon.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const VerifyCodeDocument = gql`
    mutation VerifyCode($input: CodeVerificationInput!) {
  verifyCode(input: $input) {
    result
  }
}
    `;
export type VerifyCodeMutationFn = ApolloReactCommon.MutationFunction<VerifyCodeMutation, VerifyCodeMutationVariables>;

/**
 * __useVerifyCodeMutation__
 *
 * To run a mutation, you first call `useVerifyCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyCodeMutation, { data, loading, error }] = useVerifyCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyCodeMutation, VerifyCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyCodeMutation, VerifyCodeMutationVariables>(VerifyCodeDocument, baseOptions);
      }
export type VerifyCodeMutationHookResult = ReturnType<typeof useVerifyCodeMutation>;
export type VerifyCodeMutationResult = ApolloReactCommon.MutationResult<VerifyCodeMutation>;
export type VerifyCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyCodeMutation, VerifyCodeMutationVariables>;
export const TermsOfUseDocument = gql`
    query TermsOfUse {
  termsOfUse {
    description
  }
}
    `;

/**
 * __useTermsOfUseQuery__
 *
 * To run a query within a React component, call `useTermsOfUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermsOfUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermsOfUseQuery({
 *   variables: {
 *   },
 * });
 */
export function useTermsOfUseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TermsOfUseQuery, TermsOfUseQueryVariables>) {
        return ApolloReactHooks.useQuery<TermsOfUseQuery, TermsOfUseQueryVariables>(TermsOfUseDocument, baseOptions);
      }
export function useTermsOfUseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TermsOfUseQuery, TermsOfUseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TermsOfUseQuery, TermsOfUseQueryVariables>(TermsOfUseDocument, baseOptions);
        }
export type TermsOfUseQueryHookResult = ReturnType<typeof useTermsOfUseQuery>;
export type TermsOfUseLazyQueryHookResult = ReturnType<typeof useTermsOfUseLazyQuery>;
export type TermsOfUseQueryResult = ApolloReactCommon.QueryResult<TermsOfUseQuery, TermsOfUseQueryVariables>;
export const UserDocument = gql`
    query User {
  user {
    ...UserData
  }
}
    ${UserDataFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<UserQuery, UserQueryVariables>;
export const WatchHistoriesDocument = gql`
    query WatchHistories {
  watchHistories {
    ...WatchHistoryItemWatchHistory
  }
}
    ${WatchHistoryItemWatchHistoryFragmentDoc}`;

/**
 * __useWatchHistoriesQuery__
 *
 * To run a query within a React component, call `useWatchHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWatchHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchHistoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useWatchHistoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WatchHistoriesQuery, WatchHistoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<WatchHistoriesQuery, WatchHistoriesQueryVariables>(WatchHistoriesDocument, baseOptions);
      }
export function useWatchHistoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WatchHistoriesQuery, WatchHistoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WatchHistoriesQuery, WatchHistoriesQueryVariables>(WatchHistoriesDocument, baseOptions);
        }
export type WatchHistoriesQueryHookResult = ReturnType<typeof useWatchHistoriesQuery>;
export type WatchHistoriesLazyQueryHookResult = ReturnType<typeof useWatchHistoriesLazyQuery>;
export type WatchHistoriesQueryResult = ApolloReactCommon.QueryResult<WatchHistoriesQuery, WatchHistoriesQueryVariables>;