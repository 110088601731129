import { useState, useEffect } from 'react'

import useUserContext from './useUserContext'
import { getCurrentLocale, getChangedPathname } from '../utils/locale'

import { UserDataFragment } from '../types/generated'
import { saveUserCredentials } from '../utils/user'

interface LoginData {
  token?: string
  // NOTE: Receive both types REST api response and graphql response
  user: UserDataFragment
  redirectPath?: string
}

const useLoginFlow = () => {
  const [data, setData] = useState<LoginData | null>(null)
  const { ActionTypes, dispatch } = useUserContext()

  useEffect(() => {
    if (!data) {
      return
    }
    const { token, user, redirectPath } = data
    if (token) {
      saveUserCredentials(token, user.id)
    }

    dispatch({
      type: ActionTypes.AUTHENTICATE_SUCCESS,
      payload: user,
    })

    if (redirectPath) {
      // Note: 로그인 후 새로고침 효과를 위해 history.push가 아닌
      // location.href로 돌려버림
      window.location.href = getChangedPathname({
        pathname: redirectPath || '/',
        search: '',
        localeKey: getCurrentLocale(user.info),
      })
    }
    // eslint-disable-next-line
  }, [data])

  return setData
}

export default useLoginFlow
