import './SearchLink.scss'

import React, { MouseEvent } from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { safeMapLoop, getCommaSeparatedStr } from '../../utils/misc'
import LinkWithLocale from '../../components/LinkWithLocale'

interface Props {
  links: any
  title?: string
  className?: string
  wrapperClassName?: string
}

export default function SearchLink({ links, title, className, wrapperClassName }: Props) {
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation()
  }
  const getTitle = getCommaSeparatedStr(links)

  return isEmpty(links) ? null : (
    <ul className={classNames('SearchLink', wrapperClassName)}>
      {title}{' '}
      {safeMapLoop(links, (link, i) => (
        <li key={`${link.id}${i}`} className={className}>
          <LinkWithLocale to={link.to} onClick={handleClick}>
            {getTitle(link.title, i)}
          </LinkWithLocale>
        </li>
      ))}
    </ul>
  )
}
