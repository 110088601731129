const $ = document.querySelector.bind(document)

const OVERLAY_CLASS_NAME = 'overlay'

export const delay = (delayTime: number, callback: () => void) => setTimeout(callback, delayTime)

export const preventBodyScroll = () => {
  const $html = $('html')
  const $body = $('body')
  $html!.className = `${$html!.className} ${OVERLAY_CLASS_NAME}`
  $body!.className = `${$body!.className} ${OVERLAY_CLASS_NAME}`
  $html!.style.height = '100vh'
  $body!.style.height = '100vh'
}

export const allowBodyScroll = () => {
  const $html = $('html')
  const $body = $('body')
  const reg = new RegExp(`(\\s|^)${OVERLAY_CLASS_NAME}(\\s|$)`)
  $html!.className = $html!.className.replace(reg, '')
  $body!.className = $body!.className.replace(reg, '')
  $html!.removeAttribute('style')
  $body!.removeAttribute('style')
}
