import './ErrorPage.scss'

import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { get, includes } from 'lodash'
import dayjs from 'dayjs'

import useRouter from '../../hooks/useRouter'

import LinkWithLocale from '../../components/LinkWithLocale'
import Logo from '../../components/Logo'

interface Props {}

const hasIncludeLink = ['notfound', 'expired']

const errorPageInfo = {
  notfound: {
    imageUrl: '/images/404.svg',
    title: 'error.pageNotFound',
    phrase: 'error.pageNotFoundPhrase',
  },
  server: {
    imageUrl: '/images/500.svg',
    title: 'error.server',
    phrase: 'error.serverPhrase',
  },
  region: {
    imageUrl: '/images/geoblk.svg',
    title: 'error.region',
    phrase: 'error.regionPhrase',
  },
  expired: {
    imageUrl: '/images/expired.svg',
    title: 'error.expired',
    phrase: 'error.expiredPhrase',
  },
  maintenance: {
    imageUrl: '/images/maintenance.svg',
    title: 'error.maintain',
    phrase: 'error.maintainSchedule',
  },
}

const ErrorPage: FC<Props> = () => {
  const {
    match: { params },
  } = useRouter()
  const { t } = useTranslation()
  const pageKind = get(params, 'error') || 'notfound'
  const pageInfo = get(errorPageInfo, pageKind) || get(errorPageInfo, 'notfound')
  const hasRedirect = includes(hasIncludeLink, pageKind)

  const copyright = `© 2018-${dayjs().get('year')} ODK Media, Inc.`

  return (
    <div className="ErrorPage">
      <Logo imgClassName="ErrorPage__logo" />
      <div className="ErrorPage__wrapper">
        <img className="ErrorPage__image" src={pageInfo.imageUrl} alt="Error status" />
        <h1 className="ErrorPage__title">{t(pageInfo.title)}</h1>
        <p className="ErrorPage__phrase">{t(pageInfo.phrase)}</p>
        {hasRedirect && (
          <div className="ErrorPage__links">
            <LinkWithLocale className="ErrorPage__link" to="/contact-us">
              {t('error.contactUs')}
            </LinkWithLocale>
            <LinkWithLocale className="ErrorPage__link" to="/">
              {t('error.home')}
            </LinkWithLocale>
          </div>
        )}
      </div>
      <p className="ErrorPage__copyright">{copyright}</p>
    </div>
  )
}

export default ErrorPage
