import languages from '../constants/languages.json'
import { camelize } from 'humps'
import { startsWith, isEmpty } from 'lodash'

import { getItem } from './localStorage'

import { Episode, Program, I18nContents } from '../types/models'

import { EpisodeDetailFragment, ProgramDetailFragment, UserInfo } from '../types/generated'

export const DEFAULT_LANGUAGE = 'zh-Hans'
export const STORED_LOCALE_KEY = 'selectedLocale'

export const getLocaleFormUrl = () => {
  const { pathname } = window.location
  if (pathname === '' || pathname === '/') {
    return DEFAULT_LANGUAGE
  }

  const locale = window.location.pathname.split('/')[1]

  const validLocale = languages.some(l => l.key === locale)

  if (!validLocale) {
    return DEFAULT_LANGUAGE
  }

  return locale
}

export const toLocaleUrl = (url: string) => `/${getLocaleFormUrl()}${startsWith(url, '/') ? url : `/${url}`}`

export const toI18nKey = (key: string, language: string) => camelize(`${key}_${language || getLocaleFormUrl()}`)

export const getTranslateValue = (target: any, key: string, language?: string) => {
  if (!target) {
    return null
  }

  const currentLanguage = language || getLocaleFormUrl()

  return !isEmpty(target[toI18nKey(key, currentLanguage)])
    ? target[toI18nKey(key, currentLanguage)]
    : target[toI18nKey(key, DEFAULT_LANGUAGE)]
}

export const getI18nContents = (
  data: Partial<Program | ProgramDetailFragment | Episode | EpisodeDetailFragment>
): I18nContents => {
  if (!data) {
    return {
      title: '',
      secondaryTitle: '',
      synopsis: '',
      images: {
        thumbnail: '',
        poster: '',
      },
    }
  }

  return {
    title: getTranslateValue(data, 'title'),
    secondaryTitle: getTranslateValue(data, 'secondaryTitle'),
    synopsis: getTranslateValue(data, 'synopsis'),
    images: {
      thumbnail: (data.images && data.images.thumbnail) || '',
      poster: (data.images && getTranslateValue(data.images, 'poster')) || '',
    },
  }
}

const baseTitles: { [key: string]: string } = {
  en: 'Watch Full Episodes Free-OnDemandChina',
  'zh-Hans': '高清全集免费在线观看-ODC影视',
  'zh-Hant': '高清全集免費在線觀看-ODC影視',
}

export const getBaseTitle = (language: string) => baseTitles[language]

export const getChangedPathname = ({
  pathname = window.location.pathname,
  search = window.location.search,
  localeKey,
}: {
  pathname?: string
  search?: string
  localeKey: string
}) => {
  const changedPathname = pathname.replace(/\/en|\/zh-Hans|\/zh-Hant/, `/${localeKey}`)

  return search ? `${changedPathname}${search}` : changedPathname
}

export const getCurrentLocale = (userInfo?: UserInfo | null) =>
  getItem(STORED_LOCALE_KEY, null) ?? userInfo?.language ?? getLocaleFormUrl()

export const acceptLanguageMap: { [key: string]: string } = {
  en: 'en-US',
  'zh-hans': 'zh-CN',
  'zh-hant': 'zh-TW',
}
