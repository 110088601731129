import './SideEpisodeItem.scss'

import React from 'react'

import CCLanguages from './CCLanguages'
import SideContentItem from './SideContentItem'

import { getTranslateValue } from '../utils/locale'

import {
  CurationCarouselContentsEpisodeFragment,
  CurationCarouselContentsCarouselWatchHistoryFragment,
} from '../types/generated'

interface Props {
  url: string
  imageUrl: string
  programTitle: string
  episode: CurationCarouselContentsEpisodeFragment | CurationCarouselContentsCarouselWatchHistoryFragment
}

export default function SideEpisodeItem({ url, imageUrl, programTitle, episode }: Props) {
  return (
    <SideContentItem url={url} imageUrl={imageUrl} alt={`Popular program ${programTitle}`}>
      <h4 className="SideEpisodeItem__itemTitle">{programTitle}</h4>
      <h5 className="SideEpisodeItem__itemSubTitle">{getTranslateValue(episode, 'title')}</h5>
      <CCLanguages ccLanguages={episode.videoCcLanguages} />
    </SideContentItem>
  )
}
