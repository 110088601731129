import './SideContents.scss'

import React from 'react'
import { useTranslation } from 'react-i18next'
import { find, includes } from 'lodash'

import useDeviceTypeContext from '../hooks/useDeviceTypeContext'
import { adSpots } from '../advertisement'
import { getSizedImageUrl } from '../utils/misc'
import { getTranslateValue } from '../utils/locale'
import { buildEpisodePageUrl } from '../utils/episode'

import AdArea from './AdArea'
import Loading from './Loading'
import SideProgramItem from './SideProgramItem'
import SideEpisodeItem from './SideEpisodeItem'
import SideContentsContainer from '../containers/SideContentsContainer'

import { DeviceType } from '../utils/responsive'
import { isCarouselProgram } from '../utils/typeGuards'
import { AdAreaHeight } from '../constants/enums'
import { SIDE_CONTENT_IMAGE_SIZE } from '../constants/images'
import { CurationCarouselContentsFragment } from '../types/generated'

const getLink = (content: CurationCarouselContentsFragment, programDirectPlay: boolean | null) => {
  if (isCarouselProgram(content)) {
    return `/program/${content.slug}${programDirectPlay ? '/direct-play' : ''}`
  }
  return buildEpisodePageUrl(content.program.slug, content.kind, content.number, content.part)
}

const getImageUrl = (content: CurationCarouselContentsFragment) => {
  if (isCarouselProgram(content)) {
    return getTranslateValue(content.images, 'poster')
  }

  return content.images?.thumbnail
}

interface Props {
  children?: React.ReactNode
}

export default function SideContents({ children }: Props) {
  const { t } = useTranslation()
  const deviceType = useDeviceTypeContext()
  const articleSideAdSpot = find(adSpots, { name: 'ODC_ARTICLE_SIDE' })!

  const isDesktop = includes([DeviceType.LG, DeviceType.XL, DeviceType.MX], deviceType)

  return isDesktop ? (
    <aside className="SideContents" data-cy="SideContents">
      {children}
      <div id="companion" style={{ position: 'absolute' }}></div>
      <AdArea className="SideContents__sideAd" adSpotId={articleSideAdSpot.spotId} height={AdAreaHeight.ARTICLE_SIDE} />
      <h2 className="screen-reader-text">{t('popularProgram.title')}</h2>
      <SideContentsContainer>
        {({ curations, isLoading }) => (
          <ul>
            <Loading className="SideContents__loading" visible={isLoading} size={40} />
            {curations?.map(curation => (
              <li key={curation.slug}>
                <h3 className="SideContents__title">{getTranslateValue(curation, 'title')}</h3>
                <ul className="SideContents__content">
                  {curation.carousels.map(carousel => {
                    const url = `${getLink(carousel, curation.programDirectPlay)}?pos=rightSideBar_${curation.slug}`
                    const imageUrl = getSizedImageUrl(
                      getImageUrl(carousel),
                      SIDE_CONTENT_IMAGE_SIZE.WIDTH,
                      SIDE_CONTENT_IMAGE_SIZE.HEIGHT
                    )
                    const programTitle = isCarouselProgram(carousel)
                      ? getTranslateValue(carousel, 'title')
                      : getTranslateValue(carousel.program, 'title')

                    return (
                      <li key={`${curation.slug}:${carousel.uuid}`}>
                        {isCarouselProgram(carousel) ? (
                          <SideProgramItem url={url} imageUrl={imageUrl} title={programTitle} program={carousel} />
                        ) : (
                          <SideEpisodeItem
                            url={url}
                            imageUrl={imageUrl}
                            programTitle={programTitle}
                            episode={carousel}
                          />
                        )}
                      </li>
                    )
                  })}
                </ul>
              </li>
            ))}
          </ul>
        )}
      </SideContentsContainer>
    </aside>
  ) : null
}
