import './NavBar.scss'

import React, { useState, useRef, Dispatch, SetStateAction, useEffect } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import SearchBar from './SearchBar'
import CategoryList from './CategoryList'
import UserMenu from './UserMenu'
import MobileSidebar from './MobileSidebar'
import Logo from './Logo'
import LocaleSelector from './LocaleSelector'

import useRouter from '../hooks/useRouter'
import useScrollEvent from '../hooks/useScrollEvent'
import useDeviceTypeContext from '../hooks/useDeviceTypeContext'

import { isIncludePathname, isHome, isProgramPage } from '../utils/queryString'
import { safeGet } from '../utils/misc'
import { isMobileView } from '../utils/responsive'
import { CategoryItemFragment, Maybe } from '../types/generated'

const disableUrls = ['/sign']

interface Props {
  categories: CategoryItemFragment[]
  currentCategory: Maybe<CategoryItemFragment>
  parentCategory: Maybe<CategoryItemFragment>
  mobileMenuVisible: boolean
  onVisibleMobileMenu: () => void
  onHideMobileMenu: () => void
  onCurrentCategory: Dispatch<SetStateAction<Maybe<CategoryItemFragment>>>
}

export default function NavBar({
  categories,
  currentCategory,
  parentCategory,
  mobileMenuVisible,
  onVisibleMobileMenu,
  onHideMobileMenu,
  onCurrentCategory,
}: Props) {
  const navRef = useRef<HTMLDivElement>(null)
  const navbarOffsetTop = useRef(0)
  const [visibleSearchBar, setVisibleSearchBar] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  const { t } = useTranslation()
  const {
    location: { pathname },
  } = useRouter()
  const deviceType = useDeviceTypeContext()

  useEffect(() => {
    const $nav = navRef.current
    if ($nav === null) {
      return
    }
    navbarOffsetTop.current = safeGet(() => $nav.getBoundingClientRect().top, 0)
  }, [])

  useScrollEvent(() => {
    if (window.scrollY <= navbarOffsetTop.current) {
      setIsSticky(false)
      return
    }
    if (window.scrollY > navbarOffsetTop.current) {
      setIsSticky(true)
    }
  })

  const handleCategoryClick = (category: CategoryItemFragment) => {
    onCurrentCategory(category)
  }

  const isDisable = isIncludePathname(pathname, disableUrls)

  return isDisable ? null : (
    <nav
      ref={navRef}
      className={classNames('NavBar', {
        'NavBar--transparent': isHome(pathname) || (isProgramPage(pathname) && isMobileView(deviceType)),
        'NavBar--sticky': isSticky && !mobileMenuVisible,
      })}
    >
      <div
        className={classNames('NavBar__wrapper', {
          'NavBar__wrapper--sticky': isSticky && !mobileMenuVisible,
        })}
      >
        <Logo imgClassName="NavBar__logo" />
        <button id="MobileSidebarToggle" className="NavBar__menuToggle" onClick={onVisibleMobileMenu}>
          menu toggle
        </button>
        <CategoryList
          isRootCategoryList
          categories={categories}
          currentCategory={parentCategory ?? currentCategory}
          onCategoryClick={handleCategoryClick}
          onHideMobileMenu={onHideMobileMenu}
        />
        <div className="NavBar__right">
          <button id="SearchMenuToggle" className="NavBar__searchButton" onClick={() => setVisibleSearchBar(true)}>
            {t('searchBar.togglebutton')}
          </button>
          <LocaleSelector />
          <UserMenu onHideMobileMenu={onHideMobileMenu} />
        </div>
      </div>
      <MobileSidebar
        categories={categories}
        currentCategory={parentCategory ?? currentCategory}
        mobileMenuVisible={mobileMenuVisible}
        onCategoryClick={handleCategoryClick}
        onHideMobileMenu={onHideMobileMenu}
      />
      {visibleSearchBar && <SearchBar onClose={() => setVisibleSearchBar(false)} />}
    </nav>
  )
}
