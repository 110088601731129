import React, { useState, useEffect } from 'react'
import { debounce } from 'lodash'

import getDeviceType, { DeviceType } from '../utils/responsive'

interface Props {
  children: React.ReactNode
}

export const DeviceTypeContext = React.createContext<DeviceType>(getDeviceType())

export function DeviceTypeContextProvider({ children }: Props) {
  const [deviceType, setDeviceType] = useState(getDeviceType())

  useEffect(() => {
    const resizeEvent = debounce(() => {
      setDeviceType(getDeviceType())
    }, 50)

    window.addEventListener('resize', resizeEvent)

    return () => {
      window.removeEventListener('resize', resizeEvent)
    }
  }, [])
  return <DeviceTypeContext.Provider value={deviceType}>{children}</DeviceTypeContext.Provider>
}

export const DeviceTypeContextConsumer = DeviceTypeContext.Consumer
