import React, { createContext, useReducer, FC } from 'react'

import { Action } from '../types/common'

interface State {
  message: string
  isShowing: boolean
}

const initialState: State = {
  message: '',
  isShowing: false,
}

const ActionTypes = {
  ACTIVE_NOTIFICATION: 'ACTIVE_NOTIFICATION',
  DEACTIVE_NOTIFICATION: 'DEACTIVE_NOTIFICATION',
}

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionTypes.ACTIVE_NOTIFICATION:
      return {
        ...state,
        message: action.payload.message,
        isShowing: true,
      }
    case ActionTypes.DEACTIVE_NOTIFICATION:
      return {
        message: '',
        isShowing: false,
      }
    default:
      return state
  }
}

const NotificationContext = createContext({
  ...initialState,
  // eslint-disable-next-line
  dispatch: (action: Action) => {
    // eslint-disable-next-line
  },
  ActionTypes,
})

export const NotificationContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const value = {
    ...state,
    dispatch,
    ActionTypes,
  }

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>
}

export default NotificationContext
