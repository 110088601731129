import { flow, replace, split as splitFp, map, filter, fromPairs, toPairs, join } from 'lodash/fp'
import { indexOf, get, includes, some, isEmpty, isNil, values, every } from 'lodash'
import i18next from 'i18next'

import { BroadcastStatus, FilterSortOptions } from '../constants/enums'

export const SIGNIN_URL = '/sign/sign-in'

export const getQueryObject = (queryString: string) => {
  if (!queryString) {
    return {}
  }

  return flow(
    replace('?', ''),
    splitFp('&'),
    map(x => {
      const slicePoint = indexOf(x, '=')
      return [x.slice(0, slicePoint)].concat(decodeURI(x.slice(slicePoint + 1)))
    }),
    fromPairs
  )(queryString)
}

const checkValidQueryObject = (queryObject: { [key: string]: any }) =>
  every(values(queryObject), (v: any) => typeof v === 'string' || typeof v === 'number')

const pruneQueryObject = (queryObject: { [key: string]: any }) =>
  flow(
    toPairs,
    filter(([, value]) => !!value),
    fromPairs
  )(queryObject)

export const getQueryString = (queryObject: { [key: string]: string | number }) => {
  if (isEmpty(queryObject)) {
    return ''
  }
  const cleanQueryObject = pruneQueryObject(queryObject)
  if (!checkValidQueryObject(cleanQueryObject)) {
    return ''
  }

  return `?${flow(
    toPairs,
    map(x => x.join('=')),
    join('&')
  )(cleanQueryObject)}`
}

export const getSearchValue = (queryString: string, key: string) => {
  const value = get(getQueryObject(queryString), key)
  return value === undefined ? value : decodeURI(value)
}

export const getNextPage = (search: string): string | null =>
  getSearchValue(search, 'next') ? decodeURIComponent(getSearchValue(search, 'next')) : null

export const getSigninUrl = () => {
  const { pathname, search = '' } = window.location
  return includes(pathname, 'sign-in') ? SIGNIN_URL : `${SIGNIN_URL}?next=${encodeURIComponent(`${pathname}${search}`)}`
}

const checkIncludePathname = (pathname: string, checkUrl: string) => pathname.indexOf(checkUrl) >= 0

export const isIncludePathname = (pathname: string, checkUrls: string | string[]) => {
  if (!(pathname && checkUrls)) {
    return false
  }

  if (typeof checkUrls === 'string') {
    return checkIncludePathname(pathname, checkUrls)
  }

  return some(checkUrls, url => checkIncludePathname(pathname, url))
}

export const isHome = (pathname: string) => pathname === `/${i18next.language}`

export const isProgramPage = (pathname: string) => /\/(en|zh-Hans|zh-Hant)\/program\/.+/.test(pathname)

export const isProgramListPage = (pathname: string) => /\/(en|zh-Hans|zh-Hant)\/category\/.+/.test(pathname)

export const RESET_FILTER = ''
export const GENRE_ALL = ''

export const getFilterOptionsFromUrl = (
  search: string
): {
  genre: string
  status: BroadcastStatus
  sortFilter: FilterSortOptions
} => {
  const { genre, status, sortFilter } = getQueryObject(search)

  const isValidStatus = includes(values(BroadcastStatus), status)
  const isValidSortFilterOptions = includes(values(FilterSortOptions), sortFilter)

  return {
    genre: genre ?? GENRE_ALL,
    status: isValidStatus ? status : BroadcastStatus.ALL,
    sortFilter: isValidSortFilterOptions ? sortFilter : FilterSortOptions.POPULAR,
  }
}

export const generateFilterString = ({
  genre,
  status,
  sortFilter,
}: {
  genre?: string
  status?: BroadcastStatus
  sortFilter?: FilterSortOptions
}) =>
  getQueryString({
    genre: isNil(genre) ? GENRE_ALL : genre,
    status: isNil(status) || status === BroadcastStatus.ALL ? '' : status,
    sortFilter: isNil(sortFilter) || sortFilter === FilterSortOptions.POPULAR ? '' : sortFilter,
  })

export const isDefaultFilterOptions = (status: BroadcastStatus, genre: string) =>
  status === BroadcastStatus.ALL && genre === GENRE_ALL
