import { useRef, useEffect } from 'react'

export default function useInterval(callback: () => void, gap: number) {
  const savedCallback = useRef() as { current: () => void }

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    const tick = () => savedCallback.current()
    const intervalId = setInterval(tick, gap)

    return () => clearInterval(intervalId)
    // eslint-disable-next-line
  }, [gap])
}
