import './UserMenu.scss'

import React, { useState, useCallback, useRef, memo, FC, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { get, first, upperFirst } from 'lodash'
import classNames from 'classnames'

import { validateEmail } from '../utils/authUtils'

import useEventListener from '../hooks/useEventListener'
import useUserContext from '../hooks/useUserContext'

import SigninLink from './SigninLink'
import LinkWithLocale from './LinkWithLocale'
import LogoutButton from './LogoutButton'

interface Props {
  isMobile?: boolean
  onHideMobileMenu?: () => void
}

const getDisplayName = (username: string) => (validateEmail(username) ? first(username.split('@')) : username)

const dropdownItems = [
  {
    titleKey: 'userInfoPage.favorite',
    to: '/user/favorites',
  },
  {
    titleKey: 'userInfoPage.watchHistory',
    to: '/user/watch-history',
  },
  {
    titleKey: 'userInfoPage.account',
    to: '/user/account',
  },
]

const UserMenu: FC<Props> = ({ isMobile, onHideMobileMenu }) => {
  const [isActive, setIsActive] = useState(false)
  const { t } = useTranslation()
  const activeButtonEl = useRef<HTMLButtonElement>(null)
  const logoutButtonEl = useRef<HTMLButtonElement>(null)
  const { isAuthenticated, username, info } = useUserContext()

  const name = get(info, 'name') || getDisplayName(username)
  const inActiveMenus = useCallback(() => {
    setIsActive(false)
    if (isMobile && typeof onHideMobileMenu === 'function') {
      onHideMobileMenu()
    }
    // eslint-disable-next-line
  }, [])

  const inActiveLinks = useCallback((e: MouseEvent<HTMLUListElement>) => {
    if (e.target === logoutButtonEl.current) {
      return
    }
    inActiveMenus()
    // eslint-disable-next-line
  }, [])

  useEventListener([
    {
      eventName: 'click',
      callback: e => {
        if (e.target !== activeButtonEl.current && e.target !== logoutButtonEl.current) {
          setIsActive(false)
        }
      },
    },
  ])

  return isAuthenticated ? (
    <div
      className={classNames('UserMenu', {
        'UserMenu--isMobile': isMobile,
      })}
    >
      <button
        ref={activeButtonEl}
        id="UserMenuToggle"
        className={classNames('UserMenu__button', {
          'UserMenu__button--active': isActive,
        })}
        onClick={() => {
          setIsActive(prevState => !prevState)
        }}
      >
        {name}
      </button>
      {isActive && (
        <ul className="UserMenu__links" onClick={inActiveLinks} onMouseLeave={inActiveMenus}>
          {dropdownItems.map(({ titleKey, to }) => (
            <li key={titleKey} className="UserMenu__link">
              <LinkWithLocale to={to}>{upperFirst(t(titleKey))}</LinkWithLocale>
            </li>
          ))}
          <li>
            <LogoutButton
              buttonRef={logoutButtonEl}
              className={classNames('UserMenu__signOut', {
                'UserMenu__signOut--isMobile': isMobile,
              })}
              onSuccess={inActiveMenus}
            />
          </li>
        </ul>
      )}
    </div>
  ) : (
    <SigninLink isMobile={isMobile} onHideMobileMenu={onHideMobileMenu} />
  )
}

export default memo(UserMenu)
