import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'

import useUserContext from './useUserContext'
import { STORED_LOCALE_KEY } from '../utils/locale'
import localStorage from '../utils/localStorage'

interface SyncLocaleParams {
  locale: string | null
  isSaveLocalStorage?: boolean
}

const useSyncLocale = () => {
  const [data, setData] = useState<SyncLocaleParams | null>(null)
  const { i18n } = useTranslation()
  const { ActionTypes, dispatch } = useUserContext()

  useEffect(() => {
    if (isNil(data) || isNil(data.locale)) {
      return
    }
    const { locale, isSaveLocalStorage } = data
    dispatch({
      type: ActionTypes.SET_CURRENT_LOCALE,
      payload: locale,
    })

    i18n.changeLanguage(locale)
    if (isSaveLocalStorage) {
      localStorage.setItem(STORED_LOCALE_KEY, locale)
    }
    // eslint-disable-next-line
  }, [data])

  return setData
}

export default useSyncLocale
