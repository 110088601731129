import './SearchResultPage.scss'

import React from 'react'
import { useTranslation } from 'react-i18next'

import SideContents from '../../components/SideContents'
import SearchResults from './SearchResults'

export default function SearchResultPage() {
  return (
    <div className="SearchResultPage">
      <h1 className="SearchResultPage__title">{useTranslation().t('searchResult.title')}</h1>
      <div className="SearchResultPage__wrapper">
        <SearchResults />
        <SideContents />
      </div>
    </div>
  )
}
