import './SideContentItem.scss'

import React, { FC, ReactNode } from 'react'

import LinkWithLocale from './LinkWithLocale'

interface Props {
  url: string
  imageUrl: string
  alt: string
  nativeLink?: boolean
  children: ReactNode
}

const SideContentItem: FC<Props> = ({ url, imageUrl, alt, nativeLink = false, children }) => {
  return (
    <LinkWithLocale nativeLink={nativeLink} className="SideContentItem" to={url}>
      <img className="SideContentItem__image" src={imageUrl} alt={alt} />
      <div className="SideContentItem__content">{children}</div>
    </LinkWithLocale>
  )
}

export default SideContentItem
