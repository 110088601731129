import './MobileSubCategorySelector.scss'

import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { isNil } from 'lodash'

import Portal from './Portal'
import Translate from './Translate'

import { toLocaleUrl } from '../utils/locale'
import { preventBodyScroll, allowBodyScroll } from '../utils/ui'
import { CategoryItemFragment, Maybe } from '../types/generated'

interface Props {
  categories: CategoryItemFragment[]
  currentCategory: CategoryItemFragment
  parentCategory?: Maybe<CategoryItemFragment>
  isHome: boolean
  onCloseClick: () => void
  onModalClick: (category: CategoryItemFragment) => void
  onIsCategorySelected: (slug: string) => Maybe<boolean>
}

export default function MobileSubCategorySelector({
  categories,
  currentCategory,
  parentCategory,
  isHome,
  onCloseClick,
  onModalClick,
  onIsCategorySelected,
}: Props) {
  const { t } = useTranslation()

  useEffect(() => {
    preventBodyScroll()
    return () => allowBodyScroll()
  }, [])

  return (
    <Portal position="top">
      <div className="MobileSubCategorySelector">
        <div className="MobileSubCategorySelector__header">
          <span className="MobileSubCategorySelector__parentCategory">
            <Translate target={parentCategory} fieldKey="title" />
          </span>
          <button
            id="CategorySelectOveralyClose"
            className="MobileSubCategorySelector__closeButton"
            onClick={onCloseClick}
          >
            close
          </button>
        </div>
        <ul className="MobileSubCategorySelector__categoryList">
          <li
            id="AllCategorySelect"
            className={classNames('MobileSubCategorySelector__subCategory', {
              'MobileSubCategorySelector__subCategory--selected': isNil(parentCategory),
            })}
            onClick={() => onModalClick(parentCategory ?? currentCategory)}
          >
            <NavLink to={toLocaleUrl(`/category/${parentCategory?.slug ?? currentCategory.slug}`)}>
              {t('programFilter.all')}
            </NavLink>
          </li>
          {categories.map((category: CategoryItemFragment) => (
            <li
              key={category.slug}
              id="CategorySelect"
              className={classNames('MobileSubCategorySelector__subCategory', {
                'MobileSubCategorySelector__subCategory--selected': !isHome && onIsCategorySelected(category.slug),
              })}
              onClick={() => onModalClick(category)}
            >
              <NavLink to={toLocaleUrl(`/category/${category.slug}`)}>
                <Translate target={category} fieldKey="title" />
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </Portal>
  )
}
