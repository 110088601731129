export enum DeviceType {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  MX = 'mx',
}

export enum FilterSortOptions {
  POPULAR = 'popular-yesterday',
  ONAIREDDATE = '-release_start',
  PUBLISHED = 'recent-updated',
}

export enum ReleaseStatus {
  ONAIR = 'on-air',
  COMPLETED = 'completed',
  COMINGSOON = 'coming-soon',
  UNKNOWN = 'unknown',
}

export enum BroadcastStatus {
  ALL = 'all',
  ONAIR = 'on-air',
  COMPLETED = 'completed',
  COMINGSOON = 'coming-soon',
  END = 'end',
  UNKNOWN = 'unknown',
}

export enum PublishStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  HIDDEN = 'hidden',
  EXPIRED = 'expired',
  DISABLED = 'disabled',
}

export enum EpisodeType {
  MAIN_FEATURE = 'main-feature',
  SERIES = 'series',
  CLIP = 'clip',
  TRAILER = 'trailer',
  TEASER = 'teaser',
}

export enum HomeContentType {
  PROGRAM = 'program',
  EPISODE = 'episode',
  WATCH_HISTORY = 'watchHistory',
}

export enum KeyCode {
  ESC = 27,
  ENTER = 13,
  SPACEBAR = 32,
  ARROW_LEFT = 37,
  ARROW_RIGHT = 39,
  ARROW_UP = 38,
  ARROW_DOWN = 40,
  TAB = 9,
}

export enum Country {
  KR = 'Korea',
  CN = 'China',
  US = 'United States',
  CA = 'Canada',
  TW = 'Taiwan',
}

export enum CategoryType {
  CATEGORY = 'category',
  GENRE = 'genre',
}

export enum FreshdeskLanguage {
  'zh-Hans' = 'zh-CN',
  'zh-Hant' = 'zh-TW',
  en = 'en',
}

export enum FreshdeskTabStatus {
  VISIBLE = 1,
  INVISIBLE = 0,
}

export enum SearchQueryType {
  NORMAL = 'q',
  PERSON = 'person',
  GENRE = 'genre',
  CATEGORY = 'category',
  PRODUCE_COUNTRY = 'produce_country',
  PRODUCE_YEAR = 'produce_year',
}

export enum HTTPStatus {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  GONE = 410,
  SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  SERVICE_UNAVALIABLE = 503,
}

export enum ItemType {
  PROGRAM,
  EPISODE,
}

export enum PosterType {
  LANDSCAPE,
  PORTRAIT,
}

export enum CuePointCodeType {
  INTRO_START = 'intro-start',
  INTRO_END = 'intro-end',
  ENDING_START = 'ending-start',
}

export enum LogicOrderingOptions {
  EPISODE_CONTINUE_WATCHING = 'episode-continue-watching',
  EPISODE_RECENT_UPDATED = 'episode-recent-updated',
  PROGRAM_RECENT_UPDATED = 'program-recent-updated',
  PROGRAM_POPULAR = 'program-popular',
  PROGRAM_CUSTOM_DRAMA = 'program-custom-drama',
  PROGRAM_CUSTOM_VARIETY = 'program-custom-variety',
  PROGRAM_CUSTOM_DOCUMENTARY = 'program-custom-documentary',
  PROGRAM_CUSTOM_MOVIE = 'program-custom-movie',
  PROGRAM_CUSTOM_NEWS = 'program-custom-news',
  PROGRAM_CUSTOM_KIDS = 'program-custom-kids',
}

export enum DispatchEventType {
  INTERSECTING = 'intersecting',
}

export enum AdAreaHeight {
  LEFT_RIGHT = 600,
  ARTICLE_SIDE = 250,
  ARTICLE_MID = 90,
  ARTICLE_BOTTOM = 250,
}
