import './SideProgramItem.scss'

import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import CCLanguages from './CCLanguages'
import SideContentItem from './SideContentItem'
import EpisodeSubInfo from './EpisodeSubInfo'

import { safeMapLoop, getCommaSeparatedStr } from '../utils/misc'
import { getTranslateValue } from '../utils/locale'
import { ReleaseStatus } from '../constants/enums'

import { CurationCarouselContentsProgramFragment } from '../types/generated'

interface Props {
  url: string
  imageUrl: string
  title: string
  program: CurationCarouselContentsProgramFragment
}

export default function SideProgramItem({ url, imageUrl, title, program }: Props) {
  const { t } = useTranslation()
  const { meta, releaseStatus, latestEpisode, produceYear } = program
  const countries = meta?.produceCountries ?? []
  const getCountries = getCommaSeparatedStr(countries)

  return (
    <SideContentItem url={url} imageUrl={imageUrl} alt={`Popular program ${title}`}>
      <h4 className="SideProgramItem__title">{title}</h4>
      {meta?.isSeries && (
        <EpisodeSubInfo
          latestEpisodeTitle={latestEpisode ? getTranslateValue(program.latestEpisode, 'title') : ''}
          episodeTotal={meta?.episodeTotal ?? 0}
          releaseStatus={releaseStatus as ReleaseStatus}
        />
      )}
      <p className="SideProgramItem__meta">
        <span>
          {produceYear} {produceYear && !isEmpty(countries) && '|'}{' '}
          {safeMapLoop(countries, (country, i) => getCountries(t(country), i) || '')}
        </span>
      </p>
      <CCLanguages ccLanguages={meta?.ccLanguages} />
    </SideContentItem>
  )
}
