import React, { useRef, FC } from 'react'
import { first } from 'lodash'

import useLazyLoad from '../hooks/useLazyLoad'

import { DispatchEventType } from '../constants/enums'

const ioOptions = {
  root: null,
  threshold: 1,
}

interface Props {
  isLoading?: boolean
}

const ScrollFetch: FC<Props> = ({ isLoading }) => {
  const divRef = useRef<HTMLDivElement>(null)

  useLazyLoad(
    divRef,
    (divEntries: IntersectionObserverEntry[]) => {
      const divEntry = first(divEntries)
      const intersectingEvent = new CustomEvent(DispatchEventType.INTERSECTING)

      if (!divEntry) {
        return
      }
      if (divEntry.isIntersecting) {
        window.dispatchEvent(intersectingEvent)
      }
    },
    ioOptions
  )

  return !!isLoading ? null : <div ref={divRef} className="ScrollFetch" />
}

export default ScrollFetch
