import './SearchResults.scss'

import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { upperCase, includes, find, values, head, keys, camelCase, isEmpty } from 'lodash'

import AdArea from '../../components/AdArea'
import Loading from '../../components/Loading'
import ScrollFetch from '../../components/ScrollFetch'
import SearchResultItem from './SearchResultItem'

import useRouter from '../../hooks/useRouter'
import useEndOfScroll from '../../hooks/useEndOfScroll'

import { getQueryObject } from '../../utils/queryString'
import { adSpots } from '../../advertisement'
import { Country, AdAreaHeight } from '../../constants/enums'
import { useSearchLazyQuery, SearchQueryVariables, Maybe } from '../../types/generated'

const isCountry = (searchKeyword: string) => includes(keys(Country), searchKeyword)

export default function SearchResults() {
  const {
    location: { search },
  } = useRouter()
  const queryObject = getQueryObject(search)
  const parameterName = head(keys(queryObject))
  const searchKeyword = head(values(queryObject))
  const { t } = useTranslation()
  const [fetch, { data, called, loading, fetchMore, client }] = useSearchLazyQuery({
    notifyOnNetworkStatusChange: true,
  })
  const searchItems = data?.search.nodes ?? []

  const fetchMoreRef = useRef(fetchMore)
  const variablesRef = useRef<Maybe<Omit<SearchQueryVariables, 'page'>>>(null)
  const nextPageRef = useRef(data?.search.pageInfo.nextPage)
  const searchResultsRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    if (!search || !parameterName || !searchKeyword) {
      return
    }

    const variables = {
      keyword: parameterName === 'q' ? searchKeyword : null,
      category: null,
      genre: null,
      person: null,
      produceCountry: null,
      produceYear: null,
      page: queryObject.page ? parseInt(queryObject.page, 10) : 1,
      [camelCase(parameterName)]: searchKeyword,
    }

    fetch({ variables })

    variablesRef.current = {
      ...variables,
    }
    // eslint-disable-next-line
  }, [search])

  const articleBottomAdSpot = find(adSpots, { name: 'ODC_ARTICLE_BOTTOM' })!

  useEffect(() => {
    fetchMoreRef.current = fetchMore
  }, [fetchMore])

  useEffect(() => {
    nextPageRef.current = data?.search.pageInfo.nextPage
    // eslint-disable-next-line
  }, [data?.search.pageInfo.nextPage])

  useEndOfScroll(searchResultsRef, () => {
    if (variablesRef.current && nextPageRef.current) {
      const variables = {
        ...variablesRef.current,
        page: nextPageRef.current,
      }

      fetchMoreRef
        .current?.({
          variables,
        })
        .then(() => fetch({ variables }))
    }
  })

  useEffect(() => {
    return () => {
      client?.cache.evict({
        fieldName: 'search',
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="SearchResults">
      <h2 className="SearchResults__title">
        {t('searchResult.searchFor')}{' '}
        <span className="SearchResults__searchKeyword">
          {isCountry(searchKeyword) ? t(searchKeyword) : searchKeyword}
        </span>
        .
      </h2>
      <ul ref={searchResultsRef} className="SearchResults__resultItems">
        {searchItems.map(item => (
          <SearchResultItem key={item.uuid} item={item} />
        ))}
      </ul>
      {loading && <Loading className="SearchResults__loading" visible size={60} />}
      {called && !loading && isEmpty(searchItems) && (
        <div className="SearchResults__emptyResult">
          <p>{upperCase(t('searchResult.noResult'))}</p>
          <span>{t('searchResult.tryAnotherSearch')}</span>
        </div>
      )}
      {data?.search.pageInfo.hasNextPage && called && !loading && <ScrollFetch />}
      <AdArea
        className="SearchResults__bottomAd"
        adSpotId={articleBottomAdSpot.spotId}
        height={AdAreaHeight.ARTICLE_BOTTOM}
      />
    </div>
  )
}
