import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'

import useUserContext from '../hooks/useUserContext'
import { getRequestHeader } from '../utils/api'

import { useCurationQuery, CurationType, CurationItemFragment } from '../types/generated'

interface Props {
  children: (props: { curations: CurationItemFragment[]; isLoading: boolean }) => React.ReactElement<any>
}

export default function SideContentsContainer({ children }: Props) {
  const { isAuthenticated } = useUserContext()
  const { data, called, loading, refetch } = useCurationQuery({
    context: { headers: getRequestHeader({ isAuthRequest: true }) },
    variables: {
      type: CurationType.SIDE_CONTENT,
    },
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (called) {
      refetch()
    }
  }, [isAuthenticated, called, refetch])

  const curations =
    data?.curation.nodes.filter(node =>
      isAuthenticated
        ? !isEmpty(node.carousels)
        : !isEmpty(node.carousels) && node.logicOrdering !== 'episode-continue-watching'
    ) ?? []

  return children({ curations, isLoading: loading })
}
