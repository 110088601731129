import variables from '../styles/responsive.module.scss'
import { includes } from 'lodash'

export enum DeviceType {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  MX = 'mx',
}

export default function getDeviceType(): DeviceType {
  const { innerWidth } = window
  const small = parseInt(variables.small, 10)
  const medium = parseInt(variables.medium, 10)
  const large = parseInt(variables.large, 10)
  const extraLarge = parseInt(variables.extraLarge, 10)
  const max = parseInt(variables.max, 10)

  if (innerWidth < small) {
    return DeviceType.XS
  } else if (innerWidth >= small && innerWidth < medium) {
    return DeviceType.SM
  } else if (innerWidth >= medium && innerWidth < large) {
    return DeviceType.MD
  } else if (innerWidth >= large && innerWidth < extraLarge) {
    return DeviceType.LG
  } else if (innerWidth >= extraLarge && innerWidth < max) {
    return DeviceType.XL
  } else if (innerWidth >= max) {
    return DeviceType.MX
  }

  return DeviceType.SM
}

export const isMobileView = (deviceType: DeviceType) =>
  includes([DeviceType.XS, DeviceType.SM, DeviceType.MD], deviceType)
