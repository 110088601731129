import UAParser from 'ua-parser-js'

const parser = new UAParser(navigator.userAgent)

const { os, browser } = parser.getResult()

export const isIOS = os.name?.includes('iOS')

export const isAndroid = os.name?.includes('Android')

export const isIE = browser.name?.includes('IE')
