import { RefObject, useState } from 'react'
import { first } from 'lodash'

import useLazyLoad from './useLazyLoad'

import { PosterType } from '../constants/enums'

type IntersectionTarget = HTMLDivElement | HTMLImageElement

export const isImage = ($ref: IntersectionTarget): $ref is HTMLImageElement => $ref instanceof HTMLImageElement

export const isPortrait = (width: number, height: number) => width < height

export const getFirstClassName = (className: string) => (className ? first(className.split(' ')) : '')

export const appendPosterPlaceholder = ($imageElement: IntersectionTarget) => {
  const $div = document.createElement('div')
  $imageElement.insertAdjacentElement('afterend', $div)

  return $div
}

export const setImage = ($imgObj: IntersectionObserverEntry, $targetElement: IntersectionTarget) => {
  if (isImage($targetElement)) {
    $targetElement.setAttribute('src', `${$imgObj.target.getAttribute('data-src')}`)
    return
  }
  $targetElement.style.backgroundImage = `url(${$imgObj.target.getAttribute('data-src')})`
}

const useLazyLoadImage = (imageRef: RefObject<IntersectionTarget>) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [imagePosterType, setImagePosterType] = useState<PosterType | null>(null)

  const setNextLazyValue = useLazyLoad(imageRef, (imageEntries, observer) => {
    const imageEntry = first(imageEntries)
    if (!imageEntry) {
      return
    }
    if (imageEntry.isIntersecting) {
      const $intersectedEl = imageEntry.target as IntersectionTarget

      const originClassName = getFirstClassName($intersectedEl.className)
      const imgObj = new Image()
      imgObj.src = imageEntry.target.getAttribute('data-src') as string

      imgObj.addEventListener('load', () => {
        $intersectedEl.className = `${$intersectedEl.className} ${originClassName}--loaded`
        if (isPortrait(imgObj.width, imgObj.height) && !$intersectedEl.nextSibling) {
          $intersectedEl.className = `${$intersectedEl.className} ${originClassName}--portrait`
          const $siblingDiv = appendPosterPlaceholder($intersectedEl)
          $siblingDiv.style.backgroundImage = `url(${imageEntry.target.getAttribute('data-src')})`
        }
        setImage(imageEntry, $intersectedEl)
        setIsLoaded(true)
        setImagePosterType(isPortrait(imgObj.width, imgObj.height) ? PosterType.PORTRAIT : PosterType.LANDSCAPE)
        observer.unobserve(imageEntry.target)
      })

      imgObj.addEventListener('error', () => {
        $intersectedEl.className = `${$intersectedEl.className} ${originClassName}--loaded`
        setIsLoaded(true)
        observer.unobserve(imageEntry.target)
      })
    }
  })

  return { isLoaded, imagePosterType, setNextLazyValue }
}

export default useLazyLoadImage
