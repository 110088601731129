import './LogoutButton.scss'

import React, { useCallback, FC, RefObject } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { upperFirst } from 'lodash'

import useRequestApi from '../hooks/useRequestApi'
import useUserContext from '../hooks/useUserContext'

import { getSignoutUrl } from '../utils/urls'
import { reloadPage } from '../utils/misc'

import Loading from './Loading'
import { clearStoredUserCredentials } from '../utils/user'

interface Props {
  buttonRef?: RefObject<HTMLButtonElement>
  className?: string
  onSuccess?: () => void
}

const LogoutButton: FC<Props> = ({ buttonRef, className, onSuccess }) => {
  const { t } = useTranslation()
  const { dispatch, ActionTypes } = useUserContext()
  const [{ isLoading }, post] = useRequestApi({
    onSuccess: () => {
      dispatch({ type: ActionTypes.AUTHENTICATE_CANCEL })
      clearStoredUserCredentials()
      if (onSuccess) {
        onSuccess()
      }
      reloadPage()
    },
    method: 'post',
  })
  const logOut = useCallback(() => {
    post(getSignoutUrl(), {})
    // eslint-disable-next-line
  }, [])

  return (
    <button
      ref={buttonRef}
      id="Logout"
      className={classNames('LogoutButton', className, {
        'LogoutButton--isLoading': isLoading,
      })}
      onClick={logOut}
    >
      {upperFirst(t('signout'))}
      {isLoading && <Loading className="LogoutButton__loading" visible />}
    </button>
  )
}

export default LogoutButton
