import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { getLocaleFormUrl } from './utils/locale'

import en from './languages/en.json'
import zhHans from './languages/zh-hans.json'
import zhHant from './languages/zh-hant.json'

i18n.use(initReactI18next).init({
  keySeparator: '::',
  fallbackLng: 'zh-Hans',
  lowerCaseLng: true,
  lng: getLocaleFormUrl(),
})

i18n.addResourceBundle('en', 'translation', en)
i18n.addResourceBundle('zh-hans', 'translation', zhHans)
i18n.addResourceBundle('zh-hant', 'translation', zhHant)

const filterKeysForPlayer = (i18nObj: any) => {
  let result: any = {}
  for (const key in i18nObj) {
    // error.region도 player에서 사용돼서 추가
    if (/^player\./.test(key) || key === 'error.region') {
      result[key] = i18nObj[key]
    }
  }
  return result
}

window.PLAYER_I18N = {
  en: filterKeysForPlayer(en),
  'zh-Hans': filterKeysForPlayer(zhHans),
  'zh-Hant': filterKeysForPlayer(zhHant),
}

export default i18n
