import React, { ReactNode, MouseEvent } from 'react'

interface Props {
  className?: string
  href: string
  children?: ReactNode
  onClick?: (e: MouseEvent) => void
}

export default function OuterLink({ className, href, children, onClick }: Props) {
  return (
    <a className={className} href={href} target="_blank" rel="noopener noreferrer" onClick={onClick}>
      {children}
    </a>
  )
}
