/* eslint-disable @typescript-eslint/camelcase */
export const API_END_POINT = process.env.REACT_APP_API_END_POINT || 'https://dev.odkmedia.io/odc/api/v1'
export const API_V2_END_POINT = process.env.REACT_APP_API_V2_END_POINT || 'https://dev.odkmedia.io/odc/api/v2'
export const CW_ENDPOINT = process.env.REACT_APP_CW_END_POINT || 'https://cw-dev.odkmedia.io/v1'
export const CW_HEADERS = {
  'ODK-Service-Name': 'odc',
  'Content-Type': 'application/json',
}

// Signin Signup
export const getVerifyCodeUrl = () => `${API_END_POINT}/user/verify/`
export const getConfirmCodeUrl = () => `${API_END_POINT}/user/verify/confirm/`
export const getSignupUrl = () => `${API_END_POINT}/user/sign-up/`
export const getSigninUrl = () => `${API_END_POINT}/user/sign-in/`
export const getSignoutUrl = () => `${API_END_POINT}/user/sign-out/`

// User account
export const fetchUserProfileUrl = () => `${API_END_POINT}/user/profile/`
export const getResetPasswordUrl = () => `${API_END_POINT}/user/reset-password/`
export const getForgotPasswordUrl = () => `${API_END_POINT}/user/forgot-password/`
export const getDeleteAccountUrl = () => `${API_END_POINT}/user/delete-account/`

// User
export const getRemoveWatchHistoryUrl = (userId: number, episodeId: number) =>
  `${CW_ENDPOINT}/wh/users/${userId}/episodes/${episodeId}`
export const getRemoveAllWatchHistoryUrl = (userId: number) => `${CW_ENDPOINT}/wh/users/${userId}`

export const getEpisodeContinueWatchingUrl = (userId: number, episodeId: number) =>
  `${CW_ENDPOINT}/cw/users/${userId}/episodes/${episodeId}`

export const getContactUsUrl = () => `${API_END_POINT}/contact-us/`
