import { uniqBy } from 'lodash'

import { TypePolicies, FieldPolicy, FieldReadFunction, FieldFunctionOptions } from '@apollo/client'
import { FavoriteConnection, PageInfo, ProgramConnection, EpisodeConnection } from '../types/generated'
import { People } from '../types/models'

const DEFAULT_PAGE_SIZE = 24

type DataConnection<T> = T extends {
  pageInfo: PageInfo
  totalCount: number
  nodes: any[]
}
  ? Readonly<T>
  : never

type FieldValue<T> = FieldPolicy<T> | FieldReadFunction<T>
type Fields = {
  favorites: FieldValue<FavoriteConnection>
  programs: FieldValue<ProgramConnection>
  episodes: FieldValue<EpisodeConnection>
  search: FieldValue<ProgramConnection>
}

const read = <T>(data: DataConnection<T> | undefined) => data

/**
 * A function to combine the results of the stored cache and the next page.
 * If data can be fetched through the read function, it is not called.
 * Like read, `offset-limit pagination` is implemented.
 * Sometimes api returns items with the same id to consecutive pages, so the value of removing duplicate data is returned through ʻuniqBy`.
 *
 * 저장된 cache와 next page의 결과를 합치기 위한 함수.
 * read 함수를 통해 data를 가져올 수 있으면 호출되지 않는다.
 * read와 마찬가지로 `offset-limit pagination`을 구현함.
 * 가끔 api에서 같은 id를 지닌 item을 연속된 페이지에 반환하는 경우가 있어, `uniqBy`를 통해 data의 중복을 제거한 값을 return함.
 */
const merge = <T>(
  existing: DataConnection<T> | undefined,
  incomming: DataConnection<T>,
  { args, readField }: FieldFunctionOptions
) => {
  const mergedData = existing ? [...existing.nodes] : []
  const page = args?.page ?? 1
  const offset = (page - 1) * DEFAULT_PAGE_SIZE

  for (let i = 0; i < incomming.nodes.length; i++) {
    mergedData[offset + i] = incomming.nodes[i]
  }

  return {
    ...incomming,
    nodes: uniqBy(mergedData, data => readField('id', data)),
  }
}

const fields: Fields = {
  favorites: {
    read,
    merge,
  },
  programs: {
    keyArgs: ['categorySlug', 'releaseStatus', 'genreSlug', 'sortFilter'],
    read,
    merge,
  },
  episodes: {
    keyArgs: ['ordering', 'programSlug'],
    read,
    merge,
  },
  search: {
    keyArgs: ['keyword', 'category', 'genre', 'person', 'produceCountry', 'produceYear'],
    read,
    merge,
  },
}

const typePolicies: TypePolicies = {
  Query: {
    fields,
  },
  Program: {
    fields: {
      meta: {
        merge: (existing, incomming, { mergeObjects }) => mergeObjects(existing, incomming),
      },
      images: {
        merge: (existing, incomming, { mergeObjects }) => mergeObjects(existing, incomming),
      },
      latestEpisode: {
        merge: (existing, incomming, { mergeObjects }) => mergeObjects(existing, incomming),
      },
      category: {
        merge: (existing, incomming, { mergeObjects }) => mergeObjects(existing, incomming),
      },
      genre: {
        merge: (existing, incomming, { mergeObjects }) => mergeObjects(existing, incomming),
      },
    },
  },
  Episode: {
    fields: {
      images: {
        merge: (existing, incomming, { mergeObjects }) => mergeObjects(existing, incomming),
      },
      people: {
        merge: (existing = [], incoming: People[]) => uniqBy([...existing, ...incoming], 'slug'),
      },
    },
  },
}

export default typePolicies
