import './AdArea.scss'

import React, { ReactNode, useState, FC } from 'react'
import classNames from 'classnames'

interface Props {
  adSpotId: string
  className?: string
  closeable?: boolean
  width?: number | string
  height: number | string
  onClose?: () => void
  children?: ReactNode
}

const AdArea: FC<Props> = ({ className, adSpotId, width = 'auto', height, closeable = false, onClose }) => {
  const [visible, setVisible] = useState(true)

  if (!visible) {
    return null
  }

  return (
    <div className={classNames('AdArea', className)} style={{ width, height }}>
      <div id={adSpotId} />
      {closeable && (
        <button
          className="AdArea__close"
          onClick={() => {
            setVisible(false)
            if (onClose) {
              setTimeout(() => onClose())
            }
          }}
        >
          <i className="fas fa-times" />
        </button>
      )}
    </div>
  )
}

export default AdArea
