import React, { useEffect } from 'react'
import { isNil } from 'lodash'

import useLoginFlow from '../hooks/useLoginFlow'
import useUserContext from '../hooks/useUserContext'
import useSyncLocale from '../hooks/useSyncLocale'
import useRouter from '../hooks/useRouter'

import { getChangedPathname, getCurrentLocale, getLocaleFormUrl } from '../utils/locale'
import { getRequestHeader } from '../utils/api'
import { useUserLazyQuery, UserInfoFragment } from '../types/generated'
import { getStoredUserToken, clearStoredUserCredentials } from '../utils/user'

interface Props {
  children: React.ReactNode
}

export default function AuthPage({ children }: Props) {
  const [getUser, { data, error, called }] = useUserLazyQuery({
    context: { headers: getRequestHeader({ isAuthRequest: true }) },
    fetchPolicy: 'network-only',
  })
  const userToken = getStoredUserToken()
  const login = useLoginFlow()
  const { ActionTypes, dispatch } = useUserContext()
  const syncLocale = useSyncLocale()
  const { history, location } = useRouter()

  const syncLocaleWithRedirect = (userInfo?: UserInfoFragment) => {
    const locale = getCurrentLocale(userInfo)
    syncLocale({ locale })
    if (getLocaleFormUrl() !== locale) {
      history.push(getChangedPathname({ localeKey: locale }))
    }
  }
  useEffect(() => {
    if (!called) {
      return
    }

    if (error) {
      clearStoredUserCredentials()
      dispatch({ type: ActionTypes.AUTHENTICATE_CANCEL })
      syncLocaleWithRedirect()
      return
    }

    if (!data?.user) {
      return
    }

    syncLocaleWithRedirect(data.user.info)
    login({
      user: data.user,
    })
    // eslint-disable-next-line
  }, [data, error, called])

  useEffect(() => {
    /**
     * NOTE: Check current user id for prevent to call get user twice,
     *       if userId exist and including to dependency array.
     */
    const currentUserToken = getStoredUserToken()

    if (isNil(currentUserToken)) {
      // Dose not exist user token
      dispatch({ type: ActionTypes.AUTHENTICATE_CANCEL })
      syncLocaleWithRedirect()
      return
    }
    getUser()
    dispatch({ type: ActionTypes.AUTHENTICATE })
    // eslint-disable-next-line
  }, [location.pathname])

  return !userToken || called ? <>{children}</> : null
}
