export function getItem(key: string, initialValue: string | number | boolean | object | null) {
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(key)
    // Parse stored json or if none return initialValue
    if (!item) {
      return initialValue
    }
    return typeof item === 'string' ? item : JSON.parse(item)
  } catch (error) {
    // If error also return initialValue
    console.log(error)
    return initialValue
  }
}

export function setItem(key: string, value: string | number | boolean | object) {
  try {
    const item = typeof value === 'string' ? value : JSON.stringify(value)
    window.localStorage.setItem(key, item)
  } catch (e) {
    if (e.name === 'QuotaExceededError') {
      window.localStorage.clear()
    }
    // A more advanced implementation would handle the error case
    console.log(e)
  }
}

export const removeItem = (key: string) => {
  try {
    window.localStorage.removeItem(key)
  } catch (e) {
    console.log(e)
  }
}

export default {
  getItem,
  setItem,
  removeItem,
}
