import './Input.scss'

import React, { FC, ChangeEvent, KeyboardEvent, RefObject } from 'react'
import classNames from 'classnames'

import { KeyCode } from '../constants/enums'

interface Props {
  inputRef?: RefObject<HTMLInputElement>
  name: string
  id?: string
  className?: string
  placeholder?: string
  type?: 'text' | 'password' | 'email'
  autofocus?: boolean
  autocomplete?: boolean
  disabled?: boolean
  readonly?: boolean
  block?: boolean
  value?: string | number
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onSubmit?: (e: KeyboardEvent<HTMLInputElement>) => void
  onReset?: (e: KeyboardEvent<HTMLInputElement>) => void
  onBlur?: () => void
  onFocus?: () => void
}

const Input: FC<Props> = ({
  inputRef,
  name,
  id,
  className,
  placeholder,
  type,
  autofocus,
  autocomplete,
  disabled,
  readonly,
  onChange,
  onBlur,
  onFocus,
  block,
  value,
  onSubmit,
  onReset,
}) => {
  const handleKeyupEvent = (event: KeyboardEvent<HTMLInputElement>) => {
    const { keyCode } = event

    if (keyCode === KeyCode.ENTER && typeof onSubmit === 'function') {
      onSubmit(event)
      return
    }

    if (keyCode === KeyCode.ESC && typeof onReset === 'function') {
      onReset(event)
      return
    }
  }

  return (
    <input
      ref={inputRef}
      id={id}
      className={classNames('Input', className, {
        'Input--block': block,
      })}
      type={type || 'text'}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyUp={handleKeyupEvent}
      name={name}
      placeholder={placeholder}
      autoFocus={autofocus}
      autoComplete={autocomplete ? 'on' : 'off'}
      disabled={disabled}
      readOnly={readonly}
    />
  )
}

export default Input
