import './LocaleSelector.scss'

import React, { useRef, useState, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { find } from 'lodash'
import classNames from 'classnames'

import useEventListener from '../hooks/useEventListener'
import useSyncLocale from '../hooks/useSyncLocale'
import useRouter from '../hooks/useRouter'

import { getChangedPathname } from '../utils/locale'
import locales from '../constants/languages.json'

interface Props {
  className?: string
}

const LocaleSelector: FC<Props> = ({ className }) => {
  const [visibleLocaleSelector, setVisibleLocaleSelector] = useState(false)
  const toggleButtonRef = useRef<HTMLButtonElement>(null)
  const { i18n } = useTranslation()
  const syncLocale = useSyncLocale()
  const { history } = useRouter()

  const currentLocale: { [key: string]: string } = find(locales, ({ key }) => key === i18n.language) || locales[0]

  const handleClickLocaleSelector = () => {
    setVisibleLocaleSelector(!visibleLocaleSelector)
  }

  const handleLocaleChange = (localeKey: string) => {
    handleClickLocaleSelector()
    syncLocale({
      locale: localeKey,
      isSaveLocalStorage: true,
    })
    history.push(getChangedPathname({ localeKey }))
  }

  useEventListener([
    {
      eventName: 'click',
      callback: e => {
        if (e.target !== toggleButtonRef.current) {
          setVisibleLocaleSelector(false)
        }
      },
    },
  ])

  return (
    <div
      className={classNames('LocaleSelector', className)}
      onMouseLeave={() => {
        setVisibleLocaleSelector(false)
      }}
    >
      <div
        className={classNames({
          LocaleSelector__hover: visibleLocaleSelector,
        })}
      />
      <button ref={toggleButtonRef} className="LocaleSelector__locale" onClick={() => handleClickLocaleSelector()}>
        {currentLocale.name}
      </button>
      <ul
        className={classNames('LocaleSelector__selector', {
          'LocaleSelector__selector--visible': visibleLocaleSelector,
        })}
      >
        {locales.map(({ key, name }) => (
          <li
            key={key}
            className={classNames('LocaleSelector__localeItem', {
              'LocaleSelector__localeItem--selected': key === i18n.language,
            })}
            onClick={() => handleLocaleChange(key)}
          >
            {name}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default LocaleSelector
