import './Footer.scss'

import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import LinkWithLocale from './LinkWithLocale'
import OuterLink from './OuterLink'

const documentLinks = [
  {
    to: 'https://odkmedia.net',
    titleKeyword: 'footer.aboutUs',
    isOuter: true,
  },
  {
    to: '/terms-of-use',
    titleKeyword: 'footer.termsOfUse',
  },
  {
    to: 'https://odkmedia.net/privacy-policy',
    titleKeyword: 'footer.privacyPolicy',
    isOuter: true,
  },
  {
    to: '/contact-us',
    titleKeyword: 'footer.help',
  },
  {
    to: '/faq',
    titleKeyword: 'footer.faq',
  },
]

const snsLinks = [
  {
    url: 'https://www.facebook.com/ondemandchina',
    iconImageUrl: '/icons/ico-facebook.svg',
    description: 'link to odc facebook',
  },
  {
    url: 'https://www.instagram.com/ondemandchina/',
    iconImageUrl: '/icons/ico-instagram.svg',
    description: 'link to odc instagram',
  },
  {
    url: 'https://twitter.com/OndemandChina',
    iconImageUrl: '/icons/ico-twitter.svg',
    description: 'link to odc twitter',
  },
  {
    url: 'https://www.youtube.com/c/OnDemandChinaOfficial ',
    iconImageUrl: '/icons/ico-youtube.svg',
    description: 'link to odc youtube',
  },
  {
    url: 'https://www.weibo.com/ondemandchina',
    iconImageUrl: '/icons/ico-weibo.svg',
    description: 'link to odc weibo',
  },
  {
    url: 'https://blog.ondemandchina.com/',
    iconImageUrl: '/icons/ico-blog.svg',
    description: 'link to odc blog',
  },
]

const Footer: FC = () => {
  const { t } = useTranslation()

  return (
    <footer className="Footer">
      <ul className="Footer__snsLinks">
        {snsLinks.map(link => (
          <li key={link.url}>
            <OuterLink href={link.url}>
              <img src={link.iconImageUrl} alt={link.description} />
            </OuterLink>
          </li>
        ))}
      </ul>

      <ul className="Footer__documentLinks">
        {documentLinks.map(({ to, titleKeyword, isOuter }) => (
          <li key={to} className="Footer__documentLink">
            {isOuter ? (
              <OuterLink href={to}>{t(titleKeyword)}</OuterLink>
            ) : (
              <LinkWithLocale to={to}>{t(titleKeyword)}</LinkWithLocale>
            )}
          </li>
        ))}
        <li className="Footer__documentLink">
          <OuterLink href="https://privacyportal.onetrust.com/webform/5ace1796-24fd-4b65-9564-9e8e8df7ec5d/6adc38fb-7317-430f-aecb-7382f7162dd3">
            {t('footer.doNotSellMyPersonalInformation')}
          </OuterLink>
        </li>
      </ul>

      <div className="Footer__company">{`© 2018-${dayjs().get('year')} ODK Media, Inc.`}</div>
      <div className="Footer__company">OnDemandChina &amp; Design Reg. U.S. Pat. &amp; Tm. Off.</div>
    </footer>
  )
}

export default Footer
