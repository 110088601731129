import './SearchBarInput.scss'

import React, { memo, KeyboardEvent, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import Input from './Input'
import Loading from './Loading'

import useRouter from '../hooks/useRouter'

import { toLocaleUrl } from '../utils/locale'

interface Props {
  searchKeyword: string
  isPossibleSubmit: boolean
  isLoading: boolean
  onChange: Dispatch<SetStateAction<string>>
  onClose: () => void
}

export default memo(function SearchBarInput({ searchKeyword, isPossibleSubmit, isLoading, onChange, onClose }: Props) {
  const { history } = useRouter()
  const { t } = useTranslation()

  const onSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (!isPossibleSubmit) {
      return
    }

    history.push(toLocaleUrl(`/search?q=${searchKeyword}`))
    window.scrollTo(0, 0)
    onClose()
    // eslint-disable-next-line
  }

  return (
    <div className="SearchBarInput">
      <Input
        className="SearchBarInput__input"
        name="search keyword"
        type="text"
        placeholder={t('searchBar.togglebutton')}
        value={searchKeyword}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        onSubmit={onSubmit}
        autofocus
      />
      <Loading visible={isLoading} size={40} />
      <button
        id="SearchKeywordClear"
        className="SearchBarInput__clearButton"
        onClick={() => {
          onChange('')
        }}
      >
        {t('clear')}
      </button>
      <button id="SearchBarClose" className="SearchBarInput__closeButton" onClick={onClose}>
        {t('close')}
      </button>
    </div>
  )
})
