import { useReducer, useEffect, useState } from 'react'
import { isNil } from 'lodash'

import useUserContext from '../hooks/useUserContext'
import useNotification from '../hooks/useNotification'
import { requestApi, isServerError } from '../utils/api'
import { safeGet, logger } from '../utils/misc'

import { HTTPStatus } from '../constants/enums'
import { FetchState, Action } from '../types/common'
import { RequestErrorMessages } from '../types/models'

type FetchPostState = Pick<FetchState<{}>, Exclude<keyof FetchState<{}>, 'error'>> & {
  error: RequestErrorMessages | null
}
type RequestHelper = (url: string | null, body?: { [key: string]: any } | undefined) => void

interface RequestApiParams {
  initialUrl?: string
  initialBody?: { [key: string]: any }
  method?: 'get' | 'post' | 'patch' | 'delete' | 'put'
  header?: { [key: string]: string }
  onSuccess?: (state: FetchPostState) => void
  onFailure?: (state: FetchPostState) => void
  isAuthRequest?: boolean
}

const initialState = {
  isLoading: false,
  hasError: false,
  isFetched: false,
  error: null,
  errorCode: null,
  data: null,
  count: null,
  hasNext: false,
  nextUrl: null,
}

function reducer(state: FetchState<any>, action: Action) {
  switch (action.type) {
    case 'FETCH_INIT':
      logger.log('[REDUCER] fetch init.')
      return {
        ...state,
        isLoading: true,
        isFetched: false,
        hasError: false,
      }
    case 'FETCH_SUCCESS':
      logger.log('[REDUCER] fetch successed.', action.payload)
      return {
        isLoading: false,
        isFetched: true,
        hasError: false,
        error: null,
        errorCode: null,
        data: action.payload.data,
        count: action.payload.count,
        hasNext: action.payload.hasNext,
        nextUrl: action.payload.nextUrl,
      }
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isFetched: true,
        hasError: true,
        error: action.payload.error,
        errorCode: action.payload.errorCode,
      }

    default:
      throw new Error(`Unknown action. ${action}`)
  }
}

const defaultRequestOptions: RequestApiParams = {
  initialUrl: '',
  method: 'get',
  isAuthRequest: true,
}

const useRequestApi = <T>(params?: RequestApiParams): [FetchState<T>, RequestHelper] => {
  const { initialUrl, initialBody, onSuccess, onFailure, method = 'get', header, isAuthRequest = true } =
    params ?? defaultRequestOptions
  const [state, dispatch] = useReducer(reducer, initialState)
  const [requestData, setRequestData] = useState({
    url: initialUrl || '',
    body: initialBody,
  })
  const userContext = useUserContext()
  const notification = useNotification()

  const request: RequestHelper = (url, body) => {
    if (isNil(url)) {
      return
    }

    if (method !== 'get') {
      setRequestData({ url, body })
      return
    }

    if (requestData.url !== url) {
      setRequestData({ url, body })
    }
  }

  useEffect(() => {
    let didCancel = false
    const { url, body } = requestData

    const fetch = async () => {
      dispatch({ type: 'FETCH_INIT' })

      try {
        const res: { [key: string]: any } = await requestApi({ url, body, method, header, isAuthRequest })
        const result = res?.results ?? res?.detail ?? res

        if (!didCancel) {
          const payload = {
            data: result,
            count: res.count,
            hasNext: !!res.next,
            nextUrl: res.next,
          }

          dispatch({
            type: 'FETCH_SUCCESS',
            payload,
          })
        }
      } catch (error) {
        if (!didCancel) {
          if (safeGet(() => error.response.status) === HTTPStatus.UNAUTHORIZED) {
            userContext.dispatch({
              type: userContext.ActionTypes.AUTHENTICATE_CANCEL,
            })
          }
          if (isServerError(safeGet(() => error.response.status))) {
            notification.showNotification('Server Error')
            dispatch({
              type: 'FETCH_FAILURE',
              payload: {
                error: safeGet(() => error.response.data, 'Server Error.'),
                errorCode: safeGet(() => error.response.status, error),
              },
            })
            return
          }
          dispatch({
            type: 'FETCH_FAILURE',
            payload: {
              error: safeGet(() => error.response.data, 'Error occurred.'),
              errorCode: safeGet(() => error.response.status, error),
            },
          })
        }
      }
    }

    if (url) {
      fetch()
    }

    return () => {
      didCancel = true
    }
    // eslint-disable-next-line
  }, [requestData])

  useEffect(() => {
    const { isFetched, hasError, isLoading } = state

    if (!isFetched || isLoading) {
      return
    }
    if (!hasError && onSuccess) {
      onSuccess(state)
    }
    if (hasError && onFailure) {
      onFailure(state)
    }
    // eslint-disable-next-line
  }, [state])

  return [
    {
      ...state,
      data: state.data as T,
    },
    request,
  ]
}

export default useRequestApi
