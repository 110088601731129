import './LazyLoadImage.scss'

import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'

import useLazyLoadImage from '../hooks/useLazyLoadImage'

interface Props {
  className?: string
  src: string
  alt: string
}

export default function LazyLoadImage({ className, src, alt }: Props) {
  const imageRef = useRef<HTMLImageElement>(null)
  const { isLoaded, setNextLazyValue } = useLazyLoadImage(imageRef)

  useEffect(() => {
    if (src && isLoaded) {
      setNextLazyValue(src)
    }
    // eslint-disable-next-line
  }, [src])

  return (
    <img
      ref={imageRef}
      className={classNames('LazyLoadImage', className)}
      alt={alt}
      src="/images/thumbnail-placeholder.svg"
      data-src={src}
    />
  )
}
