import { AdSpot, AdvertisementState } from './types/common'
import { isFunction, toPairs, isEmpty, isArray, omit, debounce } from 'lodash'
import { flow, toPairs as toPairsFp, map, fromPairs, filter } from 'lodash/fp'

import { getPlatform } from './utils/misc'
import { getQueryString } from './utils/queryString'

type AdStateWithoutEpisodeId = Omit<AdvertisementState, 'episodeId'>
type AdStateWithoutEpisodeIdAndPartner = Omit<AdStateWithoutEpisodeId, 'partner'>

export const AD_REFRESH_TIME = 1000 * 45
const AD_DEBOUNCING_TIME = 500
const AD_CUSTOM_PARAMETER_PREFIX = 'cust_params='
const AD_STATE_PARTNER_KEY = 'partner'
const AD_STATE_EPISODE_ID_KEY = 'episodeId'
const ENCODED_COMMA = encodeURIComponent(',')
const GOOGLE_AD_VIDEO_SOURCE_ID = process.env.REACT_APP_DFP_VIDEO_SOURCE_ID ?? '2516564'
const GOOGLE_AD_VIDEO_SOURCE_ID_KEY = 'cmsid'
const GOOGLE_AD_VIDEO_ID_KEY = 'vid'

const customParameterMap: { [key: string]: string } = {
  platform: 'platform_ODC',
  environment: 'stage_ODC',
  category: 'category_ODC',
  program: 'program_ODC',
  episode: 'episode_ODC',
  partner: 'partner_ODC',
  fullscreen: 'fullscreen',
  customAdCue: 'customAdCue_ODC',
}
export const environmentMap: { [key: string]: string } = {
  local: 'dev',
  development: 'dev',
  staging: 'staging',
  production: 'www',
}
export const initialAdState = {
  platform: getPlatform(),
  environment: environmentMap[process.env.REACT_APP_DEPLOY_ENV || 'local'],
  category: '',
  program: '',
  episode: '',
  partner: '',
  fullscreen: '0',
  episodeId: '',
  customAdCue: '0',
}

window.advertisementState = { ...initialAdState }

const googletag = window.googletag || {}
googletag.cmd = googletag.cmd || []

const articleTopMapping =
  isFunction(googletag.sizeMapping) &&
  googletag
    .sizeMapping()
    .addSize([0, 0], [320, 50])
    .addSize(
      [768, 320],
      [
        [728, 90],
        [320, 50],
      ]
    )
    .addSize(
      [1024, 320],
      [
        [970, 90],
        [728, 90],
      ]
    )
    .addSize(
      [1200, 320],
      [
        [970, 90],
        [728, 90],
      ]
    )
    .addSize(
      [1520, 320],
      [
        [970, 90],
        [728, 90],
      ]
    )
    .build()

const articleLeftRightMapping =
  isFunction(googletag.sizeMapping) && googletag.sizeMapping().addSize([0, 0], [160, 600]).build()

const articleSideMapping =
  isFunction(googletag.sizeMapping) && googletag.sizeMapping().addSize([0, 0], [300, 250]).build()

const articleMidMapping =
  isFunction(googletag.sizeMapping) &&
  googletag
    .sizeMapping()
    .addSize([0, 0], [320, 50])
    .addSize([768, 320], [320, 50])
    .addSize([1024, 320], [320, 50])
    .addSize([1200, 320], [728, 90])
    .addSize(
      [1520, 320],
      [
        [970, 90],
        [728, 90],
      ]
    )
    .build()

const articleBottomMapping =
  isFunction(googletag.sizeMapping) &&
  googletag
    .sizeMapping()
    .addSize(
      [0, 0],
      [
        [320, 50],
        [300, 250],
      ]
    )
    .addSize(
      [768, 320],
      [
        [320, 50],
        [300, 250],
      ]
    )
    .addSize(
      [1024, 320],
      [
        [320, 50],
        [300, 250],
      ]
    )
    .addSize([1200, 320], [728, 90])
    .addSize(
      [1520, 320],
      [
        [970, 90],
        [728, 90],
      ]
    )
    .build()

export const adSpots: AdSpot[] = [
  {
    id: '/66416091/ODC_Top',
    name: 'ODC_Top',
    sizes: [
      [970, 90],
      [728, 90],
      [320, 50],
    ],
    spotId: 'div-gpt-ad-1563442558791-0',
    sizeMapping: articleTopMapping,
  },
  {
    id: '/66416091/ODC_ARTICLE_MID',
    name: 'ODC_ARTICLE_MID',
    sizes: [
      [970, 90],
      [728, 90],
      [320, 50],
    ],
    spotId: 'div-gpt-ad-1563416805914-0',
    sizeMapping: articleMidMapping,
  },
  {
    id: '/66416091/ODC_ARTICLE_SIDE',
    name: 'ODC_ARTICLE_SIDE',
    sizes: [300, 250],
    spotId: 'div-gpt-ad-1563416762817-0',
    sizeMapping: articleSideMapping,
  },
  {
    id: '/66416091/ODC_Left',
    name: 'ODC_Left',
    sizes: [
      [160, 600],
      [120, 600],
    ],
    spotId: 'div-gpt-ad-1563416718788-0',
    sizeMapping: articleLeftRightMapping,
  },
  {
    id: '/66416091/ODC_Right',
    name: 'ODC_Right',
    sizes: [
      [160, 600],
      [120, 600],
    ],
    spotId: 'div-gpt-ad-1563416666966-0',
    sizeMapping: articleLeftRightMapping,
  },
  {
    id: '/66416091/ODC_ARTICLE_BOTTOM',
    name: 'ODC_ARTICLE_BOTTOM',
    sizes: [
      [300, 250],
      [728, 90],
      [320, 50],
      [970, 90],
    ],
    spotId: 'div-gpt-ad-1563416845529-0',
    sizeMapping: articleBottomMapping,
  },
]

googletag.cmd.push(() => {
  adSpots.forEach(({ id, sizes, spotId, sizeMapping }: AdSpot) => {
    googletag.defineSlot(id, sizes, spotId).defineSizeMapping(sizeMapping).addService(googletag.pubads())
  })

  googletag.companionAds().setRefreshUnfilledSlots(true)
  googletag.pubads().enableVideoAds()
  googletag.pubads().disableInitialLoad()
  googletag.pubads().enableSingleRequest()
  googletag.pubads().collapseEmptyDivs()
  googletag.enableServices()
})

export const refreshAd = debounce(() => {
  if (googletag && googletag.pubads) {
    googletag.pubads().clearTargeting()
    toPairs(window.advertisementState).forEach(([key, value]) => {
      if (!!value || key === 'fullscreen') {
        googletag.pubads().setTargeting(customParameterMap[key], value)
      }
    })
    googletag.pubads().refresh()
  }
}, AD_DEBOUNCING_TIME)

export const displayAd = () => {
  if (googletag && googletag.cmd) {
    googletag.cmd.push(() => {
      googletag.display()
    })
  }
}

const getVideoAdQueryParameter = () =>
  getQueryString({
    [GOOGLE_AD_VIDEO_SOURCE_ID_KEY]: GOOGLE_AD_VIDEO_SOURCE_ID,
    [GOOGLE_AD_VIDEO_ID_KEY]: window.advertisementState.episodeId,
  }).substring(1)

export const setAdvertisementState = (state: Partial<AdvertisementState>) => {
  if (isEmpty(state)) {
    return
  }

  toPairs(state).forEach(([key, value]) => {
    if (value === undefined) {
      return
    }

    window.advertisementState[key] = value
  })
}

export const convertAdState = (advertisementState: Partial<AdvertisementState>) =>
  flow(
    toPairsFp,
    filter(([key]) => key !== AD_STATE_EPISODE_ID_KEY),
    map(([key, value]) => [customParameterMap[key], value]),
    fromPairs
  )(advertisementState)

export const buildAdCustomParameter = () => {
  const adStateWithoutEpisodeId: AdStateWithoutEpisodeId = omit(window.advertisementState, AD_STATE_EPISODE_ID_KEY)

  if (isArray(adStateWithoutEpisodeId.partner)) {
    const adStateWithoutPartner: AdStateWithoutEpisodeIdAndPartner = omit(adStateWithoutEpisodeId, AD_STATE_PARTNER_KEY)
    const convertedAdState: { [key: string]: string } = convertAdState(adStateWithoutPartner)
    const queryString = getQueryString(convertedAdState)
    const partnerQueryString = `&${customParameterMap[AD_STATE_PARTNER_KEY]}=${adStateWithoutEpisodeId.partner.join(
      ENCODED_COMMA
    )}`

    return `${queryString.substring(1)}${partnerQueryString}`
  }

  const convertedAdState: { [key: string]: string } = convertAdState(adStateWithoutEpisodeId)
  const queryString = getQueryString(convertedAdState)

  return queryString.substring(1)
}

export const getEncodedAdCustomParameter = () =>
  `${AD_CUSTOM_PARAMETER_PREFIX}${encodeURIComponent(buildAdCustomParameter())}`

export const getGoogleDfpAdTag = (debug = false) => {
  if (debug) {
    return 'https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/ad_rule_samples&ciu_szs=300x250&ad_rule=1&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ar%3Dpremidpostlongpod&cmsid=496&vid=short_tencue&correlator='
  }

  return `${process.env.REACT_APP_DFP_AD_TAG}&${getVideoAdQueryParameter()}&${getEncodedAdCustomParameter()}`
}
export const initializeAdState = () => setAdvertisementState(initialAdState)
