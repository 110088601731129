import './Notification.scss'

import React, { memo, FC } from 'react'
import { useTranslation } from 'react-i18next'

import useNotification from '../hooks/useNotification'

import Portal from '../components/Portal'

interface Props {}

const Notification: FC<Props> = () => {
  const { isShowing, message, closeNotification } = useNotification()
  const { t } = useTranslation()

  if (!isShowing) {
    return null
  }

  return (
    <Portal position="top">
      <div className="Notification">
        <div className="Notification clearFix">
          <p className="Notification__message">{message}</p>
          <button id="NotificationConfirm" className="Notification__button" onClick={closeNotification}>
            {t('gotit')}
          </button>
        </div>
        <div className="Notification__dim" />
      </div>
    </Portal>
  )
}

export default memo(Notification)
