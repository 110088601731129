import React, { FC, FunctionComponent } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { includes } from 'lodash'

import useRouter from '../hooks/useRouter'
import useUserContext from '../hooks/useUserContext'
import { toLocaleUrl } from '../utils/locale'

interface Props {
  path: string
  exact?: boolean
  component: FunctionComponent<any>
}

const AuthenticateRoute: FC<Props> = ({ path, exact, component }) => {
  const { isAuthenticated } = useUserContext()
  const {
    location: { pathname },
  } = useRouter()
  const redirectPath = includes(pathname, 'forgot-password') ? '/user/account/reset-password' : '/'

  return isAuthenticated ? (
    <Redirect to={toLocaleUrl(redirectPath)} />
  ) : (
    <Route path={path} exact={exact} component={component} />
  )
}

export default AuthenticateRoute
