const ONESIGNAL_APP_ID = process.env.REACT_APP_ONESIGNAL_ID

export const initializeOneSignal = () => {
  if (ONESIGNAL_APP_ID) {
    const OneSignal = window.OneSignal || []

    OneSignal.push(() => {
      OneSignal.init({
        appId: ONESIGNAL_APP_ID,
        autoResubscribe: true,
        notifyButton: {
          enable: true,
        },
        allowLocalhostAsSecureOrigin: true,
        welcomeNotification: {
          title: 'OnDemandChina',
          message: 'Thanks for subscribing!',
        },
      })
      OneSignal.showNativePrompt()
    })
  } else {
    console.log('OneSignal App ID does not exist.')
  }
}
