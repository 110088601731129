import './CCLanguages.scss'

import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import classNames from 'classnames'

interface Props {
  ccLanguages: string[] | undefined
  className?: string
}

export default memo(function CCLanguages({ ccLanguages, className }: Props) {
  const { t } = useTranslation()

  return ccLanguages && !isEmpty(ccLanguages) ? (
    <div className={classNames('CCLanguages', className)}>
      <span className="CCLanguages__cc">
        {/* TODO: replace 추후 삭제 */}
        {ccLanguages.map(language => t(`cc.${language.replace('_', '-')}`)).join(' | ')}
      </span>
    </div>
  ) : null
})
