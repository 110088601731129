import { useEffect } from 'react'

interface EventParam {
  eventName: string
  callback: (e: any) => void
}

export default function useEventListener(events: EventParam[], eventObject: Window | Document = document) {
  useEffect(() => {
    events.forEach(({ eventName, callback }) => {
      eventObject.addEventListener(eventName, callback)
    })

    return () => {
      events.forEach(({ eventName, callback }) => {
        eventObject.removeEventListener(eventName, callback)
      })
    }
    // eslint-disable-next-line
  }, [])
}
