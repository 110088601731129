import './DynamicSearchResultItem.scss'

import React from 'react'
import { useTranslation } from 'react-i18next'

import { getCommaSeparatedStr, getSizedImageUrl } from '../utils/misc'
import { getTranslateValue } from '../utils/locale'

import LinkWithLocale from './LinkWithLocale'
import LazyLoadImage from './LazyLoadImage'
import EpisodeSubInfo from './EpisodeSubInfo'
import CCLanguages from './CCLanguages'

import { ReleaseStatus } from '../constants/enums'
import { SearchItemFragment } from '../types/generated'

interface Props {
  item: SearchItemFragment
}

export default function DynamicSearchResultItem({ item }: Props) {
  const { t } = useTranslation()

  const { slug, images, latestEpisode, releaseStatus, produceYear, meta } = item
  const title = getTranslateValue(item, 'title') ?? ''
  const secondaryTitle = getTranslateValue(item, 'secondaryTitle')
  const latestEpisodeTitle = getTranslateValue(latestEpisode, 'title') ?? ''
  const poster = getTranslateValue(images, 'poster')
  const getCountries = getCommaSeparatedStr(meta?.produceCountries ?? [])

  return (
    <li className="DynamicSearchResultItem">
      <LinkWithLocale to={`/program/${slug}` || '/'}>
        <div className="DynamicSearchResultItem__item">
          <LazyLoadImage
            className="DynamicSearchResultItem__searchItemImage"
            src={getSizedImageUrl(poster)}
            alt={`${title} program image`}
          />
          <div className="DynamicSearchResultItem__searchItemContent">
            <h3 className="DynamicSearchResultItem__searchItemtitle">{title}</h3>
            <p className="DynamicSearchResultItem__episodeName">{secondaryTitle}</p>
            {meta?.isSeries && (
              <EpisodeSubInfo
                latestEpisodeTitle={latestEpisodeTitle}
                episodeTotal={meta?.episodeTotal ?? 0}
                releaseStatus={releaseStatus as ReleaseStatus}
              />
            )}
            <p className="DynamicSearchResultItem__createdAt">
              {produceYear} {produceYear && !meta?.produceCountries && '|'}{' '}
              {meta?.produceCountries.map((countryCode, i) => (
                <span key={countryCode} className="DynamicSearchResultItem__nation">
                  {getCountries(t(countryCode), i) || ''}
                </span>
              ))}
            </p>
            <CCLanguages ccLanguages={meta?.ccLanguages} />
          </div>
        </div>
      </LinkWithLocale>
    </li>
  )
}
