import TagManager from 'react-gtm-module'
import { Player } from '@odkmedia/odx-web-player'

import { getStoredUserId } from './user'
import { createLogger, isDebug } from './misc'

interface VideoPlayingState {
  prevTime: number
  watchedTime: number
  accumulatedWatchedTime: number
  isVideoStarted: boolean
  isFirstVideoPlayed: boolean
  isFirstFiveSecPassed: boolean
}

const GTM_ID = 'GTM-T7NHBTH'
const GTM_SEND_INTERVAL_SECONDS = isDebug() ? 10 : 60 * 5
const log = createLogger('Player', '#000000', '#FFFFFF')

let videoPlayingState: VideoPlayingState
let isCastConnected = false

export const initializeGTM = () => {
  const gtmArgs = {
    gtmId: GTM_ID,
    dataLayer: {
      userId: getStoredUserId(),
    },
  }
  TagManager.initialize(gtmArgs)
}

export const sendGTMEvent = (dataLayer: { [key: string]: string | number | undefined }) => {
  TagManager.dataLayer({ dataLayer })
  if (isDebug()) {
    console.log('****** Player Debugging ******')
    console.log(dataLayer)
  }
}

const updateVideoPlayingState = (state: VideoPlayingState) => {
  videoPlayingState = { ...state }
}

const initializeVideoPlayingState = () =>
  updateVideoPlayingState({
    prevTime: 0,
    watchedTime: 0,
    accumulatedWatchedTime: 0,
    isVideoStarted: false, // 동영상 광고부터 플레이를 감지한다(광고도 비디오 시작으로 간주)
    isFirstVideoPlayed: false, // 동영상 광고 이후의 플레이를 감지한다
    isFirstFiveSecPassed: false,
  })

const sendVideoPlayCount = (episodeName: string, category: string, contentProvider?: string) => {
  if (!videoPlayingState.isVideoStarted && episodeName && category) {
    sendGTMEvent({
      event: 'videoPlayCount',
      label: episodeName,
      category: `Video/${category}`,
      contentProvider,
    })
  }
  updateVideoPlayingState({
    ...videoPlayingState,
    isVideoStarted: true,
  })
}

export const initializePlayerGTM = ({
  player,
  episodeName,
  categorySlug,
  contentProvider,
}: {
  player: Player
  episodeName: string
  categorySlug: string
  contentProvider: string
}) => {
  const commonMetaData = {
    label: episodeName,
    category: `Video/${categorySlug}`,
    contentProvider: contentProvider,
  }

  initializeVideoPlayingState()

  const onBeforeUnload = () => {
    const remainingWatchingTime = Math.floor(videoPlayingState.watchedTime % GTM_SEND_INTERVAL_SECONDS)

    if (0 < remainingWatchingTime) {
      sendGTMEvent({
        ...commonMetaData,
        event: 'everyFiveMinView',
        viewhourSeconds: remainingWatchingTime,
      })
    }
  }

  player.on('odx-cast-connected', () => {
    isCastConnected = true
    sendGTMEvent({
      event: 'chromecastConnected',
    })
  })

  player.on('odx-cast-disconnected', () => {
    isCastConnected = false
  })

  player.on('odx-ad-impression', () => {
    sendGTMEvent({
      ...commonMetaData,
      event: 'VideoAdImpression',
    })
  })

  player.on('odx-autoplay-fail', () => {
    sendGTMEvent({
      ...commonMetaData,
      event: 'videoPlayStartFail',
    })
  })

  player.on('loadstart', () => {
    if (isCastConnected) {
      sendGTMEvent({
        ...commonMetaData,
        event: 'chromecastPlayStart',
      })
      return
    }

    log('onPlayerLoad')
    sendGTMEvent({
      ...commonMetaData,
      event: 'playerLoad',
    })
    window.addEventListener('beforeunload', onBeforeUnload)
  })

  player.on('playing', () => {
    sendVideoPlayCount(episodeName, categorySlug, contentProvider)
    if (!videoPlayingState.isFirstVideoPlayed) {
      sendGTMEvent({
        ...commonMetaData,
        event: 'firstVideoPlay',
      })
    }
    updateVideoPlayingState({
      ...videoPlayingState,
      isFirstVideoPlayed: true,
    })
  })

  player.on('odx-five-sec', (event, info) => {
    updateVideoPlayingState({
      ...videoPlayingState,
      watchedTime: videoPlayingState.watchedTime + 5,
    })

    if (5 <= videoPlayingState.watchedTime && !videoPlayingState.isFirstFiveSecPassed) {
      sendGTMEvent({
        ...commonMetaData,
        event: 'firstFiveSecView',
      })
      updateVideoPlayingState({
        ...videoPlayingState,
        isFirstFiveSecPassed: true,
      })
    }

    if (GTM_SEND_INTERVAL_SECONDS <= videoPlayingState.watchedTime) {
      sendGTMEvent({
        ...commonMetaData,
        event: 'everyFiveMinView',
        viewhourSeconds: GTM_SEND_INTERVAL_SECONDS,
      })
      updateVideoPlayingState({
        ...videoPlayingState,
        watchedTime: videoPlayingState.watchedTime - GTM_SEND_INTERVAL_SECONDS,
        accumulatedWatchedTime: videoPlayingState.accumulatedWatchedTime + GTM_SEND_INTERVAL_SECONDS,
      })
    }
  })

  player.on('odx-content-ended', (_, { remainWatchedTime }) => {
    if (remainWatchedTime) {
      updateVideoPlayingState({
        ...videoPlayingState,
        watchedTime: videoPlayingState.watchedTime + remainWatchedTime,
      })
    }

    onBeforeUnload()
    initializeVideoPlayingState()
  })
}
