import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'intersection-observer'
import 'custom-event-polyfill'
import 'object-fit-images'
import './styles/global.scss'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import { ApolloClient, ApolloProvider } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import typePolicies from './graphql/typePolicies'
import { possibleTypes } from './fragmentTypes.json'
import { name, version } from '../package.json'

import { DeviceTypeContextProvider } from './contexts/DeviceTypeContext'
import { UserContextProvider } from './contexts/UserContext'
import { NotificationContextProvider } from './contexts/NotificationContext'

import App from './App'
import ScrollToTop from './components/ScrollToTop'
import { isDevelopment } from './utils/misc'

import './i18n'
import './advertisement'
import { initializeOneSignal } from './notification'
import UrlChecker from './components/UrlChecker'
import { initializeGTM } from './utils/analytics'

initializeGTM()
initializeOneSignal()
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV ?? 'development',
  defaultIntegrations: false,
  release: version,
})

export const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT ?? 'https://odc-graphql-dev.odkmedia.io/graphql',
  cache: new InMemoryCache({
    possibleTypes,
    typePolicies,
  }),
  name,
  version,
  connectToDevTools: isDevelopment(),
})

try {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <Router>
        <UrlChecker>
          <ScrollToTop>
            <DeviceTypeContextProvider>
              <UserContextProvider>
                <NotificationContextProvider>
                  <App />
                </NotificationContextProvider>
              </UserContextProvider>
            </DeviceTypeContextProvider>
          </ScrollToTop>
        </UrlChecker>
      </Router>
    </ApolloProvider>,
    document.getElementById('root')
  )
} catch (e) {
  console.error(e)
}

// 이스터에그 :)

console.log(`
                                           ##
  ###   #####   ###  #   ##     ##          #   #
 #   #   #   #   #  #     ##   ##           #
#     #  #    #  # #      ##   ##   ##   ####  ##  ###
#     #  #    #  ##       # # # #  #  # #   #   #  #  #
#     #  #    #  # #      # # # #  #### #   #   #   ###
 #   #   #   #   #  #     #  #  #  #    #  ##   #  #  #
  ###   #####   ###  ##  ### # ###  ###  ## ## ### ####

  ODK Media is always looking for talented individuals to join us in providing global service, beyond U.S. and Korea. https://careers.odkmedia.net
`)
