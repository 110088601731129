import { useContext, ReactNode } from 'react'

import NotificationContext from '../contexts/NotificationContext'

export default function useNotification() {
  const value = useContext(NotificationContext)
  const { dispatch, ActionTypes } = value
  return {
    ...value,
    showNotification: (message: string | ReactNode) =>
      dispatch({
        type: ActionTypes.ACTIVE_NOTIFICATION,
        payload: {
          message,
        },
      }),
    closeNotification: () =>
      dispatch({
        type: ActionTypes.DEACTIVE_NOTIFICATION,
      }),
  }
}
