import { invert } from 'lodash'

import { HomeContentType } from '../constants/enums'
import { Content, Episode, Program } from '../types/models'
import { Maybe, ProgramDetailFragment, EpisodeDetailFragment } from '../types/generated'

const kindSplitter = '-'

const kindMapper: { [key: string]: string } = {
  ep: 'series',
  movie: 'main',
  trailer: 'trailer',
  clip: 'clip',
  extra: 'additional',
}

const kindOfUrlMapper = invert(kindMapper)

enum EpisodeIdentityIndex {
  KIND = 0,
  EPISODE_NUMBER = 1,
  PART = 2,
}

const hasPart = (parsed: string[]) => parsed.length === 3

export const getKind = (kindOfUrl: string) => kindMapper[kindOfUrl]

export const parseEpisodeIdentities = (
  episodeIdentitiesString: string
): {
  kind: string
  episodeNumber: number
  part: number | null
} => {
  const parsed = episodeIdentitiesString.split(kindSplitter)
  if (parsed === null || parsed.length < 2) {
    throw new Error(`invalid url: ${episodeIdentitiesString}`)
  }

  return {
    kind: getKind(parsed[EpisodeIdentityIndex.KIND]),
    episodeNumber: parseInt(parsed[EpisodeIdentityIndex.EPISODE_NUMBER], 10),
    part: hasPart(parsed) ? parseInt(parsed[EpisodeIdentityIndex.PART], 10) : null,
  }
}

export const kindToKindOfUrl = (kind: string) => kindOfUrlMapper[kind]

export const buildEpisodePageUrl = (programSlug: string, kind: string, episodeNumber: number, part?: Maybe<number>) => {
  const episodeIdentities = [kindToKindOfUrl(kind), episodeNumber]

  if (part) {
    episodeIdentities.push(part)
  }

  return `/watch/${programSlug}/${episodeIdentities.join(kindSplitter)}`
}

export const buildLinkUrl = (
  type: HomeContentType.PROGRAM | HomeContentType.EPISODE,
  content: Content,
  isDirectPlay: boolean
) => {
  if (type === HomeContentType.PROGRAM) {
    const program = content as Program
    return `/program/${program.slug}${isDirectPlay ? '/direct-play' : ''}`
  } else {
    const episode = content as Episode
    return buildEpisodePageUrl(episode.program.slug, episode.kind, episode.number, episode.part)
  }
}

export const buildEpisodeSlug = (kind: string, episodeNumber: number, part: number | null) =>
  `${kind}-${episodeNumber}${part ? `-${part}` : ''}`

export const getEpisodeName = (program: ProgramDetailFragment | null, episode: EpisodeDetailFragment | null) => {
  if (!program || !episode) {
    return ''
  }

  return `${program.slug}/${episode.kind}/${episode.episodeNumber}${episode.part ? `/${episode.part}` : ''}`
}
