import './SigninLink.scss'

import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { upperFirst } from 'lodash'
import classNames from 'classnames'

import { getSigninUrl } from '../utils/queryString'

import LinkWithLocale from './LinkWithLocale'

interface Props {
  className?: string
  isMobile?: boolean
  onHideMobileMenu?: () => void
}

const SigninLink: FC<Props> = ({ className, isMobile, onHideMobileMenu }) => {
  const { t } = useTranslation()
  const to = getSigninUrl()

  return (
    <LinkWithLocale
      className={classNames('SigninLink', className, {
        'SigninLink--isMobile': isMobile,
      })}
      to={to}
      onClick={() => {
        if (onHideMobileMenu) {
          onHideMobileMenu()
        }
      }}
    >
      {upperFirst(t('signin.header'))}
    </LinkWithLocale>
  )
}

export default SigninLink
