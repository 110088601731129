import { RefObject } from 'react'
import useEventListener from './useEventListener'

import { DispatchEventType } from '../constants/enums'

const useEndOfScroll = (ref: RefObject<HTMLElement>, onScrollEnd: (param?: any) => void) => {
  useEventListener(
    [
      {
        eventName: DispatchEventType.INTERSECTING,
        callback: () => {
          if (!ref.current) {
            return
          }

          if (ref.current.getBoundingClientRect().height > 0) {
            onScrollEnd()
          }
        },
      },
    ],
    window
  )
}

export default useEndOfScroll
