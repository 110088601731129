import './DynamicSearchResult.scss'

import React from 'react'
import { Trans } from 'react-i18next'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import DynamicSearchResultItem from './DynamicSearchResultItem'

import { SearchItemFragment } from '../types/generated'

interface Props {
  searchItems: SearchItemFragment[]
  className?: string
  loading: boolean
  called: boolean
  onCloseSidebar: () => void
}

export default function DynamicSearchResult({ searchItems, className, loading, called, onCloseSidebar }: Props) {
  return !loading && called ? (
    <>
      {isEmpty(searchItems) ? (
        <div className="DynamicSearchResult__notFound">
          <Trans i18nKey={'searchBar.noMatches'} />
        </div>
      ) : (
        <ul
          className={classNames('DynamicSearchResult', className)}
          onClick={e => {
            e.preventDefault()
            onCloseSidebar()
          }}
        >
          {searchItems.map(item => (
            <DynamicSearchResultItem key={item.uuid} item={item} />
          ))}
        </ul>
      )}
    </>
  ) : null
}
