import './MobileLocaleSelector.scss'

import React, { useRef, useState, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { find } from 'lodash'
import classNames from 'classnames'

import useEventListener from '../hooks/useEventListener'
import useSyncLocale from '../hooks/useSyncLocale'
import useRouter from '../hooks/useRouter'

import { getChangedPathname } from '../utils/locale'
import locales from '../constants/languages.json'

const MobileLocaleSelector: FC = () => {
  const [visibleLocaleSelector, setVisibleLocaleSelector] = useState(false)
  const toggleButtonRef = useRef<HTMLButtonElement>(null)
  const { i18n, t } = useTranslation()
  const syncLocale = useSyncLocale()
  const { history } = useRouter()

  const currentLocale: { [key: string]: string } = find(locales, ({ key }) => key === i18n.language) || locales[0]

  const handleClickLocaleSelector = () => {
    setVisibleLocaleSelector(!visibleLocaleSelector)
  }

  const handleLocaleChange = (localeKey: string) => {
    handleClickLocaleSelector()
    syncLocale({
      locale: localeKey,
      isSaveLocalStorage: true,
    })
    history.push(getChangedPathname({ localeKey }))
  }

  useEventListener([
    {
      eventName: 'click',
      callback: e => {
        if (e.target !== toggleButtonRef.current) {
          setVisibleLocaleSelector(false)
        }
      },
    },
  ])

  return (
    <div className="MobileLocaleSelector">
      <button
        ref={toggleButtonRef}
        className={classNames('MobileLocaleSelector__button', {
          'MobileLocaleSelector__button--active': visibleLocaleSelector,
        })}
        onClick={() => {
          setVisibleLocaleSelector(prevState => !prevState)
        }}
      >
        {visibleLocaleSelector ? t('sidebar.selectLanguage') : currentLocale.name}
      </button>
      {visibleLocaleSelector && (
        <ul className="MobileLocaleSelector__links" onClick={handleClickLocaleSelector}>
          {locales.map(({ key, name }) => (
            <li
              key={key}
              className={classNames('MobileLocaleSelector__localeItem', {
                'MobileLocaleSelector__localeItem--selected': key === i18n.language,
              })}
              onClick={() => handleLocaleChange(key)}
            >
              {name}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default MobileLocaleSelector
